import { API_BO_URL, CACHE_IS_USED, FLASK_URL } from '../../utils/config'
import {
  getComparator,
  rebuildUnitaryAnalyses,
  rebuildUnitaryCompounds,
  setOrderValueFromAnalysisOrder,
  stableSort,
} from '../../view/common/Utils'
import { receiveCurrentBatchLease, receiveUpdateBatches } from '../batches/actions'
import {
  RECEIVE_UNITARY_CALIBRATIONS_DISTINCT,
  receiveUnitaryCalibrationsDistinct,
  REQUEST_UNITARY_CALIBRATIONS_DISTINCT,
  requestUnitaryCalibrationsDistinct,
} from '../unitaryCalibrations/actions'
import { getFilterRawById } from '../filters/selectors'
import React, { Fragment } from 'react'
import {
  getUnitaryAnalysisCompoundById,
  getUnitaryAnalysisCompoundISTDById,
  getUnitaryAnalysisFilteredById,
  getUnitaryAnalysisISTDById,
  getUnitaryAnalysisISTDFromDetailStore,
} from './selectors'
import {
  receiveUpdateUnitaryAnalysesDetail,
  receiveUpdateValidationDetail,
} from '../detail/actions'
import { fetchChangeSettings } from '../settings/actions'
import MenuItem from '@material-ui/core/MenuItem'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import { fetchCachePopulate } from '../cache/actions'

export const REQUEST_UNITARY_ANALYSES = 'REQUEST_UNITARY_ANALYSES'
export const RECEIVE_UNITARY_ANALYSES = 'RECEIVE_UNITARY_ANALYSES'
export const REQUEST_UNITARY_ANALYSES_COMPOUND = 'REQUEST_UNITARY_ANALYSES_COMPOUND'
export const RECEIVE_UNITARY_ANALYSES_COMPOUND = 'RECEIVE_UNITARY_ANALYSES_COMPOUND'
export const RECEIVE_UNITARY_ANALYSES_FILTERED = 'RECEIVE_UNITARY_ANALYSES_FILTERED'
export const REQUEST_UNITARY_ANALYSES_FILTERED = 'REQUEST_UNITARY_ANALYSES_FILTERED'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const SORT_UNITARY_ANALYSES = 'SORT_UNITARY_ANALYSES'
export const SORT_UNITARY_COMPOUNDS = 'SORT_UNITARY_COMPOUNDS'
export const REQUEST_SORT_UNITARY_ANALYSES = 'REQUEST_SORT_UNITARY_ANALYSES'
export const RECEIVE_SORT_UNITARY_ANALYSES = 'RECEIVE_SORT_UNITARY_ANALYSES'
export const REQUEST_SORT_UNITARY_COMPOUNDS = 'REQUEST_SORT_UNITARY_COMPOUNDS'
export const RECEIVE_SORT_UNITARY_COMPOUNDS = 'RECEIVE_SORT_UNITARY_COMPOUNDS'
export const UPDATE_VALIDATION_ANALYSE = 'UPDATE_VALIDATION_ANALYSE'
export const UPDATE_VALIDATION_COMPOUND = 'UPDATE_VALIDATION_COMPOUND'
export const UPDATE_PEAK_ANALYSE = 'UPDATE_PEAK_ANALYSE'
export const UPDATE_PEAK_COMPOUND = 'UPDATE_PEAK_COMPOUND'
export const REQUEST_UNITARY_ANALYSES_DISTINCT_VALIDATION =
  'REQUEST_UNITARY_ANALYSES_DISTINCT_VALIDATION'
export const RECEIVE_UNITARY_ANALYSES_DISTINCT_VALIDATION =
  'RECEIVE_UNITARY_ANALYSES_DISTINCT_VALIDATION'
export const REQUEST_UPDATE_PEAK_ANALYSE = 'REQUEST_UPDATE_PEAK_ANALYSE'
export const RECEIVE_UPDATE_PEAK_ANALYSE = 'RECEIVE_UPDATE_PEAK_ANALYSE'
export const REQUEST_UPDATE_PEAK_COMPOUND = 'REQUEST_UPDATE_PEAK_COMPOUND'
export const RECEIVE_UPDATE_PEAK_COMPOUND = 'RECEIVE_UPDATE_PEAK_COMPOUND'
export const RECEIVE_UPDATE_MAIN_CHANNEL = 'RECEIVE_UPDATE_MAIN_CHANNEL'
export const RECEIVE_UPDATE_UA_VALIDATION = 'RECEIVE_UPDATE_UA_VALIDATION'
export const RECEIVE_UPDATE_UA_INTERPOLATION = 'RECEIVE_UPDATE_UA_INTERPOLATION'
export const RECEIVE_UPDATE_UA_STD_POINT = 'RECEIVE_UPDATE_UA_STD_POINT'
export const RECEIVE_UPDATE_UA_SETTINGS = 'RECEIVE_UPDATE_UA_SETTINGS'
export const RECEIVE_UPDATE_COMPOUND_ISTD = 'RECEIVE_UPDATE_COMPOUND_ISTD'
export const RECEIVE_UPDATE_UNITARY_ANALYSES = 'RECEIVE_UPDATE_UNITARY_ANALYSES'
export const REQUEST_UNITARY_ANALYSES_DISTINCT = 'REQUEST_UNITARY_ANALYSES_DISTINCT'
export const RECEIVE_UNITARY_ANALYSES_DISTINCT = 'RECEIVE_UNITARY_ANALYSES_DISTINCT'
export const REQUEST_UNITARY_COMPOUND_DISTINCT = 'REQUEST_UNITARY_COMPOUND_DISTINCT'
export const RECEIVE_UNITARY_COMPOUND_DISTINCT = 'RECEIVE_UNITARY_COMPOUND_DISTINCT'
export const REQUEST_UNITARY_ANALYSES_CHANGE_SELECTED_CHANNEL =
  'REQUEST_UNITARY_ANALYSES_CHANGE_SELECTED_CHANNEL'
export const REQUEST_UNITARY_COMPOUNDS_CHANGE_SELECTED_CHANNEL =
  'REQUEST_UNITARY_COMPOUNDS_CHANGE_SELECTED_CHANNEL'
export const RECEIVE_UPDATE_UA_CALIBRATION = 'RECEIVE_UPDATE_UA_CALIBRATION'
export const REQUEST_UNITARY_ANALYSES_CHANGE_LOCK = 'REQUEST_UNITARY_ANALYSES_CHANGE_LOCK'
export const REQUEST_UNITARY_COMPOUND_ISTD = 'REQUEST_UNITARY_COMPOUND_ISTD'
export const RECEIVE_UNITARY_COMPOUND_ISTD = 'RECEIVE_UNITARY_COMPOUND_ISTD'
export const REQUEST_UNITARY_ANALYSES_COMPOUND_QC = 'REQUEST_UNITARY_ANALYSES_COMPOUND_QC'
export const RECEIVE_UNITARY_ANALYSES_COMPOUND_QC = 'RECEIVE_UNITARY_ANALYSES_COMPOUND_QC'
export const REQUEST_ALL_UNITARY_ANALYSES_ISTD = 'REQUEST_ALL_UNITARY_ANALYSES_ISTD'
export const RECEIVE_ALL_UNITARY_ANALYSES_ISTD = 'RECEIVE_ALL_UNITARY_ANALYSES_ISTD'
export const REQUEST_ALL_UNITARY_ANALYSES_COMPOUND_ISTD =
  'REQUEST_ALL_UNITARY_ANALYSES_COMPOUND_ISTD'
export const RECEIVE_ALL_UNITARY_ANALYSES_COMPOUND_ISTD =
  'RECEIVE_ALL_UNITARY_ANALYSES_COMPOUND_ISTD'
export const RECEIVE_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL =
  'RECEIVE_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL'
export const REQUEST_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL =
  'REQUEST_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL'
export const RECEIVE_RECOMPUTE_PEAKS = 'RECEIVE_RECOMPUTE_PEAKS'
export const FORCE_UA_FETCHING = 'FORCE_UA_FETCHING'
export const RECEIVE_RESET_PEAK_ANALYSE = 'RECEIVE_RESET_PEAK_ANALYSE'
export const REQUEST_GLOBAL_UA = 'REQUEST_GLOBAL_UA'
export const RECEIVE_GLOBAL_UA = 'RECEIVE_GLOBAL_UA'
export const REQUEST_GLOBAL_UA_SELECTED = 'REQUEST_GLOBAL_UA_SELECTED'
export const RECEIVE_GLOBAL_UA_SELECTED = 'RECEIVE_GLOBAL_UA_SELECTED'
export const REQUEST_UA_SHELL_FILTER = 'REQUEST_UA_SHELL_FILTER'
export const RECEIVE_UA_SHELL_FILTER = 'RECEIVE_UA_SHELL_FILTER'

export function forceUAFetching() {
  return async (dispatch) => {
    return dispatch(receiveForceUAFetching())
  }
}
function receiveForceUAFetching() {
  return {
    type: FORCE_UA_FETCHING,
  }
}

export function fetchSaveUASettings(uaId, settings, collection, batchId, analysisId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: settings }),
      }
      let url = `${FLASK_URL()}/unitary_analysis/${uaId}/parameters`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateSettings(data, collection, false))
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveUpdateSettings(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UA_SETTINGS,
    objectsUpdated: json,
    collection: collection,
  }
}

export function fetchUpdateUAValidation(data, collection, unitaryAnalyse, multiAnalyse, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          batch_id: batchId,
          analysis_id: unitaryAnalyse ? unitaryAnalyse.analyseId : null,
          body: data,
        }),
      }
      let url
      if (multiAnalyse) {
        url = `${FLASK_URL()}/unitary_analysis/validate`
      } else {
        url = `${FLASK_URL()}/unitary_analysis/${unitaryAnalyse._id}/validate`
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Update ua detail store
          dispatch(receiveUpdateValidationDetail(data, collection, false))
          resolve(dispatch(receiveUpdateValidation(data, collection, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveUpdateValidation(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UA_VALIDATION,
    objectsUpdated: json,
    collection: collection,
  }
}

export function fetchUpdateMainChannel(
  mainChannel,
  unitaryAnalyseId,
  collection,
  multiAnalyse,
  batchId,
  analysisId,
) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['value'] = mainChannel

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      let url = ''
      if (multiAnalyse) {
        url = `${FLASK_URL()}/molecule/${unitaryAnalyseId}/update_main_channel`
      } else {
        url = `${FLASK_URL()}/unitary_analysis/${unitaryAnalyseId}/update_main_channel`
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateMainChannel(data, collection, false))
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveUpdateMainChannel(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_MAIN_CHANNEL,
    unitaryAnalysesUpdated: json,
    collection: collection,
  }
}

export function fetchUpdateAnalysePeak(UAId, data, batchId, analysisId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // dispatch(requestUpdateAnalysePeak())

      const { unitaryAnalyses } = getState()
      const uaF = getUnitaryAnalysisFilteredById(unitaryAnalyses, UAId)

      if (!uaF) {
        // Maybe it's an ISTD that is updated, so check the ISTD data
        const ua_ISTD_from_analysis_view_store = getUnitaryAnalysisISTDById(unitaryAnalyses, UAId)
        const ua_ISTD_from_detail_view_store = getUnitaryAnalysisISTDFromDetailStore(
          unitaryAnalyses,
          UAId,
        )
        // In case of the analysis view didn't show the ISTD with option 'ISTD combionation',
        // the store 'allUnitaryAnalysesIstd' is empty. So we can't get the ISTD from there.
        // We should get it from the store reached by the detail view instead.
        const ua_ISTD =
          localStorage.getItem('SETTINGS_chromato_display') === 'ISTD combination'
            ? ua_ISTD_from_analysis_view_store
            : ua_ISTD_from_detail_view_store
        if (typeof ua_ISTD?.unlocked === 'boolean') {
          data['unlocked'] = ua_ISTD.unlocked
        }
      } else {
        if (typeof uaF.unlocked === 'boolean') {
          data['unlocked'] = uaF.unlocked
        }
      }

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      // // try {
      // const response = await fetch(
      //   `${FLASK_URL()}/unitary_analysis/${UAId}/update_peak`,
      //   requestOptions,
      // )
      // const authenticationError = response.status === 403
      // if (authenticationError) {
      //   dispatch(fetchChangeSettings('loginPopup', true))
      //   throw new Error("You're not logged.")
      // }
      // const json = await response.json()
      // if (response.status === 554) {
      //   const error = new Error(json.message)
      //   error.code = response.status
      //   throw error
      // }
      //
      // // Update ua detail store
      // dispatch(receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'analyse', authenticationError))
      // return dispatch(receiveUpdateAnalysePeak(json, data.channel, authenticationError))
      fetch(`${FLASK_URL()}/unitary_analysis/${UAId}/update_peak`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update ua detail store
          dispatch(receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'analyse', false))
          resolve(dispatch(receiveUpdateAnalysePeak(json, data.channel, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function requestUpdateAnalysePeak() {
  return {
    type: REQUEST_UPDATE_PEAK_ANALYSE,
  }
}
function receiveUpdateAnalysePeak(json, channel, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_PEAK_ANALYSE,
    peakChannel: channel,
    unitaryAnalysesUpdated: json,
  }
}

export function fetchResetAnalysePeak(UAId, data, batchId, analysisId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      fetch(`${FLASK_URL()}/unitary_analysis/${UAId}/reset_peak`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update ua detail store
          dispatch(receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'analyse', false))
          resolve(dispatch(receiveResetAnalysePeak(json, data.channel, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

function receiveResetAnalysePeak(json, channel, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_PEAK_ANALYSE,
    peakChannel: channel,
    unitaryAnalysesUpdated: json,
  }
}

export function fetchUpdateCompoundPeak(UAId, data, batchId, analysisId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // dispatch(requestUpdateCompoundPeak())

      const { unitaryAnalyses } = getState()
      const uaF = getUnitaryAnalysisCompoundById(unitaryAnalyses, UAId)

      if (!uaF) {
        // Maybe it's an ISTD that is updated, so check the ISTD data
        const ua_ISTD = getUnitaryAnalysisCompoundISTDById(unitaryAnalyses, UAId)
        if (ua_ISTD.unlocked) {
          data['unlocked'] = ua_ISTD.unlocked
        }
      } else {
        if (uaF.unlocked) {
          data['unlocked'] = uaF.unlocked
        }
      }

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      // const response = await fetch(
      //   `${FLASK_URL()}/unitary_analysis/${UAId}/update_peak`,
      //   requestOptions,
      // )
      // const authenticationError = response.status === 403
      // if (authenticationError) {
      //   dispatch(fetchChangeSettings('loginPopup', true))
      //   throw new Error("You're not logged.")
      // }
      // const json = await response.json()
      // if (response.status === 554) {
      //   const error = new Error(json.message)
      //   error.code = response.status
      //   throw error
      // }
      // // Update ua detail store
      // dispatch(
      //   receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'compound', authenticationError),
      // )
      //
      // return dispatch(receiveUpdateCompoundPeak(json, data.channel, authenticationError))
      fetch(`${FLASK_URL()}/unitary_analysis/${UAId}/update_peak`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update ua detail store
          dispatch(receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'compound', false))
          resolve(dispatch(receiveUpdateCompoundPeak(json, data.channel, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function fetchResetCompoundPeak(UAId, data, batchId, analysisId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      fetch(`${FLASK_URL()}/unitary_analysis/${UAId}/reset_peak`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update ua detail store
          dispatch(receiveUpdateUnitaryAnalysesDetail(json, data.channel, 'compound', false))
          resolve(dispatch(receiveResetCompoundPeak(json, data.channel, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

function receiveResetCompoundPeak(json, channel, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_PEAK_COMPOUND,
    peakChannel: channel,
    unitaryAnalysesUpdated: json,
  }
}

export function fetchUpdateGlobalPeak(UAId, data, selectedChannel, batchId, analysisId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analysisId, body: data }),
      }
      fetch(`${FLASK_URL()}/unitary_analysis/${UAId}/integration_peak`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          dispatch(receiveUpdateCompoundPeak(json, selectedChannel, false))
          resolve(json)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function requestUpdateCompoundPeak() {
  return {
    type: REQUEST_UPDATE_PEAK_COMPOUND,
  }
}
function receiveUpdateCompoundPeak(json, channel, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_PEAK_COMPOUND,
    peakChannel: channel,
    unitaryAnalysesUpdated: json,
  }
}
export function fetchUpdateAnalyseValidation(analyseId, validation) {
  return {
    type: UPDATE_VALIDATION_ANALYSE,
    analyseId: analyseId,
    validation: validation,
  }
}
export function fetchUpdateCompoundValidation(analyseId, validation) {
  return {
    type: UPDATE_VALIDATION_COMPOUND,
    analyseId: analyseId,
    validation: validation,
  }
}

export function sortUnitaryAnalyses(sort, sortBy) {
  return async (dispatch) => {
    dispatch(requestSortUnitaryAnalyses())
    return dispatch(receiveSortUnitaryAnalyses(sort, sortBy))
  }
}

export function requestSortUnitaryAnalyses() {
  return {
    type: REQUEST_SORT_UNITARY_ANALYSES,
  }
}

export function receiveSortUnitaryAnalyses(sort, sortBy) {
  return {
    type: RECEIVE_SORT_UNITARY_ANALYSES,
    sort: sort,
    sortBy: sortBy,
  }
}

export function sortUnitaryCompounds(sort, sortBy) {
  return async (dispatch) => {
    dispatch(requestSortUnitaryCompounds())
    return dispatch(receiveSortUnitaryCompounds(sort, sortBy))
  }
}

export function requestSortUnitaryCompounds() {
  return {
    type: REQUEST_SORT_UNITARY_COMPOUNDS,
  }
}

export function receiveSortUnitaryCompounds(sort, sortBy) {
  return {
    type: RECEIVE_SORT_UNITARY_COMPOUNDS,
    sort: sort,
    sortBy: sortBy,
  }
}

// export function sortUnitaryAnalyses(sort, sortBy) {
//   return {
//     type: SORT_UNITARY_ANALYSES,
//     sort: sort,
//     sortBy: sortBy,
//   }
// }

// export function sortUnitaryCompounds(sort, sortBy) {
//   return {
//     type: SORT_UNITARY_COMPOUNDS,
//     sort: sort,
//     sortBy: sortBy,
//   }
// }

export function requestUnitaryAnalyses(analyseId) {
  return {
    type: REQUEST_UNITARY_ANALYSES,
    analyseId: analyseId,
  }
}

export function requestUnitaryAnalysesCompound(
  batchId,
  moleculeEvent,
  untreated,
  search,
  sorter,
  dateLock,
) {
  return {
    type: REQUEST_UNITARY_ANALYSES_COMPOUND,
    moleculeEvent: moleculeEvent,
    batchId: batchId,
    sorter: sorter,
    untreated: untreated,
    search: search,
    dateLock: dateLock,
  }
}

export function requestUnitaryAnalysesFiltered(
  analyseId,
  gridFilter,
  requested,
  untreated,
  sorter,
  search,
  dateLock,
) {
  return {
    type: REQUEST_UNITARY_ANALYSES_FILTERED,
    analyseId: analyseId,
    filter: gridFilter,
    requested: requested,
    untreated: untreated,
    sorter: sorter,
    search: search,
    dateLock: dateLock,
  }
}

export function receiveUnitaryAnalyses(unitaryAnalyses, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES,
    unitaryAnalyses: unitaryAnalyses,
  }
}

export function receiveUnitaryAnalysesCompound(
  unitaryAnalyses,
  authenticationError,
  sorter,
  search,
  filter,
  dateLock,
) {
  let unitaryAnalysesCompound = []

  if (unitaryAnalyses && unitaryAnalyses.length > 0) {
    unitaryAnalysesCompound = unitaryAnalyses.map(function (unitAnalyse) {
      return rebuildUnitaryCompounds(unitAnalyse)
    })

    // Apply good order for analyses
    unitaryAnalysesCompound = setOrderValueFromAnalysisOrder(unitaryAnalysesCompound)

    if (filter && filter.content.columnFilters) {
      filter.content.columnFilters.forEach((colF) => {
        unitaryAnalysesCompound = unitaryAnalysesCompound.filter((ua) => {
          // Test if the column inside the filter exist for the UA
          // return true if not.
          if (Object.keys(ua).includes(colF.column)) {
            switch (colF.operator) {
              case '=':
                return ua[colF.column] === parseFloat(colF.value)
              case '!=':
                return ua[colF.column] !== parseFloat(colF.value)
              case '>':
                return ua[colF.column] > parseFloat(colF.value)
              case '>=':
                return ua[colF.column] >= parseFloat(colF.value)
              case '<':
                return ua[colF.column] < parseFloat(colF.value)
              case '<=':
                return ua[colF.column] <= parseFloat(colF.value)
              case 'empty':
                return ua[colF.column] === null || ua[colF.column] === ''
              case 'notEmpty':
                return ua[colF.column] !== null || ua[colF.column] !== ''
              case 'is':
                return (
                  ua[colF.column] ===
                  (typeof ua[colF.column] === 'boolean'
                    ? colF.value === 'true'
                    : colF.value === 'true'
                    ? 'True'
                    : 'False')
                )
              case 'contains':
                return ua[colF.column].indexOf(colF.value) !== -1
              case 'equals':
                return ua[colF.column] === colF.value
              case 'starts':
                return ua[colF.column].startsWith(colF.value)
              case 'ends':
                return ua[colF.column].endsWith(colF.value)
            }
          } else {
            return true
          }
        })
      })
    }

    if (sorter) {
      const sortTab = sorter.split('$')
      unitaryAnalysesCompound = stableSort(
        unitaryAnalysesCompound,
        getComparator(sortTab[0], sortTab[1]),
      )
    }
    if (search) {
      unitaryAnalysesCompound = unitaryAnalysesCompound.filter(
        (ua) =>
          (ua.analyse && ua.analyse.toLowerCase().includes(search.toLowerCase())) ||
          (ua.type && ua.type.toLowerCase().includes(search.toLowerCase())) ||
          (ua.s_n && ua.s_n.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.rt && ua.rt.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.area && ua.area.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.level && ua.level.toString().toLowerCase().includes(search.toLowerCase())),
      )
    }
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES_COMPOUND,
    unitaryAnalysesCompound: unitaryAnalysesCompound,
    dateLock: dateLock,
  }
}
export function receiveUnitaryAnalysesFiltered(
  unitaryAnalyses,
  authenticationError,
  sorter,
  search,
  filter,
  dateLock,
) {
  // Threat data
  let unitaryAnalysesFiltered = []
  if (unitaryAnalyses) {
    unitaryAnalysesFiltered = unitaryAnalyses.map(function (unitAnalyse) {
      return rebuildUnitaryAnalyses(unitAnalyse)
    })
    // Filter by columnFilters
    if (filter && filter.content.columnFilters) {
      filter.content.columnFilters.forEach((colF) => {
        unitaryAnalysesFiltered = unitaryAnalysesFiltered.filter((ua) => {
          // Test if the column inside the filter exist for the UA
          // return true if not.
          if (Object.keys(ua).includes(colF.column)) {
            switch (colF.operator) {
              case '=':
                return ua[colF.column] === parseFloat(colF.value)
              case '!=':
                return ua[colF.column] !== parseFloat(colF.value)
              case '>':
                return ua[colF.column] > parseFloat(colF.value)
              case '>=':
                return ua[colF.column] >= parseFloat(colF.value)
              case '<':
                return ua[colF.column] < parseFloat(colF.value)
              case '<=':
                return ua[colF.column] <= parseFloat(colF.value)
              case 'empty':
                return ua[colF.column] === null || ua[colF.column] === ''
              case 'notEmpty':
                return ua[colF.column] !== null || ua[colF.column] !== ''
              case 'is':
                return (
                  ua[colF.column] ===
                  (typeof ua[colF.column] === 'boolean'
                    ? colF.value === 'true'
                    : colF.value === 'true'
                    ? 'True'
                    : 'False')
                )
              case 'contains':
                return ua[colF.column].indexOf(colF.value) !== -1
              case 'equals':
                return ua[colF.column] === colF.value
              case 'starts':
                return ua[colF.column].startsWith(colF.value)
              case 'ends':
                return ua[colF.column].endsWith(colF.value)
            }
          } else {
            return true
          }
        })
      })
    }
    if (sorter) {
      const sortTab = sorter.split('$')
      unitaryAnalysesFiltered = stableSort(
        unitaryAnalysesFiltered,
        getComparator(sortTab[0], sortTab[1]),
      )
    }
    if (search) {
      unitaryAnalysesFiltered = unitaryAnalysesFiltered.filter(
        (ua) =>
          (ua.peakName.name && ua.peakName.name.toLowerCase().includes(search.toLowerCase())) ||
          (ua.type && ua.type.toLowerCase().includes(search.toLowerCase())) ||
          (ua.s_n && ua.s_n.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.rt && ua.rt.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.area && ua.area.toString().toLowerCase().includes(search.toLowerCase())) ||
          (ua.level && ua.level.toString().toLowerCase().includes(search.toLowerCase())),
      )
    }
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES_FILTERED,
    unitaryAnalysesFiltered: unitaryAnalysesFiltered,
    dateLock: dateLock,
  }
}

export function fetchUnitaryAnalyses(analyseId) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalyses(analyseId))

    let filter = {
      property: 'content.analysis.id',
      value: analyseId,
      filterType: 'string',
      operator: 'eq',
    }
    let p = encodeURIComponent(JSON.stringify(filter))

    const url = `${API_BO_URL()}.unitary_analysis/page/?filter=%5B${p}%5D`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAnalyses(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analyses',
          <Fragment>
            <div>An error does not allow to get unitary analyses:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
let controllerUnitaryAnalysesFilteredSaved = null

export function fetchUnitaryAnalysesFiltered(
  analyseId,
  gridFilter,
  requested,
  untreated,
  detected,
  notDetected,
  relaunch,
  sorter,
  search,
) {
  return async (dispatch) => {
    if (controllerUnitaryAnalysesFilteredSaved) {
      controllerUnitaryAnalysesFilteredSaved.abort()
    }
    const controller = new AbortController()
    controllerUnitaryAnalysesFilteredSaved = controller

    // setTimeout(() => controller.abort(), 500)
    const dateLock = new Date().getTime()
    dispatch(
      requestUnitaryAnalysesFiltered(
        analyseId,
        gridFilter,
        requested,
        untreated,
        sorter,
        search,
        dateLock,
      ),
    )

    let sort = 'sort=content.global_score&dir=ASC'
    // if (sorter) {
    //   const sortTab = sorter.split('$')
    //   sort = 'sort=content.' + sortTab[1] + '&dir=' + sortTab[0]
    // }

    let filter = [
      {
        property: 'content.analysis.id',
        value: analyseId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: gridFilter === 0 ? 'True' : 'False',
        filterType: 'string',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      filter.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    if (requested) {
      filter.push({
        property: 'content.requested',
        value: requested,
        filterType: 'boolean',
        operator: 'eq',
      })
    }

    if (untreated) {
      filter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (detected || notDetected || relaunch) {
      if (!detected) {
        filter.push({
          property: 'content.validation',
          value: '1',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!notDetected) {
        filter.push({
          property: 'content.validation',
          value: '2',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!relaunch) {
        filter.push({
          property: 'content.validation',
          value: '3',
          filterType: 'string',
          operator: 'ne',
        })
      }
      filter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'ne',
      })
    }
    if (gridFilter > 0 && gridFilter < 6) {
      let gridFilterParam = ''
      switch (gridFilter) {
        // case 0:
        //   gridFilterParam = true
        //   break
        case 1:
          gridFilterParam = 'detected'
          break
        case 2:
          gridFilterParam = 'suspected'
          break
        case 3:
          gridFilterParam = 'excluded'
          break
        case 4:
          gridFilterParam = 'ok'
          break
        case 5:
          gridFilterParam = 'ko'
          break
        default:
          gridFilterParam = 'True'
      }
      filter.push({
        property: 'content.classification',
        value: gridFilterParam,
        filterType: 'string',
        operator: 'eq',
      })
    }

    // if (search) {
    //   filter.push({
    //     property: 'content.molecule.name',
    //     value: search,
    //     filterType: 'string',
    //     operator: 'eq',
    //   })
    // }

    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        signal: controller.signal,
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        // Custom filters
        let currentFilter = null
        if (localStorage.getItem('GLOBAL_filter_selected')) {
          const customFilter = JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))
          if (customFilter !== 0) {
            currentFilter = await getFilterRawById(customFilter)
            if (currentFilter) {
              // Filter by compounds
              const cf = currentFilter.content.compounds.map((cp) => cp.name)
              if (cf.length > 0) {
                result = result.filter((ua) => cf.includes(ua.name))
              }
            }
          }
        }
        dispatch(
          receiveUnitaryAnalysesFiltered(result, false, sorter, search, currentFilter, dateLock),
        )
        return result
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get unitary analysis',
            <Fragment>
              <div>An error does not allow to get unitary analysis:</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
          dispatch(receiveUnitaryAnalysesFiltered([], null, null, null, null, dateLock))
          return error
        }
      })
      .finally(() => {
        if (controllerUnitaryAnalysesFilteredSaved === controller) {
          controllerUnitaryAnalysesFilteredSaved = null
        }
        controller.abort()
      })
  }
}

export function fetchUnitaryAnalysesAllISTD(analyseId) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesAllISTD(analyseId))

    let sort = 'sort=content.order&dir=ASC'

    let filter = [
      {
        property: 'content.analysis.id',
        value: analyseId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'True',
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAllAnalysesISTD(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
        return dispatch(receiveUnitaryAllAnalysesISTD([], false))
      })
  }
}

export function requestUnitaryAnalysesAllISTD(analyseId) {
  return {
    type: REQUEST_ALL_UNITARY_ANALYSES_ISTD,
    ISTDAnalyseId: analyseId,
  }
}

export function receiveUnitaryAllAnalysesISTD(istd, authenticationError) {
  // Threat data
  let unitaryAnalysesIstd = []
  if (istd) {
    unitaryAnalysesIstd = istd.map(function (unitAnalyse) {
      return rebuildUnitaryAnalyses(unitAnalyse)
    })
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_ALL_UNITARY_ANALYSES_ISTD,
    allUnitaryAnalysesIstd: unitaryAnalysesIstd,
  }
}
export function fetchUnitaryAnalysesCompoundAllISTD(batchId, moleculeEvent) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesCompoundAllISTD(batchId, moleculeEvent))

    let sort = 'sort=content.order&dir=ASC'

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.molecule.event',
        value: moleculeEvent,
        filterType: 'number',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'True',
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAllAnalysesCompoundISTD(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis compound',
          <Fragment>
            <div>An error does not allow to get unitary analysis compound:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function requestUnitaryAnalysesCompoundAllISTD(batchId, moleculeEvent) {
  return {
    type: REQUEST_ALL_UNITARY_ANALYSES_COMPOUND_ISTD,
  }
}

export function receiveUnitaryAllAnalysesCompoundISTD(istd, authenticationError) {
  // Threat data
  let unitaryAnalysesCompoundIstd = []
  if (istd) {
    unitaryAnalysesCompoundIstd = istd.map(function (unitAnalyse) {
      return rebuildUnitaryCompounds(unitAnalyse)
    })
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_ALL_UNITARY_ANALYSES_COMPOUND_ISTD,
    allUnitaryAnalysesCompoundIstd: unitaryAnalysesCompoundIstd,
  }
}

export function fetchQCUnitaryAnalysesCompound(batchId, moleculeEvent) {
  return async (dispatch) => {
    dispatch(requestQCUnitaryAnalysesCompound(batchId, moleculeEvent))
    let filter = ['blank', 'matrix_bio', 'sample', 'other']
      .map((f) => ({
        property: 'content.analysis_type',
        value: f,
        filterType: 'string',
        operator: 'ne',
      }))
      .concat([
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: moleculeEvent,
          filterType: 'number',
          operator: 'eq',
        },
      ])
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveQCUnitaryAnalysesCompound(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Quality Control Unitary Analyses Compound',
          <Fragment>
            <div>An error does not allow to get Quality Control Unitary Analyses Compound:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestQCUnitaryAnalysesCompound(batchId, moleculeEvent) {
  return {
    type: REQUEST_UNITARY_ANALYSES_COMPOUND_QC,
    QCBatchId: batchId,
    QCMoleculeEvent: moleculeEvent,
  }
}
export function receiveQCUnitaryAnalysesCompound(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES_COMPOUND_QC,
    unitaryAnalysesCompoundQC: json,
  }
}

let controllerUnitaryAnalysesCompoundSaved = null
export function fetchUnitaryAnalysesCompound(
  batchId,
  moleculeEvent,
  untreated,
  requested,
  validationDetected,
  validationNotDetected,
  validationRelaunch,
  std,
  mb,
  mbd,
  stde,
  blk,
  spl,
  oth,
  classificationAllDetected,
  classificationAllSuspected,
  classificationAllExcluded,
  search,
  sorter,
) {
  return async (dispatch) => {
    if (controllerUnitaryAnalysesCompoundSaved) {
      controllerUnitaryAnalysesCompoundSaved.abort()
    }
    const controller = new AbortController()
    controllerUnitaryAnalysesCompoundSaved = controller
    const dateLock = new Date().getTime()
    dispatch(
      requestUnitaryAnalysesCompound(batchId, moleculeEvent, untreated, search, sorter, dateLock),
    )

    let sort = 'sort=content.global_score&dir=ASC'

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.molecule.event',
        value: moleculeEvent,
        filterType: 'number',
        operator: 'eq',
      },
    ]
    if (untreated) {
      filter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (requested) {
      filter.push({
        property: 'content.requested',
        value: requested,
        filterType: 'boolean',
        operator: 'eq',
      })
    }
    if (validationDetected || validationNotDetected || validationRelaunch) {
      if (!validationDetected) {
        filter.push({
          property: 'content.validation',
          value: '1',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!validationNotDetected) {
        filter.push({
          property: 'content.validation',
          value: '2',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!validationRelaunch) {
        filter.push({
          property: 'content.validation',
          value: '3',
          filterType: 'string',
          operator: 'ne',
        })
      }
      filter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'ne',
      })
    }
    if (std || mb || mbd || stde || blk || spl || oth) {
      if (!std) {
        filter.push({
          property: 'content.analysis_type',
          value: 'calibration',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!mb) {
        filter.push({
          property: 'content.analysis_type',
          value: 'matrix_bio',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!mbd) {
        filter.push({
          property: 'content.analysis_type',
          value: 'matrix_bio_doped',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!stde) {
        filter.push({
          property: 'content.analysis_type',
          value: 'std_end',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!blk) {
        filter.push({
          property: 'content.analysis_type',
          value: 'blank',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!spl) {
        filter.push({
          property: 'content.analysis_type',
          value: 'sample',
          filterType: 'string',
          operator: 'ne',
        })
      } else if (
        classificationAllDetected ||
        classificationAllSuspected ||
        classificationAllExcluded
      ) {
        if (!classificationAllDetected) {
          filter.push({
            property: 'content.classification',
            value: 'detected',
            filterType: 'string',
            operator: 'ne',
          })
        }
        if (!classificationAllSuspected) {
          filter.push({
            property: 'content.classification',
            value: 'suspected',
            filterType: 'string',
            operator: 'ne',
          })
        }
        if (!classificationAllExcluded) {
          filter.push({
            property: 'content.classification',
            value: 'excluded',
            filterType: 'string',
            operator: 'ne',
          })
        }
      }
      if (!oth) {
        filter.push({
          property: 'content.analysis_type',
          value: 'other',
          filterType: 'string',
          operator: 'ne',
        })
      }
    }
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        signal: controller.signal,
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        // Custom filters
        let currentFilter = null
        if (localStorage.getItem('GLOBAL_filter_selected')) {
          const customFilter = JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))
          if (customFilter !== 0) {
            currentFilter = await getFilterRawById(customFilter)
          }
        }
        return dispatch(
          receiveUnitaryAnalysesCompound(result, false, sorter, search, currentFilter, dateLock),
        )
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get unitary analysis compound',
            <Fragment>
              <div>An error does not allow to get unitary analysis compound:</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
          receiveUnitaryAnalysesCompound([], false, null, null, null, dateLock)
        }
      })
      .finally(() => {
        if (controllerUnitaryAnalysesCompoundSaved === controller) {
          controllerUnitaryAnalysesCompoundSaved = null
        }
        controller.abort()
      })
  }
}

export function fetchUnitaryAnalysesCompoundsForDetail(batchId, moleculeEvent) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesCompoundsForDetail(batchId, moleculeEvent))

    let sort = 'sort=content.order&dir=ASC'

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.molecule.event',
        value: moleculeEvent,
        filterType: 'number',
        operator: 'eq',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))
    const includes = [
      'name',
      '_id',
      'content.main_channel',
      'content.time',
      'content.channels',
      // 'content.channels.[].ampl',
      // 'content.channels.[].concentration',
      // 'content.channels.[].peak.area',
      // 'content.channels.[].rt_calib',
      // 'content.channels.[].rt_corrected',
      'content.analysis.name',
      'content.molecule.ret_time',
    ]
      .map((inc) => 'include=' + inc)
      .join('&')
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAnalysesCompoundsForDetail(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analyses compounds',
          <Fragment>
            <div>An error does not allow to get unitary analyses compounds for detail:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
  }
}

export function requestUnitaryAnalysesCompoundsForDetail(batchId, moleculeEvent) {
  return {
    type: REQUEST_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL,
    detailBatchId: batchId,
    detailMoleculeEvent: moleculeEvent,
  }
}

export function receiveUnitaryAnalysesCompoundsForDetail(unitaryAnalyses, authenticationError) {
  let unitaryAnalysesCompound = []
  if (unitaryAnalyses) {
    unitaryAnalysesCompound = unitaryAnalyses.map(function (unitAnalyse) {
      return rebuildUnitaryCompounds(unitAnalyse)
    })
    // Apply good order for analyses
    unitaryAnalysesCompound = setOrderValueFromAnalysisOrder(unitaryAnalysesCompound)
  }
  return {
    type: authenticationError
      ? AUTHENTICATION_FAILURE
      : RECEIVE_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL,
    unitaryAnalysesCompoundForDetail: unitaryAnalysesCompound,
  }
}

export function receiveUpdateInterpolationForUA(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UA_INTERPOLATION,
    collection: collection,
    objectsUpdated: json,
  }
}

export function receiveUpdateSTDForUA(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UA_STD_POINT,
    collection: collection,
    objectsUpdated: json,
  }
}

export function receiveUpdateCalibrationForUA(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UA_CALIBRATION,
    collection: collection,
    objectsUpdated: json,
  }
}

export function fetchUpdateCompoundISTD(istd_newGroup, istd_event, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['event'] = istd_event
      data['new_istd_group'] = istd_newGroup

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }

      let url = `${FLASK_URL()}/batch/${batchId}/istd`

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Update batches store
          dispatch(receiveUpdateBatches(data.batches, false))
          dispatch(receiveUpdateCompoundISTD(data, null, false))
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

function receiveUpdateCompoundISTD(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_COMPOUND_ISTD,
    objectsUpdated: json,
  }
}

export function receiveUpdateUnitaryAnalyses(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UNITARY_ANALYSES,
    objectsUpdated: json,
  }
}

export function fetchUnitaryAnalysesDistinct(analysisId, requested) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesDistinct(analysisId, requested))

    // Custom filters
    let customFilter = null
    if (localStorage.getItem('GLOBAL_filter_selected')) {
      customFilter = JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))
    }
    if (customFilter && customFilter !== 0) {
      let fullRaw = await getFilterRawById(customFilter)
      if (fullRaw) {
        const filterURL = [
          {
            property: 'content.analysis.id',
            value: analysisId,
            filterType: 'string',
            operator: 'eq',
          },
        ]

        // Show/Hide unknown molecule
        if (
          localStorage.getItem('SETTINGS_show_unknown') &&
          localStorage.getItem('SETTINGS_show_unknown') === 'false'
        ) {
          filterURL.push({
            property: 'content.molecule.unknown',
            value: true,
            filterType: 'boolean',
            operator: 'ne',
          })
        }

        const url = encodeURIComponent(JSON.stringify(filterURL))
        const includes =
          '&include=' +
          [
            'name',
            'content.istd',
            'content.classification',
            'content.validation',
            'content.requested',
          ].join('&include=')
        return fetch(`${API_BO_URL()}.unitary_analysis?filter=${url}${includes}`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((json) => {
            let result = json
            // Filter by compound in case of
            const fullRawCompounds = fullRaw.content.compounds.map((cp) => cp.name)
            if (fullRawCompounds.length > 0) {
              result = result.filter((ua) => fullRawCompounds.includes(ua.name))
            }
            const distinct = {
              0: { count: 0 }, //Validation empty
              1: { count: 0 }, //Validation detected
              2: { count: 0 }, //Validation not detected
              3: { count: 0 }, //Validation relaunch
              True: { count: 0 }, // ISTD
              False: { count: 0 }, // Not ISTD
              detected: { count: 0 }, //Molecule detected
              excluded: { count: 0 }, //Molecule excluded
              requested: { count: 0 }, //Requested by client
              suspected: { count: 0 }, //Molecule suspected
              ok: { count: 0 }, //Molecule Ok
              ko: { count: 0 }, //Molecule Ko
            }

            // Update the distinct result
            result.forEach((ua, index) => {
              if (ua.content.istd === 'True') {
                // if (ua.type === 'ISTD') {
                distinct['True'].count = distinct['True'].count + 1
              } else {
                distinct[ua.content.classification].count =
                  distinct[ua.content.classification].count + 1
                // distinct[ua.status].count = distinct[ua.status].count + 1
                distinct[ua.content.validation].count = distinct[ua.content.validation].count + 1
                // distinct[ua.validation].count = distinct[ua.validation].count + 1
                if (ua.content.requested) {
                  // if (ua.requested === 'True') {
                  distinct['requested'].count = distinct['requested'].count + 1
                }
              }
            })
            return dispatch(receiveUnitaryAnalysesDistinct(distinct, false))
          })
          .catch((error) => {
            if (error.status === 403) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            displayPopupMessage(
              dispatch,
              'error',
              'Failed to get unitary analysis',
              <Fragment>
                <div>An error does not allow to get unitary analysis distinct:</div>
                {generateErrorPart(error)}
                <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
              </Fragment>,
            )
          })
      } else {
        return getUnitaryAnalysis(dispatch, analysisId, requested)
      }
    } else {
      return getUnitaryAnalysis(dispatch, analysisId, requested)
    }
  }
}

async function getUnitaryAnalysis(dispatch, analysisId, requested) {
  const p1 = new Promise((resolve, reject) => {
    let filter = [
      {
        property: 'content.analysis.id',
        value: analysisId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'False',
        filterType: 'string',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      filter.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    if (requested === true) {
      filter.push({
        property: 'content.requested',
        value: true,
        filterType: 'boolean',
        operator: 'eq',
      })
    }
    let p = encodeURIComponent(JSON.stringify(filter))
    fetch(`${API_BO_URL()}.unitary_analysis/distinct/content.classification?filter=${p}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        resolve(json)
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        reject(error)
      })
  })

  const p2 = new Promise((resolve, reject) => {
    let istdfilter = [
      {
        property: 'content.analysis.id',
        value: analysisId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'True',
        filterType: 'string',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      istdfilter.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    if (requested === true) {
      istdfilter.push({
        property: 'content.requested',
        value: true,
        filterType: 'boolean',
        operator: 'eq',
      })
    }
    let f = encodeURIComponent(JSON.stringify(istdfilter))
    fetch(`${API_BO_URL()}.unitary_analysis/distinct/content.istd?filter=${f}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        resolve(json)
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        reject(error)
      })
  })

  const p3 = new Promise((resolve, reject) => {
    let filterRequested = [
      {
        property: 'content.analysis.id',
        value: analysisId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'False',
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.requested',
        value: true,
        filterType: 'boolean',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      filterRequested.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    let r = encodeURIComponent(JSON.stringify(filterRequested))
    fetch(`${API_BO_URL()}.unitary_analysis?filter=${r}&include=_id`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        resolve({
          requested: {
            count: json.length,
          },
        })
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        reject(error)
      })
  })

  const p4 = new Promise((resolve, reject) => {
    let filterValidation = [
      {
        property: 'content.analysis.id',
        value: analysisId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'False',
        filterType: 'string',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      filterValidation.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    let v = encodeURIComponent(JSON.stringify(filterValidation))
    fetch(`${API_BO_URL()}.unitary_analysis/distinct/content.validation?filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        resolve(json)
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        reject(error)
      })
  })

  Promise.all([p1, p2, p3, p4]).then(
    function (returns) {
      const json = Object.assign({}, returns[0], returns[1], returns[2], returns[3])
      return dispatch(receiveUnitaryAnalysesDistinct(json, false))
    },
    function (error) {
      return error
    },
  )
}

export function requestUnitaryAnalysesDistinct(analysisId, requested) {
  return {
    type: REQUEST_UNITARY_ANALYSES_DISTINCT,
    analysisId: analysisId,
    requested: requested,
  }
}

export function receiveUnitaryAnalysesDistinct(unitaryAnalysesDistinct, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES_DISTINCT,
    unitaryAnalysesDistinct: unitaryAnalysesDistinct,
  }
}

export function fetchUnitaryCompoundDistinct(batchId, moleculeEvent) {
  return async (dispatch) => {
    dispatch(requestUnitaryCompoundDistinct(batchId, moleculeEvent))
    const p1 = new Promise((resolve, reject) => {
      let filterRequested = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: moleculeEvent,
          filterType: 'number',
          operator: 'eq',
        },
        {
          property: 'content.requested',
          value: true,
          filterType: 'boolean',
          operator: 'eq',
        },
      ]
      let r = encodeURIComponent(JSON.stringify(filterRequested))
      fetch(`${API_BO_URL()}.unitary_analysis?filter=${r}&include=_id`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve({
            requested: {
              count: json.length,
            },
          })
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
    const p2 = new Promise((resolve, reject) => {
      let filterValidation = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: moleculeEvent,
          filterType: 'number',
          operator: 'eq',
        },
      ]
      let v = encodeURIComponent(JSON.stringify(filterValidation))
      fetch(`${API_BO_URL()}.unitary_analysis/distinct/content.validation?filter=${v}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
    const p3 = new Promise((resolve, reject) => {
      let filterValidation = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: moleculeEvent,
          filterType: 'number',
          operator: 'eq',
        },
      ]
      let t = encodeURIComponent(JSON.stringify(filterValidation))
      fetch(`${API_BO_URL()}.unitary_analysis/distinct/content.analysis_type?filter=${t}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
    const p4 = new Promise((resolve, reject) => {
      let filterClassification = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: moleculeEvent,
          filterType: 'number',
          operator: 'eq',
        },
      ]
      filterClassification.push({
        property: 'content.analysis_type',
        value: 'sample',
        filterType: 'string',
        operator: 'eq',
      })
      let classification = encodeURIComponent(JSON.stringify(filterClassification))
      fetch(
        `${API_BO_URL()}.unitary_analysis/distinct/content.classification?filter=${classification}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      )
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
    Promise.all([p1, p2, p3, p4]).then(
      function (returns) {
        const json = Object.assign({}, returns[0], returns[1], returns[2], returns[3])
        return dispatch(receiveUnitaryCompoundDistinct(json, false))
      },
      function (error) {
        return error
      },
    )
  }
}

export function requestUnitaryCompoundDistinct(batchId, moleculeEvent) {
  return {
    type: REQUEST_UNITARY_COMPOUND_DISTINCT,
    batchId: batchId,
    moleculeEvent: moleculeEvent,
  }
}

export function receiveUnitaryCompoundDistinct(unitaryCompoundDistinct, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_COMPOUND_DISTINCT,
    unitaryCompoundDistinct: unitaryCompoundDistinct,
  }
}

export function fetchChangeSelectedChannel(uaId, channel, type) {
  return async (dispatch) => {
    return dispatch({
      type:
        type === 'analyse'
          ? REQUEST_UNITARY_ANALYSES_CHANGE_SELECTED_CHANNEL
          : REQUEST_UNITARY_COMPOUNDS_CHANGE_SELECTED_CHANNEL,
      uaId: uaId,
      channel: channel,
    })
  }
}

export function fetchChangeLock(uaId, type) {
  return async (dispatch) => {
    return dispatch({
      type: REQUEST_UNITARY_ANALYSES_CHANGE_LOCK,
      collection: type,
      uaId: uaId,
    })
  }
}
export function fetchUnitaryAnalysesISTD(batchId, analyseId, istdEvent) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesISTD(batchId, analyseId, istdEvent))

    let sort = 'sort=content.order&dir=ASC'

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      // {
      //   property: 'content.analysis.id',
      //   value: analyseId,
      //   filterType: 'string',
      //   operator: 'eq',
      // },
      {
        property: 'content.molecule.event',
        value: istdEvent,
        filterType: 'number',
        operator: 'eq',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))

    // const response = await fetch(`${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`, {
    //   method: 'GET',
    //   credentials: 'include',
    // })
    // const authenticationError = response.status === 403
    // if (authenticationError) {
    //   return dispatch(fetchChangeSettings('loginPopup', true))
    // }
    // const json = await response.json()
    // return dispatch(receiveUnitaryAnalysesISTD(json.result, authenticationError))
    const url = `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return json.result
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAnalysesISTD(result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis ISTD:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
  }
}

export function requestUnitaryAnalysesISTD(batchId, analyseId, istdEvent) {
  return {
    type: REQUEST_UNITARY_COMPOUND_ISTD,
    batchId: batchId,
    analyseId: analyseId,
    istdEvent: istdEvent,
  }
}
export function receiveUnitaryAnalysesISTD(unitaryCompoundISTD, authenticationError) {
  const rebuild = unitaryCompoundISTD.map(function (unitAnalyse) {
    return { isIstd: true, ...rebuildUnitaryCompounds(unitAnalyse) }
  })
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_COMPOUND_ISTD,
    unitaryCompoundISTD: rebuild,
  }
}
export function fetchRecomputePeaks(batchId, moleculeEvent) {
  return async (dispatch) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ batch_id: batchId, analysis_id: null }),
    }
    return fetch(
      `${FLASK_URL()}/batch/${batchId}/molecule/${moleculeEvent}/recompute_peaks`,
      requestOptions,
    )
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveRecomputePeaks(json, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to recompute peak',
          <Fragment>
            <div>An error does not allow to recompute peak:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
  }
}
export function receiveRecomputePeaks(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_RECOMPUTE_PEAKS,
    unitaryAnalysesUpdated: json,
  }
}

let controllerFetchGlobalUASaved = null
export function fetchGlobalUA(batchId, mode, criteria, selectedChannel, candidatsList) {
  return async (dispatch) => {
    if (controllerFetchGlobalUASaved) {
      controllerFetchGlobalUASaved.abort()
    }
    const controller = new AbortController()
    controllerFetchGlobalUASaved = controller
    dispatch(requestGlobalUA(criteria))

    let criteriaToSend = criteria
    if (criteriaToSend.indexOf('[CHANNEL]') !== -1 && selectedChannel === 'main') {
      criteriaToSend = criteriaToSend
        .split('[&]')
        .map(function (v) {
          if (v.indexOf('[CHANNEL]') !== -1) {
            return ['1', '2', '3', '4', '5', '6', '7', '8']
              .map((channel) => {
                if (v.indexOf('[CANDIDATES]') !== -1) {
                  return ['mixed_prominence_rt_strategy', 'prominence', 'rt_nearest'].map(function (
                    candidat,
                  ) {
                    return v.replace('[CHANNEL]', channel).replace('[CANDIDATES]', candidat)
                  })
                } else {
                  return v.replace('[CHANNEL]', channel)
                }
              })
              .flat()
              .join('[&]')
          } else {
            return v
          }
        })
        .flat()
        .join('[&]')
    } else {
      criteriaToSend = criteriaToSend
        .split('[&]')
        .map(function (v) {
          if (v.indexOf('[CHANNEL]') !== -1) {
            if (v.indexOf('[CANDIDATES]') !== -1) {
              return ['mixed_prominence_rt_strategy', 'prominence', 'rt_nearest']
                .map(function (candidat) {
                  return v.replace('[CHANNEL]', selectedChannel).replace('[CANDIDATES]', candidat)
                })
                .flat()
                .join('[&]')
            } else {
              return v.replace('[CHANNEL]', selectedChannel)
            }
          } else {
            return v
          }
        })
        .flat()
        .join('[&]')
    }

    let sort = 'sort=content.order&dir=ASC'

    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))

    const includes = [
      '_id',
      'name',
      'content.batch.id',
      'content.analysis.id',
      'content.molecule.event',
      'content.analysis.name',
      criteriaToSend.split('[&]'),
    ]
      .flat()
      .map((inc) => 'include=' + inc)
      .join('&')

    const test = [
      '_id',
      'location',
      'name',
      'creationDate',
      'modificationDate',
      'description',
      'tags',
      'users',
      'teams',
      'projects',
      'content.level',
      'content.suffix',
      'content.version',
      'content.batch',
      'content.analysis',
      'content.score_by_policy',
      'content.score_details',
      'content.selected_policy',
      'content.ion_ratio',
      'content.global_score',
      'content.validation',
      'content.parameters',
      'content.rt',
      'content.istd',
      'content.requested',
      'content.gt_concentration',
      'content.molecule',
      'content.order',
      'content.chromatography_type',
      'content.sample_type',
      'content.analysis_type',
      'content.flags',
      'content.best_classification',
      'content.initial_classification',
      'content.classification',
      'content.time_step',
      'content.main_channel',
      'content.event',
      'homogeneousDate',
      'canEdit',
      'canRemove',
      ['1', '2', '3', '4']
        .map((c) => [
          'content.channels.' + c + '.ampl_min',
          'content.channels.' + c + '.ampl_max',
          'content.channels.' + c + '.segment',
          'content.channels.' + c + '.ce',
          'content.channels.' + c + '.q1',
          'content.channels.' + c + '.q3',
          'content.channels.' + c + '.peak',
          'content.channels.' + c + '.candidates',
          'content.channels.' + c + '.classification',
          'content.channels.' + c + '.initial_classification',
          'content.channels.' + c + '.flags',
          'content.channels.' + c + '.concentration',
          'content.channels.' + c + '.concentration_reversed',
          'content.channels.' + c + '.dev_accuracy',
          'content.channels.' + c + '.rt_calib',
          'content.channels.' + c + '.rt_corrected',
          'content.channels.' + c + '.rrt',
          'content.channels.' + c + '.rrt_mean',
          'content.channels.' + c + '.istd_area',
          'content.channels.' + c + '.dev_rt',
          'content.channels.' + c + '.dev_rrt_calibration',
          'content.channels.' + c + '.dev_rrt_mean',
          'content.channels.' + c + '.area_ratio',
        ])
        .flat(),
    ]
      .flat()
      .map((inc) => 'include=' + inc)
      .join('&')

    return fetch(`${API_BO_URL()}.unitary_analysis/page/?filter=${p}&${includes}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveGlobalUA(json.result))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
      .finally(() => {
        if (controllerFetchGlobalUASaved === controller) {
          controllerFetchGlobalUASaved = null
        }
        controller.abort()
      })
  }
}
export function requestGlobalUA(criteria) {
  return {
    type: REQUEST_GLOBAL_UA,
    criteria: criteria,
  }
}
export function receiveGlobalUA(json) {
  return {
    type: RECEIVE_GLOBAL_UA,
    globalUnitaryAnalysesUpdated: json,
  }
}

let controllerFetchGlobalUASelectedSaved = null
export function fetchGlobalUASelected(uaId) {
  return async (dispatch) => {
    if (controllerFetchGlobalUASelectedSaved) {
      controllerFetchGlobalUASelectedSaved.abort()
    }
    const controller = new AbortController()
    controllerFetchGlobalUASelectedSaved = controller
    dispatch(requestGlobalUASelected())

    let sort = 'sort=content.order&dir=ASC'
    const url = `${API_BO_URL()}.unitary_analysis/${uaId}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then(async (json) => {
          return [json]
        })
    }
    return promise
      .then(async (result) => {
        return dispatch(receiveGlobalUASelected(result[0]))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
      .finally(() => {
        if (controllerFetchGlobalUASelectedSaved === controller) {
          controllerFetchGlobalUASelectedSaved = null
        }
        controller.abort()
      })
  }
}
export function requestGlobalUASelected() {
  return {
    type: REQUEST_GLOBAL_UA_SELECTED,
  }
}
export function receiveGlobalUASelected(json) {
  return {
    type: RECEIVE_GLOBAL_UA_SELECTED,
    globalUnitaryAnalysesSelected: json,
  }
}

// No cache mechanism is used for this request cause we need an ampty object
export function fetchUnitaryAnalysesShellForFilter(analyseId) {
  return async (dispatch) => {
    dispatch(requestUnitaryAnalysesShellForFilter(analyseId))

    let filter = {
      property: 'content.analysis.id',
      value: analyseId,
      filterType: 'string',
      operator: 'eq',
    }
    let p = encodeURIComponent(JSON.stringify(filter))

    const includes = [
      'name',
      '_id',
      'content.istd ',
      // 'content.analysis.name',
    ]
      .map((inc) => 'include=' + inc)
      .join('&')
    const url = `${API_BO_URL()}.unitary_analysis/page/?filter=%5B${p}%5D&${includes}`
    let promise = fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then(async (json) => {
        return json.result
      })
    return promise
      .then(async (result) => {
        return dispatch(receiveUnitaryAnalysesShellForFilter(result))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analyses',
          <Fragment>
            <div>An error does not allow to get unitary analyses:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestUnitaryAnalysesShellForFilter(analyseId) {
  return {
    type: REQUEST_UA_SHELL_FILTER,
  }
}
export function receiveUnitaryAnalysesShellForFilter(results) {
  return {
    type: RECEIVE_UA_SHELL_FILTER,
    unitaryAnalysesShellForFilter: results,
  }
}
