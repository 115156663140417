import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import background from '../resources/images/404.gif'
import Button from '@material-ui/core/Button'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { t } from 'i18next'

const styles = () => ({
  backgroundImg: {
    position: 'fixed',
    marginLeft: 'calc(50% - 400px)',
  },
  container: {
    backgroundColor: 'white',
    height: '100%',
  },
  notFound: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 135,
  },
  notFound1: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 40,
    marginTop: 270,
  },
  notFound2: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 18,
    marginBottom: 100,
  },
  buttonHome: {
    marginLeft: 'calc(50% - 92px)',
  },
})

export default withRouter(
  connect()(
    withStyles(styles)(
      class NoMatch extends React.Component {
        constructor(props, context) {
          super(props, context)
          this.state = {}
        }
        componentWillMount() {}
        componentDidMount() {}

        render() {
          const { classes } = this.props
          return (
            <div className={classes.container}>
              <img src={background} alt="Background" className={classes.backgroundImg} />
              <div className={classes.notFound}>404</div>
              <div className={classes.notFound1}>{t('view.noMatch.msg1')}</div>
              <div className={classes.notFound2}>{t('view.noMatch.msg2')}!</div>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.buttonHome}
                startIcon={<DirectionsRunIcon />}
                endIcon={<ArrowForwardIcon />}
                onClick={() =>
                  this.props.history.push('/?' + localStorage.getItem('SERVICE_dataBase_name'))
                }
              >
                {t('view.noMatch.goHome')}
              </Button>
            </div>
          )
        }
      },
    ),
  ),
)
