import React, { Fragment, useEffect, useMemo } from 'react'
import { CookieIcon, ToriiIcon, TransitionBottom } from '../common/Style'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoginView from '../common/LoginView'
import { Dialog, DialogContent, Tooltip } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import {
  API_URL,
  CONFIGURATION_PROFILE,
  DATABASE,
  FLASK_BASE_URL,
  FLASK_URL,
  GET_TORII_BASE_URL,
  PUBLIC_URL,
  SERVICE,
} from '../../utils/config'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import Loading from '../Loading'
import ErrorIcon from '@material-ui/icons/Error'
import { fetchConfigurationFeatures, fetchConfigurations } from '../../redux/configuration/actions'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { Stack } from '@mui/material'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ReplayIcon from '@mui/icons-material/Replay'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import TuneIcon from '@mui/icons-material/Tune'
import Divider from '@material-ui/core/Divider'
import { openInNewTab } from '../common/Utils'

const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    // height: isMobile ? '100%' : '70%',
    // width: 396,
    // backgroundColor: 'transparent',
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  warningIcon: {
    color: theme.palette.common.warning,
    verticalAlign: 'sub',
    marginRight: 20,
  },
  errorIcon: {
    color: theme.palette.common.error,
    verticalAlign: 'sub',
    marginRight: 20,
  },
  formControlProfile: {
    marginTop: 10,
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
  online: {
    color: theme.palette.common.success,
  },
  offline: {
    color: theme.palette.common.error,
  },
  undefined: {
    color: '#d4d4d4',
  },
  component_canvas: {
    border: '1px solid #d4d4d4',
    borderRadius: 4,
    padding: 20,
    background: '#f9f9f9',
  },
}))
const ConfigurationDialog = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = props.history

  const [configurationDatabase, setConfigurationDatabase] = React.useState(DATABASE())

  const openDatabase = useSelector((state) => state.settings.configurationDatabase)
  const databaseLoaded = useSelector((state) => state.settings.dataBaseLoaded)
  const openDialog = useMemo(() => {
    return openDatabase.length > 0
  }, [openDatabase])
  const serverError = useMemo(() => {
    return openDatabase.length > 0 && openDatabase[0] === 'ERROR_DB_UNAVAILABLE'
  }, [openDatabase])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleCleanUserPrefs = () => {
    Object.keys(localStorage).forEach(function (key) {
      if (
        key !== 'SESSION_user_name' &&
        key !== 'SESSION_user_json' &&
        key.indexOf('__config.json__') === -1
      ) {
        localStorage.removeItem(key)
      }
    })
    window.location.reload()
  }

  const handleDeleteCookie = () => {
    const cookieName = 'ToriiSessionId'
    document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    window.location.reload()
  }

  const openToriiInTab = () => {
    const url = `${GET_TORII_BASE_URL()}`
    openInNewTab(url)
  }

  const handleConfigurationDatabaseChanged = (event) => {
    const value = event.target.value
    setConfigurationDatabase(value)
    dispatch(fetchConfigurations(value))
  }

  function handleClose(save) {
    const isSomethingChanged =
      localStorage.getItem('SERVICE_dataBase_name') !== configurationDatabase
    if (save && isSomethingChanged) {
      localStorage.setItem('SERVICE_dataBase_name', configurationDatabase)
      displayPopupMessage(
        dispatch,
        'success',
        t('view.dialogs.config.close_msg.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.dialogs.config.close_msg.msg_database">
              The database is now:
              {{
                val: configurationDatabase,
              }}
            </Trans>
          </div>
        </Fragment>,
      )
      dispatch(fetchChangeSettings('configurationDatabase', [])).then(() => {
        history.push('/?' + configurationDatabase)
        // Refresh the page
        window.location.reload()
      })
    }
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose(false)}
      TransitionComponent={TransitionBottom}
      aria-labelledby="dialog-title-user"
      classes={{
        paper: classes.customRootDialog,
      }}
    >
      {databaseLoaded && (
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + (serverError ? '/images/server.jpg' : '/images/profile.jpg')}
              title="Formulas"
              style={{ opacity: serverError ? 0.3 : 0.8 }}
            />
            {serverError ? (
              <span className={classes.titleCard}>{t('view.dialogs.config.server.title')}</span>
            ) : (
              <span className={classes.titleCard}>{t('view.common.databaseCmp.database')}</span>
            )}
          </Box>
        </DialogTitle>
      )}
      {!databaseLoaded ? (
        <DialogContent style={{ marginTop: 14, overflow: 'hidden', fontSize: 16 }}>
          <Loading
            mode="mini3dots"
            style={{ height: 108, top: -19, left: 'calc(50% - 150px)', transform: 'none' }}
          />
        </DialogContent>
      ) : serverError ? (
        <DialogContent style={{ marginTop: 14, overflow: 'hidden', fontSize: 16 }}>
          <div className={classes.component_canvas}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.errorIcon}
            >
              <ErrorIcon className={classes.errorIcon} />
              <div style={{ width: 'calc(100% - 46px)' }}>
                <div>{t('view.dialogs.config.server.msg1')}</div>
                <div style={{ marginTop: 30, color: 'black' }}>
                  {t('view.dialogs.config.server.msg2')}
                </div>
                <div style={{ marginTop: 10, color: 'black' }}>
                  {t('view.dialogs.config.server.msg3')}
                </div>
              </div>
            </Grid>
          </div>
        </DialogContent>
      ) : (
        <DialogContent style={{ marginTop: 14, overflow: 'hidden', fontSize: 16 }}>
          {openDatabase.length > 0 && (
            <div className={classes.component_canvas}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.errorIcon}
              >
                <ErrorIcon className={classes.errorIcon} />
                <div style={{ width: 'calc(100% - 46px)' }}>
                  {t('view.dialogs.config.config_database.no_selection')}
                </div>
              </Grid>
              <Fragment>
                <div style={{ marginTop: 30 }}>
                  {t('view.dialogs.config.config_database.choose_database')}
                </div>
                <FormControl
                  style={{ background: 'white' }}
                  required
                  fullWidth
                  variant="outlined"
                  className={classes.formControlProfile}
                  error={openDatabase.indexOf(configurationDatabase) === -1}
                >
                  {/*<InputLabel>Configuration profile</InputLabel>*/}
                  <Select
                    variant="outlined"
                    onChange={handleConfigurationDatabaseChanged}
                    // label="Configuration profile"
                    value={configurationDatabase}
                  >
                    {openDatabase.map((db) => (
                      <MenuItem key={db} value={db}>
                        {db}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Fragment>
            </div>
          )}
        </DialogContent>
      )}
      {databaseLoaded && (
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button color="primary" onClick={handleClick}>
            {t('view.dialogs.config.helper.button')}
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={() => window.location.reload()}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <ReplayIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.config.helper.reload')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => dispatch(fetchChangeSettings('loginPopup', true))}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <PersonOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.config.helper.relogin')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={openToriiInTab}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <ToriiIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.config.helper.torii')}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCleanUserPrefs}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <TuneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.config.helper.pref')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDeleteCookie}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <CookieIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.config.helper.cookie')}</ListItemText>
            </MenuItem>
          </Menu>
          {serverError ? (
            <div>
              {/*<Button autoFocus onClick={() => window.location.reload()} color="primary">*/}
              {/*  {t('view.dialogs.config.helper.reload')}*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*  onClick={() => dispatch(fetchChangeSettings('loginPopup', true))}*/}
              {/*  color="primary"*/}
              {/*>*/}
              {/*  {t('view.dialogs.config.helper.relogin')}*/}
              {/*</Button>*/}
            </div>
          ) : (
            <Button
              autoFocus
              onClick={() => handleClose(true)}
              color="primary"
              disabled={
                configurationDatabase === '' ||
                configurationDatabase === null ||
                configurationDatabase === undefined ||
                openDatabase.indexOf(configurationDatabase) === -1
              }
            >
              {t('view.dialogs.config.save')}
            </Button>
          )}
          {/*<Button onClick={(event) => handleClose(false)} color="primary">*/}
          {/*  {t('view.dialogs.config.close')}*/}
          {/*</Button>*/}
        </DialogActions>
      )}
    </Dialog>
  )
})
export default ConfigurationDialog
