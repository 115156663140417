import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { alpha, withStyles } from '@material-ui/core/styles'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Loading from '../../Loading'
import Box from '@material-ui/core/Box'
import SplitterLayout from 'react-splitter-layout'
import clsx from 'clsx'
import {
  convertVolumeConcentrationValue,
  customFormatDecimals,
  displayHumanReadable,
  generateUUID,
  getClickAction,
  getComparator,
  stableSort,
} from '../Utils'
import { BootstrapTooltip } from '../Style'
import { connect, useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Favorite, FavoriteBorder, Visibility, VisibilityOff } from '@material-ui/icons'
import TableMultiGrid from '../Table_MultiGrid'
import ChromatoDetailScatter from './ChromatoDetailScatter'
import StarIcon from '@material-ui/icons/Star'
import Paper from '@material-ui/core/Paper'
import { fetchBatch } from '../../../redux/batches/actions'
import {
  fetchUnitaryAnalysesCompoundsForDetail,
  fetchUnitaryAnalysesISTD,
} from '../../../redux/unitaryAnalyses/actions'
import { fetchAnalyses } from '../../../redux/analyses/actions'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import ListItemText from '@material-ui/core/ListItemText'
import ChromatoScatter from '../chromato/ChromatoScatter'
import { fetchChangeSelectedChannelDetail } from '../../../redux/detail/actions'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import { HexColorPicker } from 'react-colorful'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { GET_CHROMATO_OPACITY, PUBLIC_URL } from '../../../utils/config'
import { displayPopupMessage } from '../Message'
import Badge from '@material-ui/core/Badge'
import Pagination from './Pagination'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { fetchGetTasks, fetchGetToolbarTasks } from '../../../redux/tasks/actions'
import OpacityIcon from '@mui/icons-material/Opacity'
import Slider from '@material-ui/core/Slider'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import DashboardActivitiesComponent from '../DashboardActivitiesComponent'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({
  chromatoGrid: {
    height: '100%',
    overflow: 'hidden',
    padding: 0,
    // '& .splitter-layout': {
    //   '& > .layout-pane.layout-pane-primary': {
    //     overflow: 'auto', // Force to display the collapse button
    //   },
    // },
  },
  deeperSplitter: {
    position: 'relative',
    '& > .layout-pane': {
      padding: '1px 5px 0px 5px',
      overflow: 'hidden',
    },
    // '& > .layout-pane:first-child': {
    //   padding: '13px 5px 10px 2px',
    // },
  },
  infos: {
    height: '100%',
    paddingLeft: 20,
    paddingTop: 0,
    overflowY: 'auto',
  },

  flagInfo: {
    color: theme.palette.common.infoBlue,
  },
  flagWarning: {
    color: theme.palette.common.warning,
  },
  flagError: {
    color: theme.palette.common.error,
  },
  container: {
    maxHeight: 440,
  },

  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  tableContainer: {
    flex: '1 1 auto',
    width: 'calc(100% - 10px)',
    height: 40,
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: alpha(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: '#000000c4',
  },
  selectChips: {
    '&::before': {
      borderBottom: 0,
    },
    '& .MuiSelect-root': {
      minHeight: 36,
    },
    '& .MuiSelect-root:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIntegration: {
    margin: '7px 0px 0px 6px',
    '& select': {
      padding: 5,
      paddingRight: 20,
    },
  },
  colorPicker: {
    width: '100%',
    padding: '18.5px 14px',
    '& div': {
      cursor: 'pointer',
    },
  },
  colorOld: {
    width: '100%',
    padding: '18.5px 14px',
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    // marginTop: 30,
    borderRadius: 4,
  },
  colorPickerCntResult: {
    border: '1px solid #c4c4c4',
    width: 'calc(100% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
    height: 94,
    marginBottom: 15,
  },
  colorPickerTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  titleDiv: {
    background: 'url(' + PUBLIC_URL + '/images/colors_background.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 35px 0px rgb(0 0 0)',
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    padding: 15,
    textShadow: '1px 1px 3px white',
  },
  colorCtn: {
    height: '100%',
    borderRadius: 8,
  },
  istdBadge: {
    '& .MuiBadge-badge': {
      fontSize: 8,
      padding: 4,
      height: 12,
    },
  },
  sliderContainer: {
    width: 30,
    height: '100%',
    position: 'absolute',
    // paddingTop: '30px !important',
    // paddingBottom: '30px !important',
    // paddingLeft: '25px !important',
  },
  lineLabel: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  collapsedButton: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    right: -18,
    background: '#fbfbfb',
  },
}))

const getFilterName = (analyseType, isIstd) => {
  // if (isIstd) {
  //   return 'istd'
  // } else {
  switch (analyseType) {
    case 'blank':
      return 'blc'
    case 'matrix_bio':
    case 'matrix_bio_doped':
    case 'std_end':
      return 'qa'
    case 'calibration':
      return 'std'
    default:
      return 'sample'
  }
  // }
}

const ChromatoDetailWithRouter = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const chromatoDetailRef = React.createRef()
  const { collection, item, getSelectedChannel, canEdit, externalWindowMode } = props

  const stableKey_1 = useRef(generateUUID())
  const stableKey_2 = useRef(generateUUID())
  const stableKey_3 = useRef(generateUUID())
  const stableKey_4 = useRef(generateUUID())
  const stableKey_5 = useRef(generateUUID())
  const stableKey_6 = useRef(generateUUID())

  const volumeConcentrationUnit = localStorage.getItem('SETTINGS_volume_concentration_unit')
    ? localStorage.getItem('SETTINGS_volume_concentration_unit')
    : 'µg/l'

  const unitaryAnalyse = React.useMemo(() => {
    return { ...item, filter: getFilterName(item.analyseType) }
  }, [item])

  const [synchIstd, setSynchIstd] = React.useState(
    localStorage.getItem('LAYOUT_chromatoDetail_syncISTD')
      ? localStorage.getItem('LAYOUT_chromatoDetail_syncISTD') === 'true'
      : true,
  )

  const [collapsed, setCollapsed] = React.useState(true)
  const [graphPosition, setGraphPosition] = React.useState({
    'x-axis-1': {},
    'y-axis-1': {},
  })
  const [openChangeColor, setOpenChangeColor] = React.useState(false)
  const [anchorChangeColor, setAnchorChangeColor] = React.useState(null)
  const [itemChangeColor, setItemChangeColor] = React.useState(null)
  const [colorTmp, setColorTmp] = React.useState(null)
  const [visibilities_istd, setVisibilities_istd] = React.useState(
    unitaryAnalyse.type !== 'ISTD' && localStorage.getItem('CHROMATO_visibility_istd')
      ? localStorage.getItem('CHROMATO_visibility_istd') === 'true'
      : false,
  )
  const [visibilities_peak, setVisibilities_peak] = React.useState(
    localStorage.getItem('CHROMATO_visibility_peak')
      ? localStorage.getItem('CHROMATO_visibility_peak') === 'true'
      : true,
  )
  const [visibilities_rt, setVisibilities_rt] = React.useState(
    localStorage.getItem('CHROMATO_visibility_rt')
      ? localStorage.getItem('CHROMATO_visibility_rt') === 'true'
      : true,
  )
  const [visibilities_std, setVisibilities_std] = React.useState(
    getFilterName(unitaryAnalyse.analyseType) === 'std'
      ? true
      : localStorage.getItem('CHROMATO_visibility_std')
      ? localStorage.getItem('CHROMATO_visibility_std') === 'true'
      : false,
  )
  const [visibilities_qa, setVisibilities_qa] = React.useState(
    getFilterName(unitaryAnalyse.analyseType) === 'qa'
      ? true
      : localStorage.getItem('CHROMATO_visibility_qa')
      ? localStorage.getItem('CHROMATO_visibility_qa') === 'true'
      : false,
  )
  const [visibilities_blc, setVisibilities_blc] = React.useState(
    getFilterName(unitaryAnalyse.analyseType) === 'blc'
      ? true
      : localStorage.getItem('CHROMATO_visibility_blc')
      ? localStorage.getItem('CHROMATO_visibility_blc') === 'true'
      : false,
  )
  const [visibilities_sample, setVisibilities_sample] = React.useState(true)
  const [visibilities_channels, setVisibilities_channels] = React.useState(function () {
    let channelsVisibilities = {}
    const savedChannels = JSON.parse(localStorage.getItem('CHROMATO_visibility_channel'))
    Object.keys(unitaryAnalyse.channels).forEach((channel) => {
      channelsVisibilities[channel] =
        savedChannels && typeof savedChannels[channel] === 'boolean'
          ? savedChannels[channel]
          : channel < 3
          ? true
          : false
    })
    return channelsVisibilities
  })
  const [visibilities_analyses, setVisibilities_analyses] = React.useState({})

  const [tableItemSelectedForColor, setTableItemSelectedForColor] = React.useState(null)
  const [sampleName, setSampleName] = React.useState([])
  const [sortDirection, setSortDirection] = React.useState('asc')
  const [sortOrder, setSortOrder] = React.useState('analyse')
  const [colors, setColors] = React.useState(
    localStorage.getItem('CHROMATO_colors_list')
      ? localStorage.getItem('CHROMATO_colors_list').split(',')
      : [
          '0/#240073',
          '1/#8fd100',
          '2/#00c5ae',
          '3/#bc00c2',
          '4/#0072cd',
          '5/#ffc99b',
          '6/#005831',
          '7/#9c84ff',
          '8/#ff86c2',
          '9/#4d7500',
          '10/#ffc353',
          '11/#ff8af2',
          '12/#4d0023',
          '13/#01aba8',
          '14/#018bc2',
          '15/#c14fff',
          '16/#532800',
          '17/#00320d',
          '18/#2b001e',
          '19/#ff43e3',
          '20/#fff8ae',
          '21/#680040',
          '22/#9e0031',
          '23/#a66300',
          '24/#ff8431',
          '25/#508fff',
          '26/#d90004',
          '27/#270048',
          '28/#a8ad00',
          '29/#ecffd4',
          '30/#c2ffa8',
          '31/#003887',
          '32/#370b00',
          '33/#00657e',
          '34/#a3ffdd',
          '35/#e0ffb2',
          '36/#90e3ff',
          '37/#001659',
          '38/#e499ff',
          '39/#ff5b9e',
          '40/#7e0076',
          '41/#700014',
          '42/#53005c',
          '43/#645000',
          '44/#007c53',
          '45/#004d6e',
          '46/#ffa19a',
          '47/#ff846e',
          '48/#e1a300',
          '49/#61ae00',
          '50/#002a55',
          '51/#ff6e74',
          '52/#00b069',
          '53/#928b00',
          '54/#e3fffb',
          '55/#12000a',
          '56/#1c1d00',
          '57/#ffa3b9',
          '58/#11002e',
          '59/#008897',
          '60/#74ffe9',
          '61/#7e2000',
          '62/#ff592d',
          '63/#6affa8',
          '64/#ff9c4f',
          '65/#8c5900',
          '66/#be0026',
          '67/#003195',
          '68/#00d274',
          '69/#cdedff',
          '70/#ffab72',
          '71/#9f8000',
          '72/#a80055',
          '73/#9dd2ff',
          '74/#a70094',
          '75/#0152d5',
          '76/#fffe2e',
          '77/#c46900',
          '78/#00c741',
          '79/#eb3ef1',
          '80/#641fce',
          '81/#ecff95',
          '82/#ffe8fe',
          '83/#ff7b48',
          '84/#5755ff',
          '85/#fff549',
          '86/#ff18b2',
          '87/#00b3ff',
          '88/#d8b2ff',
          '89/#69fc46',
          '90/#ad6bff',
          '91/#eb0046',
          '92/#f7d6ff',
          '93/#00372c',
          '94/#ff444e',
          '95/#c20052',
          '96/#ff5968',
          '97/#ff3e7a',
          '98/#ffe49b',
          '99/#4e0093',
        ],
  )
  const [shouldWait, setShouldWait] = React.useState(false)
  const [isISTDEmpty, setIsISTDEmpty] = React.useState(false)

  // const openDetail = useSelector(function (state) {
  //   return state.detail.internalMode
  // })
  const batches = useSelector(function (state) {
    return state.batches.items
  })
  const compounds_origin = useSelector(function (state) {
    return state.unitaryAnalyses.unitaryAnalysesCompoundForDetail.concat(
      state.unitaryAnalyses.unitaryCompoundISTD,
    )
  })
  const compounds_origin_str_id = useSelector(function (state) {
    return JSON.stringify(
      state.unitaryAnalyses.unitaryAnalysesCompoundForDetail
        .concat(state.unitaryAnalyses.unitaryCompoundISTD)
        .map((co) => co._id),
    )
  })
  const isFetchingCompounds = useSelector(function (state) {
    return state.unitaryAnalyses.isFetchingUnitaryAnalysesCompound
  })
  const isFetchingISTD = useSelector(function (state) {
    return state.unitaryAnalyses.isFetchingUnitaryCompoundISTD
  })
  const compoundISTD = useSelector(function (state) {
    return state.unitaryAnalyses.unitaryCompoundISTD
  })

  // if (localStorage.getItem('CHROMATO_visibility_mainChannel')) {
  //   props.item.selectedChannel = parseInt(localStorage.getItem('CHROMATO_visibility_mainChannel'))
  // }

  useEffect(
    function () {
      //Try to reduce the size of data inside request
      dispatch(fetchUnitaryAnalysesCompoundsForDetail(unitaryAnalyse.batchId, unitaryAnalyse.event))
      const batch = batches.filter((batch) => batch._id === unitaryAnalyse.batchId)[0]
      if (
        batch &&
        batch.content.molecules[unitaryAnalyse.istdEvent] &&
        batch.content.molecules[unitaryAnalyse.istdEvent].istd &&
        batch.content.internal_standards &&
        batch.content.internal_standards[batch.content.molecules[unitaryAnalyse.istdEvent].istd]
      ) {
        dispatch(
          fetchUnitaryAnalysesISTD(
            unitaryAnalyse.batchId,
            unitaryAnalyse.analyseId,
            batch.content.internal_standards[batch.content.molecules[unitaryAnalyse.istdEvent].istd]
              .event,
          ),
        )
      } else {
        // In case of ISTD is empty, we haven't the callback of fetchUnitaryAnalysesISTD that initiate
        // the isFetchingISTD value to true that trigger the useEffect to set to true then false the shouldWait
        // variable
        setIsISTDEmpty(true)
      }
    },
    [unitaryAnalyse._id],
  )

  // useEffect(
  //   function () {
  //     let analysesVisibilities = {}
  //     const savedAnalyses = JSON.parse(localStorage.getItem('CHROMATO_visibility_analyses'))
  //     compounds.forEach(function (compound) {
  //       // const initVisible = compound._id === props.item._id
  //       const initVisible = compound.analyse === props.item.analyse
  //       Object.keys(compound.channels).forEach((channel) => {
  //         // analysesVisibilities[compound._id + channel] = initVisible
  //         analysesVisibilities[compound.analyse + channel] = initVisible
  //         // analysesVisibilities[compound.analyse + channel] =
  //         //   savedAnalyses && typeof savedAnalyses[compound.analyse + channel] === 'boolean'
  //         //     ? savedAnalyses[compound.analyse + channel]
  //         //     : initVisible
  //       })
  //     })
  //     setVisibilities({ ...visibilities, analyses: analysesVisibilities })
  //   },
  //   [compounds],
  // )

  useEffect(
    function () {
      let channelsVisibilities = {}
      const savedChannels = JSON.parse(localStorage.getItem('CHROMATO_visibility_channel'))
      Object.keys(unitaryAnalyse.channels).forEach((channel) => {
        channelsVisibilities[channel] =
          savedChannels && typeof savedChannels[channel] === 'boolean'
            ? savedChannels[channel]
            : channel < 3
            ? true
            : false
      })
      setVisibilities_channels(channelsVisibilities)

      switch (getFilterName(unitaryAnalyse.analyseType)) {
        case 'blc':
          setVisibilities_blc(true)
          break
        case 'qa':
          setVisibilities_qa(true)
          break
        case 'std':
          setVisibilities_std(true)
          break
        case 'sample':
        default:
          break
      }
    },
    [unitaryAnalyse._id],
  )

  useEffect(
    function () {
      let analysesVisibilities = {}
      compounds.forEach(function (compound) {
        const initVisible = compound.analyse === unitaryAnalyse.analyse
        Object.keys(compound.channels).forEach((channel) => {
          analysesVisibilities[compound.analyse + channel] = initVisible
        })
      })
      setVisibilities_analyses(analysesVisibilities)
    },
    [JSON.stringify(compounds_origin_str_id), unitaryAnalyse._id],
  )

  const memoizedVisibilities = React.useMemo(
    () => ({
      istd: visibilities_istd,
      peak: visibilities_peak,
      rt: visibilities_rt,
      std: visibilities_std,
      qa: visibilities_qa,
      blc: visibilities_blc,
      sample: visibilities_sample,
      channels: visibilities_channels,
      analyses: visibilities_analyses,
    }),
    [
      visibilities_istd,
      visibilities_peak,
      visibilities_rt,
      visibilities_std,
      visibilities_qa,
      visibilities_blc,
      visibilities_sample,
      visibilities_channels,
      visibilities_analyses,
    ],
  )

  // Set shouldWait to true at the detail opening
  // useEffect(
  //   function () {
  //     if (openDetail) {
  //       setShouldWait(true)
  //     }
  //   },
  //   [openDetail],
  // )
  //
  // // Set shouldWait to false at the end of the first compounds/istd loading
  // useEffect(
  //   function () {
  //     if ((!isFetchingCompounds && !isFetchingISTD && shouldWait) || isISTDEmpty) {
  //       setShouldWait(false)
  //     }
  //   },
  //   [isFetchingCompounds, isFetchingISTD, isISTDEmpty],
  // )

  const compounds = React.useMemo(() => {
    let colorIndexPointer = 0
    let compIstd = compounds_origin
      .filter((compound) => compound.isIstd)
      .sort(function (a, b) {
        if (a.analyse < b.analyse) return 1
        if (a.analyse > b.analyse) return -1
        return 0
      })
    compounds_origin
      .filter((compound) => !compound.isIstd)
      .sort(function (a, b) {
        if (a.analyse < b.analyse) return 1
        if (a.analyse > b.analyse) return -1
        return 0
      })
      .forEach((comp, index) => {
        let color = '#165c90'
        let colorIndex = null
        const main = comp._id === unitaryAnalyse._id
        if (!main) {
          const colorSelected = colors[colorIndexPointer % 100]
          colorIndexPointer = colorIndexPointer + 1
          const colorSplited = colorSelected.split('/')
          color = colorSplited[1]
          colorIndex = parseInt(colorSplited[0])
        }
        // return {
        //     ...compound,
        //     color: color,
        //     channelNumber: compound.mainChannel,
        //     visibility: true,
        //     filter: getFilterName(compound.analyseType),
        //     colorIndex: colorIndex,
        // }
        comp['color'] = color
        comp['colorIndex'] = colorIndex
        if (visibilities_istd && compIstd[index]) {
          compIstd[index]['color'] = color
          compIstd[index]['colorIndex'] = colorIndex
        }
      })
    return compounds_origin
  }, [JSON.stringify(compounds_origin), JSON.stringify(colors), unitaryAnalyse._id])
  // }, [compounds_origin_str_id, JSON.stringify(colors), item._id])

  const handleSampleChange = function (event) {
    const newSamples = {}
    event.target.value.forEach(function (sampleName) {
      // const sampleId = sampleIdName.split(':')[0]
      Object.keys(unitaryAnalyse.channels).forEach((channel) => {
        newSamples[sampleName + channel] = true
      })
    })
    setSampleName(event.target.value)
    setVisibilities_analyses({ ...visibilities_analyses, ...newSamples })
  }

  const handleSampleDelete = function (event) {
    setSampleName(sampleName.filter((e) => e !== event))
  }

  if (!unitaryAnalyse || !compounds || (collection === 'analyse' && isFetchingCompounds)) {
    if (collection === 'analyse') {
      setVisibilities_channels([])
      setVisibilities_analyses([])
    }
    return <Loading mode={'miniCircle'} />
  }

  if (collection === 'analyse') {
    const newUA = compounds.filter((compound) => compound.analyse === unitaryAnalyse.analyse)[0]
    if (newUA) {
      // unitaryAnalyse = newUA  //I DONT REMEBER WHY ????????????

      if (
        Object.keys(visibilities_channels).length === 0 &&
        Object.keys(visibilities_analyses).length === 0
      ) {
        // let channelsVisibilities = {}
        // Object.keys(unitaryAnalyse.channels).forEach(
        //   (channel) => (channelsVisibilities[channel] = channel < 3 ? true : false),
        // )
        let channelsVisibilities = []
        const savedChannels = JSON.parse(localStorage.getItem('CHROMATO_visibility_channel'))
        Object.keys(unitaryAnalyse.channels).forEach((channel) => {
          channelsVisibilities[channel] =
            savedChannels && typeof savedChannels[channel] === 'boolean'
              ? savedChannels[channel]
              : channel < 3
              ? true
              : false
        })
        // let analysesVisibilities = {}
        // compounds.forEach(function (compound) {
        //   const initVisible = compound._id === unitaryAnalyse._id
        //   Object.keys(compound.channels).forEach((channel) => {
        //     analysesVisibilities[compound._id + channel] = initVisible
        //   })
        // })
        let analysesVisibilities = []
        const savedAnalyses = JSON.parse(localStorage.getItem('CHROMATO_visibility_analyses'))
        compounds.forEach(function (compound) {
          const initVisible = compound.analyse === unitaryAnalyse.analyse
          Object.keys(compound.channels).forEach((channel) => {
            analysesVisibilities[compound.analyse + channel] = initVisible
            // analysesVisibilities[compound.analyse + channel] =
            //   savedAnalyses &&
            //   typeof savedAnalyses[compound.analyse + channel] === 'boolean'
            //     ? savedAnalyses[compound.analyse + channel]
            //     : initVisible
          })
        })
        setVisibilities_channels(channelsVisibilities)
        setVisibilities_analyses(analysesVisibilities)
      }
    }
  }

  const setGraphPositionFn = useCallback((newPos) => {
    setGraphPosition(newPos)
  }, [])

  const setSynchIstdFn = useCallback((sync) => {
    setSynchIstd(sync)
    localStorage.setItem('LAYOUT_chromatoDetail_syncISTD', sync)
  }, [])

  const onChromatoDetailSizeSave = function () {
    localStorage.setItem(
      'LAYOUT_chromatoDetail_secondaryPaneSize',
      chromatoDetailRef.current.state.secondaryPaneSize,
    )
  }.bind(this)

  const handleChangeSelectedChannel = (event) => {
    dispatch(fetchChangeSelectedChannelDetail(parseInt(event.target.value)))
    localStorage.setItem('CHROMATO_visibility_mainChannel', event.target.value)
  }

  let analysesData = compounds
    .filter(
      (analyse) =>
        analyse._id === unitaryAnalyse._id ||
        analyse.analyse === unitaryAnalyse.analyse ||
        analyse.analyseType === 'blank' ||
        analyse.analyseType === 'matrix_bio' ||
        analyse.analyseType === 'matrix_bio_doped' ||
        analyse.analyseType === 'calibration' ||
        analyse.analyseType === 'std_end',
    )
    .map(function (compound) {
      // let color = '#165c90'
      // let colorIndex = null
      // const main = compound._id === unitaryAnalyse._id
      // if (!main) {
      //   const colorSelected = colors[colorIndexPointer]
      //   colorIndexPointer = colorIndexPointer + 1
      //   const colorSplited = colorSelected.split('/')
      //   color = colorSplited[1]
      //   colorIndex = parseInt(colorSplited[0])
      // }
      return {
        ...compound,
        // color: color,
        channelNumber: compound.mainChannel,
        visibility: true,
        filter: getFilterName(compound.analyseType),
        // colorIndex: colorIndex,
      }
    })
  let sampleList = []
  let sampleData = []
  compounds.forEach(function (analyse) {
    if (
      analyse._id !== unitaryAnalyse._id &&
      (analyse.analyseType === 'sample' || analyse.analyseType === 'other')
    )
      sampleList.push({ analyse: analyse.analyse, _id: analyse._id })
    // const isASampleAdded = sampleName.indexOf(analyse._id + ':' + analyse.analyse) > -1
    const isASampleAdded = sampleName.indexOf(analyse.analyse) > -1
    if (
      analyse._id !== unitaryAnalyse._id &&
      (analyse.analyseType === 'sample' || analyse.analyseType === 'other') &&
      isASampleAdded
    )
      sampleData.push(analyse)
  })
  sampleData = sampleData.map(function (compound) {
    // const color = colors.splice(0, 1)
    return {
      ...compound,
      // color: compound._id === unitaryAnalyse._id ? '#165c90' : color[0],
      channelNumber: compound.mainChannel,
      visibility: true,
      filter: getFilterName(compound.analyseType),
    }
  })
  unitaryAnalyse['filter'] = getFilterName(unitaryAnalyse.analyseType)
  let tableData = []
  analysesData = analysesData.concat(sampleData)
  analysesData
    .filter((analyses) => !analyses.isIstd)
    .forEach(function (analyses) {
      if (visibilities_channels[analyses.channelNumber] && memoizedVisibilities[analyses.filter]) {
        // tableData.push({ ...analyses, _id: analyses._id + analyses.channelNumber })
        const peak =
          analyses.channels[analyses.channelNumber]?.candidates?.[analyses?.selected_policy]?.peak
        tableData.push({
          // visibility: false,
          visibility: visibilities_analyses[analyses.analyse + analyses.channelNumber],
          color: analyses.color,
          colorIndex: analyses.colorIndex,
          analyse: analyses.analyse,
          concentration: analyses.channels[analyses.channelNumber].concentration,
          area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
          channelNumber: analyses.channelNumber,
          // _id: analyses._id + analyses.channelNumber,
          _id: analyses.analyse + analyses.channelNumber,
          // istd: analyses.filter === 'istd',
          channels_data: [
            {
              q1:
                analyses.channels_data && analyses.channels_data[0] && analyses.channels_data[0].q1
                  ? analyses.channels_data[0].q1
                  : null,
            },
          ],
        })
      }
      analyses.channels_data.forEach(function (channel) {
        if (visibilities_channels[channel.channelNumber] && memoizedVisibilities[analyses.filter]) {
          const peak =
            analyses.channels[channel.channelNumber]?.candidates?.[
              analyses.channels[channel.channelNumber]?.selected_policy
            ]?.peak
          tableData.push({
            // visibility: false,
            visibility: visibilities_analyses[analyses.analyse + channel.channelNumber],
            color: analyses.color,
            colorIndex: analyses.colorIndex,
            analyse: analyses.analyse,
            concentration: analyses.channels[channel.channelNumber].concentration,
            area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
            channelNumber: channel.channelNumber,
            // _id: analyses._id + channel.channelNumber,
            _id: analyses.analyse + channel.channelNumber,
            // istd: analyses.filter === 'istd',
            channels_data: [
              {
                q1:
                  analyses.channels_data &&
                  analyses.channels_data[0] &&
                  analyses.channels_data[0].q1
                    ? analyses.channels_data[0].q1
                    : null,
              },
            ],
          })
        }
      })
    })

  let unitaryAnalyseISTD = React.useMemo(() => {
    return analysesData.filter(
      (analyses) => analyses.analyse === unitaryAnalyse.analyse && analyses.isIstd,
    )[0]
  }, [JSON.stringify(analysesData)])

  const memoizedCurrentPeaks = useMemo(
    () =>
      unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel
        ? unitaryAnalyse.main_peak_base &&
          unitaryAnalyse.main_peak_base.left &&
          unitaryAnalyse.main_peak_base.right
          ? [unitaryAnalyse.main_peak_base.left, unitaryAnalyse.main_peak_base.right]
          : []
        : unitaryAnalyse.channels_data.filter(
            (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
          ).length !== 0 &&
          unitaryAnalyse.channels_data.filter(
            (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
          )[0].peak &&
          unitaryAnalyse.channels_data.filter(
            (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
          )[0].peak.base
        ? [
            unitaryAnalyse.channels_data.filter(
              (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
            )[0].peak.base.left,
            unitaryAnalyse.channels_data.filter(
              (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
            )[0].peak.base.right,
          ]
        : [],
    [unitaryAnalyse],
  )
  const memoizedData = useMemo(
    () => analysesData.filter((analyses) => !analyses.isIstd),
    [JSON.stringify(analysesData)],
  )

  const memoizedIstdCurrentPeaks = useMemo(
    () =>
      unitaryAnalyseISTD !== undefined
        ? unitaryAnalyseISTD.mainChannel === unitaryAnalyseISTD.selectedChannel
          ? unitaryAnalyseISTD.main_peak_base &&
            unitaryAnalyseISTD.main_peak_base.left &&
            unitaryAnalyseISTD.main_peak_base.right
            ? [unitaryAnalyseISTD.main_peak_base.left, unitaryAnalyseISTD.main_peak_base.right]
            : []
          : unitaryAnalyseISTD.channels_data.filter(
              (ch) => ch.channelNumber === unitaryAnalyseISTD.selectedChannel,
            ).length !== 0 &&
            unitaryAnalyseISTD.channels_data.filter(
              (ch) => ch.channelNumber === unitaryAnalyseISTD.selectedChannel,
            )[0].peak &&
            unitaryAnalyseISTD.channels_data.filter(
              (ch) => ch.channelNumber === unitaryAnalyseISTD.selectedChannel,
            )[0].peak.base
          ? [
              unitaryAnalyseISTD.channels_data.filter(
                (ch) => ch.channelNumber === unitaryAnalyseISTD.selectedChannel,
              )[0].peak.base.left,
              unitaryAnalyseISTD.channels_data.filter(
                (ch) => ch.channelNumber === unitaryAnalyseISTD.selectedChannel,
              )[0].peak.base.right,
            ]
          : []
        : [],
    [unitaryAnalyseISTD],
  )
  const memoizedIstdData = useMemo(
    () => analysesData.filter((analyses) => analyses.isIstd),
    [JSON.stringify(analysesData)],
  )

  const getColorBorderMode = (channel) => {
    switch (channel) {
      case 1:
        return 'solid'
      case 2:
        return 'dashed'
      case 3:
        return 'dotted'
      default:
        return 'dotted'
    }
  }

  const onDisplayCurve = (itemId) => {
    // Do not re render by state all the chromato if it doesn't need
    if (!(!visibilities_analyses[itemId] && tableItemSelectedForColor === null)) {
      setTableItemSelectedForColor(visibilities_analyses[itemId] ? itemId : null)
    }
  }

  const onOpenChangeColor = (event, item) => {
    const index = item.colorIndex
    if (index) {
      setOpenChangeColor(true)
      setAnchorChangeColor(event.currentTarget)
      setItemChangeColor(item)
    } else {
      console.log("You can't change color for the main analyses")
      displayPopupMessage(
        dispatch,
        'info',
        t('view.common.chromatoDetail.chromatoDetail.color.title'),
        <Fragment>
          <div>{t('view.common.chromatoDetail.chromatoDetail.color.msg1')}</div>
          <div>{t('view.common.chromatoDetail.chromatoDetail.color.msg2')}</div>
        </Fragment>,
      )
    }
  }

  const handleCloseChangeColor = (save) => {
    if (save) {
      const index = itemChangeColor.colorIndex
      colors[index] = index + '/' + colorTmp
      setColors(colors)
      localStorage.setItem('CHROMATO_colors_list', colors)
    }
    setOpenChangeColor(false)
    setAnchorChangeColor(null)
    setItemChangeColor(null)
    setColorTmp(null)
  }

  const handleCollapsButton = () => {
    setCollapsed((prevState) => !prevState)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  function getStyles(name, personName) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 400 : 600,
    }
  }

  const setNewColorTmp = (col) => {
    setColorTmp(col)
  }

  // let unitaryAnalyseISTD = analysesData.filter(
  //   (analyses) => analyses.analyse === unitaryAnalyse.analyse && analyses.isIstd,
  // )[0]

  const [chromatoOpacity, setChromatoOpacity] = React.useState(GET_CHROMATO_OPACITY())

  function handleChromatoOpacityChange(event, newValue) {
    setChromatoOpacity(newValue)
    localStorage.setItem('CHROMATO_option_opacity', newValue)
  }

  function handleInputChromatoOpacityChange(event) {
    setChromatoOpacity(event.target.value === '' ? '' : Number(event.target.value))
  }

  function handleBlurChromatoOpacity() {
    if (chromatoOpacity < 0.01) {
      setChromatoOpacity(0.01)
    } else if (chromatoOpacity > 1) {
      setChromatoOpacity(1)
    }
  }

  return shouldWait ? (
    <Loading mode={'miniCircle'} />
  ) : (
    <>
      <Popover
        id={openChangeColor ? 'simple_popover' : null}
        open={openChangeColor}
        anchorEl={anchorChangeColor}
        onClose={() => handleCloseChangeColor()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.titleDiv}>
          <Typography className={classes.title}>
            <Trans i18nKey="view.common.chromatoDetail.chromatoDetail.set_color">
              Set the color for {{ val: itemChangeColor ? itemChangeColor.analyse : 'unknown' }}:
            </Trans>
          </Typography>
        </div>
        <Box p={2} style={{ width: 340 }}>
          <div className={classes.colorPickerCnt} style={{ marginLeft: 5 }}>
            <ErrorBoundaryGuard>
              <HexColorPicker
                className={classes.colorPicker}
                color={itemChangeColor ? itemChangeColor.color : '#000'}
                onChange={(col) => setNewColorTmp(col)}
              />
            </ErrorBoundaryGuard>
            <div className={classes.colorPickerTitle}>
              {t('view.common.chromatoDetail.chromatoDetail.change_color')}
            </div>
          </div>
          <div
            style={{
              width: 'calc(50% - 5px)',
              display: 'block',
              float: 'right',
              // marginTop: 30,
            }}
          >
            <div className={classes.colorPickerCntResult} style={{ marginLeft: 5 }}>
              <div className={classes.colorOld}>
                <div
                  className={classes.colorCtn}
                  style={{
                    backgroundColor: itemChangeColor ? itemChangeColor.color : '#000',
                  }}
                />
              </div>
              <div className={classes.colorPickerTitle}>
                {t('view.common.chromatoDetail.chromatoDetail.old_color')}
              </div>
            </div>
            <div className={classes.colorPickerCntResult} style={{ marginLeft: 5 }}>
              <div className={classes.colorOld}>
                <div
                  className={classes.colorCtn}
                  style={{
                    backgroundColor: colorTmp
                      ? colorTmp
                      : itemChangeColor
                      ? itemChangeColor.color
                      : '#000',
                  }}
                />
              </div>
              <div className={classes.colorPickerTitle}>
                {t('view.common.chromatoDetail.chromatoDetail.new_color')}
              </div>
            </div>
          </div>
          <div>
            <DialogActions style={{ float: 'right', marginBottom: 10 }}>
              <Button onClick={() => handleCloseChangeColor(true)} color="primary">
                {t('view.common.chromatoDetail.chromatoDetail.apply')}
              </Button>
              <Button autoFocus onClick={() => handleCloseChangeColor()} color="primary">
                {t('view.common.chromatoDetail.chromatoDetail.cancel')}
              </Button>
            </DialogActions>
          </div>
        </Box>
      </Popover>
      <Grid
        item
        className={clsx('unitaryAnalyse', classes.chromatoGrid)}
        key={unitaryAnalyse._id}
        event={unitaryAnalyse.event}
        unitaryanalysesid={unitaryAnalyse._id}
      >
        <SplitterLayout
          percentage
          ref={chromatoDetailRef}
          onDragEnd={onChromatoDetailSizeSave}
          secondaryInitialSize={
            localStorage.getItem('LAYOUT_chromatoDetail_secondaryPaneSize')
              ? parseFloat(localStorage.getItem('LAYOUT_chromatoDetail_secondaryPaneSize'))
              : 50
          }
          customClassName={classes.deeperSplitter}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ height: '100%' }}
          >
            {visibilities_istd && (
              <Grid
                key={stableKey_1.current}
                className={classes.sliderContainer}
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  key={stableKey_2.current}
                  style={{ top: 'calc(25% - 52px)', position: 'relative' }}
                  className={classes.lineLabel}
                  variant="h6"
                >
                  {t('view.common.chromatoDetail.chromatoDetail.compound')}
                </Typography>
                <Typography
                  key={stableKey_3.current}
                  style={{ bottom: 'calc(25% - 25px)', position: 'relative' }}
                  className={classes.lineLabel}
                  variant="h6"
                >
                  {t('view.common.chromatoDetail.chromatoDetail.istd')}
                </Typography>
              </Grid>
            )}
            <Grid
              key={stableKey_4.current}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              style={{ height: '100%' }}
            >
              <ErrorBoundaryGuard>
                <ChromatoDetailScatter
                  key={stableKey_5.current}
                  canEdit={canEdit}
                  synchIstd={synchIstd}
                  setSyncIstd={setSynchIstdFn}
                  setGraphPositionToIstd={setGraphPositionFn}
                  graphPositionISTD={graphPosition}
                  istdView={false}
                  getSelectedChannel={getSelectedChannel}
                  unitaryAnalyse={unitaryAnalyse}
                  mainUnitaryAnalyse={unitaryAnalyse} //Add the main UA to check the selected channel from him. Only usefull for ISTD ChromatoDetailscatter
                  data={memoizedData}
                  showLegend={false}
                  showChannels={false}
                  collection={collection}
                  // title={title(unitaryAnalyse)}
                  dispatch={dispatch}
                  currentPeaks={memoizedCurrentPeaks}
                  visibilities={memoizedVisibilities}
                  // colors={colors}
                  displayCurve={tableItemSelectedForColor}
                  opacity={chromatoOpacity}
                />
              </ErrorBoundaryGuard>
              {visibilities_istd && unitaryAnalyseISTD && (
                <ErrorBoundaryGuard>
                  <ChromatoDetailScatter
                    key={stableKey_6.current}
                    canEdit={canEdit}
                    synchIstd={synchIstd}
                    setSyncIstd={setSynchIstdFn}
                    setGraphPositionToIstd={setGraphPositionFn}
                    graphPositionISTD={graphPosition}
                    istdView={true}
                    getSelectedChannel={getSelectedChannel}
                    unitaryAnalyse={unitaryAnalyseISTD}
                    mainUnitaryAnalyse={unitaryAnalyse} //Add the main UA to check the selected channel from him
                    data={memoizedIstdData}
                    showLegend={false}
                    showChannels={false}
                    collection={collection}
                    // title={title(unitaryAnalyse)}
                    dispatch={dispatch}
                    currentPeaks={memoizedIstdCurrentPeaks}
                    visibilities={memoizedVisibilities}
                    // colors={colors}
                    displayCurve={tableItemSelectedForColor}
                    opacity={chromatoOpacity}
                  />
                </ErrorBoundaryGuard>
              )}
            </Grid>
            {externalWindowMode && (
              <IconButton onClick={handleCollapsButton} className={classes.collapsedButton}>
                {collapsed ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
              </IconButton>
            )}
          </Grid>
          {(!externalWindowMode || !collapsed) && (
            <Box
              style={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                paddingLeft: 12,
                paddingTop: 20,
              }}
            >
              <Paper
                style={{
                  paddingBottom: 10,
                  marginBottom: 10,
                  marginRight: 10,
                  background: '#80808008',
                }}
              >
                <Box>
                  <span style={{ marginLeft: 10, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.rt')}
                  </span>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        // icon={<VisibilityOff />}
                        // checkedIcon={<Visibility />}
                        name="rt"
                        checked={visibilities_rt}
                        onChange={function (event) {
                          setVisibilities_rt(event.target.checked)
                          localStorage.setItem('CHROMATO_visibility_rt', event.target.checked)
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <span style={{ marginLeft: 35, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.std')}
                  </span>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        name="std"
                        checked={visibilities_std}
                        onChange={function (event) {
                          setVisibilities_std(event.target.checked)
                          localStorage.setItem('CHROMATO_visibility_std', event.target.checked)
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <span style={{ marginLeft: 35, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.qc')}
                  </span>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        name="qa"
                        checked={visibilities_qa}
                        onChange={function (event) {
                          setVisibilities_qa(event.target.checked)
                          localStorage.setItem('CHROMATO_visibility_qa', event.target.checked)
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <span style={{ marginLeft: 35, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.blk')}
                  </span>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        name="blc"
                        checked={visibilities_blc}
                        onChange={function (event) {
                          setVisibilities_blc(event.target.checked)
                          localStorage.setItem('CHROMATO_visibility_blc', event.target.checked)
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  {unitaryAnalyse.type !== 'ISTD' && (
                    <span>
                      <span style={{ marginLeft: 35, fontWeight: 600 }}>
                        {t('view.common.chromatoDetail.chromatoDetail.istd')}:
                      </span>
                      <FormControlLabel
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox
                            name="istd"
                            checked={visibilities_istd}
                            onChange={function (event) {
                              setVisibilities_istd(event.target.checked)
                              localStorage.setItem('CHROMATO_visibility_istd', event.target.checked)
                            }}
                          />
                        }
                        labelPlacement="start"
                      />
                    </span>
                  )}
                  {/*<span style={{ marginLeft: 35, fontWeight: 600 }}>Others:</span>*/}
                  {/*<FormControlLabel*/}
                  {/*  style={{ marginLeft: 0 }}*/}
                  {/*  control={*/}
                  {/*    <Checkbox*/}
                  {/*      name="others"*/}
                  {/*      checked={visibilities.others}*/}
                  {/*      onChange={(event) => handleChange(event, 'others')}*/}
                  {/*    />*/}
                  {/*  }*/}
                  {/*  labelPlacement="start"*/}
                  {/*/>*/}
                </Box>
                <Box>
                  <span style={{ marginLeft: 10, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.peak')}
                  </span>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        // icon={<VisibilityOff />}
                        // checkedIcon={<Visibility />}
                        name="peak"
                        checked={visibilities_peak}
                        onChange={function (event) {
                          setVisibilities_peak(event.target.checked)
                          localStorage.setItem('CHROMATO_visibility_peak', event.target.checked)
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.selectIntegration}
                    disabled={!visibilities_peak}
                  >
                    <Select
                      native
                      value={unitaryAnalyse.selectedChannel}
                      onChange={handleChangeSelectedChannel}
                      inputProps={{
                        name: 'Integration',
                        id: 'outlined-integration-native-simple',
                      }}
                    >
                      {Object.keys(
                        unitaryAnalyse && unitaryAnalyse.channels ? unitaryAnalyse.channels : [],
                      ).map((channelIndex) => (
                        <option key={unitaryAnalyse._id + '_' + channelIndex} value={channelIndex}>
                          Channel {channelIndex}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ marginLeft: 35, fontWeight: 600 }}>
                    {t('view.common.chromatoDetail.chromatoDetail.channels')}
                  </span>
                  {Object.keys(
                    unitaryAnalyse && unitaryAnalyse.channels ? unitaryAnalyse.channels : [],
                  ).map((channelIndex) => (
                    <FormControlLabel
                      key={channelIndex}
                      control={
                        <Checkbox
                          // icon={<VisibilityOff />}
                          // checkedIcon={<Visibility />}
                          name={channelIndex}
                          checked={visibilities_channels[channelIndex]}
                          onChange={function (event) {
                            // localStorage.setItem(
                            //   'CHROMATO_visibility_' + origin,
                            //   JSON.stringify({
                            //     ...visibilities_channels,
                            //     [event.target.name]: event.target.checked,
                            //   }),
                            // )
                            setVisibilities_channels({
                              ...visibilities_channels,
                              [event.target.name]: event.target.checked,
                            })
                          }}
                        />
                      }
                      label={channelIndex}
                      labelPlacement="start"
                    />
                  ))}
                </Box>
                <Box>
                  <div
                    style={{
                      position: 'absolute',
                      marginTop: 15,
                      marginLeft: 10,
                      fontWeight: 600,
                    }}
                  >
                    {t('view.common.chromatoDetail.chromatoDetail.sp_oth')}
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{ width: 'calc(100% - 140px)', marginLeft: 130, minHeight: 49 }}
                  >
                    <Select
                      // labelId="demo-mutiple-chip-label"
                      // id="demo-mutiple-chip"
                      className={classes.selectChips}
                      multiple
                      autoWidth={true}
                      value={sampleName}
                      onChange={(e) => handleSampleChange(e)}
                      input={<Input /*id="select-multiple-chip"*/ />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                              onDelete={() => handleSampleDelete(value)}
                              onMouseDown={(event) => {
                                event.stopPropagation()
                              }}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {sampleList.map((sample) => (
                        <MenuItem
                          key={sample._id}
                          // value={sample._id + ':' + sample.analyse}
                          value={sample.analyse}
                          style={getStyles(sample.analyse, sampleName)}
                        >
                          <Checkbox
                            checked={
                              // sampleName.indexOf(sample._id + ':' + sample.analyse) > -1
                              sampleName.indexOf(sample.analyse) > -1
                            }
                          />
                          <ListItemText primary={sample.analyse} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box style={{ marginLeft: 10, marginRight: 21 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <OpacityIcon style={{ color: '#757575' }} />
                    </Grid>
                    <Grid item xs>
                      <Slider
                        value={typeof chromatoOpacity === 'number' ? chromatoOpacity : 1}
                        onChange={handleChromatoOpacityChange}
                        // marks={true}
                        step={0.01}
                        min={0.01}
                        max={1}
                      />
                    </Grid>
                    <Grid item style={{ width: 62 }}>
                      <Input
                        value={chromatoOpacity}
                        margin="dense"
                        onChange={handleInputChromatoOpacityChange}
                        onBlur={handleBlurChromatoOpacity}
                        inputProps={{
                          step: 0.01,
                          min: 0.01,
                          max: 1,
                          type: 'number',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <div className={classes.tableContainer}>
                <ErrorBoundaryGuard>
                  <TableMultiGrid
                    collection={'unitary_analysis'}
                    onMoleculeClick={() => true}
                    onMoleculeDoubleClick={() => true}
                    onMoleculeOverExtra={(row) => onDisplayCurve(row)}
                    // tableItemSelectedForColor={tableItemSelectedForColor}
                    idTable={'mychromatoTable'}
                    orderBy={'analyse'}
                    // data={tableData}
                    data={
                      Object.keys(visibilities_analyses).length > 0
                        ? stableSort(tableData, getComparator(sortDirection, sortOrder))
                        : []
                    }
                    isFetchingData={isFetchingCompounds}
                    sortAction={(order, direction) => {
                      setSortDirection(direction)
                      setSortOrder(order)
                    }}
                    columns={[
                      {
                        key: 'visibility',
                        label: t('view.common.chromatoDetail.chromatoDetail.column.display'),
                        size: 70,
                        type: 'custom',
                      },
                      {
                        key: 'color',
                        label: t('view.common.chromatoDetail.chromatoDetail.column.color'),
                        type: 'custom',
                        size: 70,
                      },
                      {
                        key: 'analyse',
                        label: t('view.common.chromatoDetail.chromatoDetail.column.analysis'),
                        size: 150,
                        type: 'text',
                      },
                      {
                        key: 'channelNumber',
                        label: t('view.common.chromatoDetail.chromatoDetail.column.channel'),
                        size: 80,
                        type: 'custom',
                      },
                      {
                        key: 'concentration',
                        label: (
                          <Trans i18nKey="view.common.chromatoDetail.chromatoDetail.column.conc">
                            Conc({{ val: volumeConcentrationUnit }})
                          </Trans>
                        ),
                        size: 120,
                        type: 'number',
                        customDisplay: function (value, item, columnInfos) {
                          if (value && value !== '' && value !== null) {
                            return customFormatDecimals(
                              value,
                              displayHumanReadable(convertVolumeConcentrationValue(value, item), 4),
                              columnInfos,
                            )
                          }
                          return value
                        },
                      },
                      {
                        key: 'area',
                        label: t('view.common.chromatoDetail.chromatoDetail.column.area'),
                        size: 120,
                        type: 'number',
                        customDisplay: function (value, item, columnInfos) {
                          if (value && value !== '' && value !== null) {
                            return customFormatDecimals(
                              value,
                              displayHumanReadable(value, 0),
                              columnInfos,
                            )
                          }
                          return value
                        },
                      },
                    ]}
                    fixedRowCount={1}
                    rowHeight={40}
                    getColumnRender={function (
                      columnIndex,
                      key,
                      rowIndex,
                      style,
                      item,
                      rowClassNames,
                      columnKey,
                      moleculeOverState,
                      moleculeOver,
                      selected,
                      onMoleculeClick,
                      onMoleculeDoubleClick,
                      columns,
                    ) {
                      const value = item[columnKey]
                      // if (columnKey === 'analyse') {
                      //   return (
                      //     <div
                      //       style={style}
                      //       key={item._id + columnKey + rowIndex + key}
                      //       className={clsx(
                      //         rowClassNames,
                      //         moleculeOverState === rowIndex && classes.selectedRow,
                      //         selected === item._id ? classes.blink : classes.noBlink,
                      //         moleculeOverState === rowIndex &&
                      //           selected === item._id &&
                      //           classes.selectedRowBlink,
                      //       )}
                      //       onMouseEnter={() => moleculeOver(rowIndex, true, item._id)}
                      //       onMouseLeave={() => moleculeOver(rowIndex, false)}
                      //       onClick={() => true}
                      //     >
                      //       <Badge
                      //         badgeContent={item.istd ? 'ISTD' : 0}
                      //         invisible={!item.istd}
                      //         color="primary"
                      //         className={classes.istdBadge}
                      //       >
                      //         <div>{item.analyse}</div>
                      //       </Badge>
                      //     </div>
                      //   )
                      // }
                      if (columnKey === 'visibility' /*&& item.visibility*/) {
                        return (
                          <div
                            style={style}
                            key={item._id + columnKey + rowIndex + key}
                            className={clsx(
                              rowClassNames,
                              moleculeOverState === rowIndex && classes.selectedRow,
                              selected === item._id ? classes.blink : classes.noBlink,
                              moleculeOverState === rowIndex &&
                                selected === item._id &&
                                classes.selectedRowBlink,
                            )}
                            onMouseEnter={() => moleculeOver(rowIndex, true, item._id)}
                            onMouseLeave={() => moleculeOver(rowIndex, false)}
                            onClick={() => true}
                          >
                            <FormControlLabel
                              style={{ marginLeft: 0, top: 0, position: 'absolute' }}
                              control={
                                <Checkbox
                                  icon={<VisibilityOff />}
                                  checkedIcon={<Visibility />}
                                  // name={item._id + item.channelNumber}
                                  // checked={visibilities.analyses[item._id + item.channelNumber]}
                                  name={item._id}
                                  checked={visibilities_analyses[item._id]}
                                  onChange={function (event) {
                                    // localStorage.setItem(
                                    //   'CHROMATO_visibility_' + origin,
                                    //   JSON.stringify({
                                    //     ...visibilities_analyses,
                                    //     [event.target.name]: event.target.checked,
                                    //   }),
                                    // )
                                    setVisibilities_analyses({
                                      ...visibilities_analyses,
                                      [event.target.name]: event.target.checked,
                                    })
                                  }}
                                />
                              }
                            />
                          </div>
                        )
                      }
                      if (columnKey === 'color') {
                        return (
                          <div
                            style={style}
                            key={item._id + columnKey + rowIndex + key}
                            className={clsx(
                              rowClassNames,
                              moleculeOverState === rowIndex && classes.selectedRow,
                              selected === item._id ? classes.blink : classes.noBlink,
                              moleculeOverState === rowIndex &&
                                selected === item._id &&
                                classes.selectedRowBlink,
                            )}
                            onMouseEnter={() => moleculeOver(rowIndex, true, item._id)}
                            onMouseLeave={() => moleculeOver(rowIndex, false)}
                            onClick={(event) => onOpenChangeColor(event, item)}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 6,
                                padding: 1.5,
                                border:
                                  '1.5px ' + getColorBorderMode(item.channelNumber) + ' #757575',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: item.color,
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: 4,
                                }}
                              ></div>
                            </div>
                          </div>
                        )
                      }
                      if (columnKey === 'channelNumber') {
                        return (
                          <div
                            style={style}
                            key={item._id + columnKey + rowIndex + key}
                            className={clsx(
                              rowClassNames,
                              moleculeOverState === rowIndex && classes.selectedRow,
                              selected === item._id ? classes.blink : classes.noBlink,
                              moleculeOverState === rowIndex &&
                                selected === item._id &&
                                classes.selectedRowBlink,
                            )}
                            onMouseEnter={() => moleculeOver(rowIndex, true, item._id)}
                            onMouseLeave={() => moleculeOver(rowIndex, false)}
                            onClick={() => true}
                          >
                            <div style={{ position: 'absolute' }}>{item.channelNumber}</div>
                            {item.channelNumber === item.mainChannel && (
                              <StarIcon
                                style={{
                                  fontSize: 20,
                                  color: 'gold',
                                  marginLeft: 10,
                                  top: 8,
                                  position: 'absolute',
                                }}
                              />
                            )}
                          </div>
                        )
                      }
                      return null
                    }}
                  />
                </ErrorBoundaryGuard>
              </div>
            </Box>
          )}
        </SplitterLayout>
      </Grid>
      {!externalWindowMode && (
        <ErrorBoundaryGuard>
          <Pagination
            collection={collection}
            style={{ position: 'absolute', width: '100%', marginTop: 11 }}
          />
        </ErrorBoundaryGuard>
      )}
      {(isFetchingISTD || isFetchingCompounds) && (
        <div style={{ position: 'absolute', width: 30, height: 30, top: 33, right: 28 }}>
          <Loading mode={'microCircle'} />
        </div>
      )}
    </>
  )
})

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       console.log(`${prop} changed from`, prevProps[prop], 'to', nextProps[prop])
//     })
//   }
//
//   return propsChanged.length === 0
// }
// export default React.memo(ChromatoDetail, areEqual)

// const ChromatoTableWithRouter = withRouter(ChromatoTableComponent)

// export default ChromatoTableWithRouter

const ChromatoDetail = React.memo(
  React.forwardRef((props, ref) => {
    return <ChromatoDetailWithRouter {...props} forwardedRef={ref} />
  }),
  // areEqual,
)

export default ChromatoDetail
