import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import { Molecule, StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { withRouter } from 'react-router-dom'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import FilterListIcon from '@material-ui/icons/FilterList'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import { PUBLIC_URL, USE_OLD_TABLE } from '../../utils/config'
import {
  fetchUnitaryAnalyses,
  fetchUnitaryAnalysesCompound,
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryAnalysesFiltered,
  fetchUnitaryCompoundDistinct,
} from '../../redux/unitaryAnalyses/actions'
import { fetchAnalyses } from '../../redux/analyses/actions'
import Loading from '../Loading'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import SearchIcon from '@material-ui/icons/Search'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import InputBase from '@material-ui/core/InputBase'
import ClearIcon from '@material-ui/icons/Clear'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import { addOrEditFilter, fetchFilters, fetchRemoveFilter } from '../../redux/filters/actions'
import WarningIcon from '@material-ui/icons/Warning'
import InputLabel from '@material-ui/core/InputLabel'
import Stack from '@mui/material/Stack'
import Slider from '@material-ui/core/Slider'
import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { fetchTeams } from '../../redux/teams/actions'
import { OutlinedInput } from '@material-ui/core'
import Box from '@mui/material/Box'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { fetchBatch } from '../../redux/batches/actions'
import Typography from '@material-ui/core/Typography'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { FormHelperText } from '@mui/material'
import { getBatchById } from '../../redux/batches/selectors'
const styles = (theme) => ({
  badgeCount: {
    '& .MuiBadge-badge': {
      right: 2,
      top: -1,
      color: alpha(theme.palette.secondary.main, 0.8),
      background: 'white',
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeFilter: {
    display: 'flex',
    margin: '0px 0px 0px 10px',
    background: 'lightgrey',
    color: 'white',
    fontSize: 12,
    padding: '1px 8px',
    borderRadius: 14,
  },
  iconFilter: {
    float: 'left',
    position: 'absolute',
    top: 2,
  },
  formControl: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 32,
    padding: '0px 12px 0px 14px',
    borderRadius: 18,
    backgroundColor: '#e0e0e0',
    color: '#666',
    '& .MuiInput-root::after': {
      borderBottomWidth: '0px !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  formControlActive: {
    backgroundColor: theme.palette.secondary.main,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiInput-root': {
      color: 'white',
    },
    '& .MuiInput-underline::after': {
      borderBottomWidth: '0px !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  formSelect: {
    paddingLeft: 30,
    color: '#666',
    '&:before': {
      borderBottomWidth: '0px !important',
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottomWidth: '0px !important',
    },
    '& > .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiSelect-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 190,
    },
  },
  addButton: {
    color: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
  },
  addButtonMenu: {
    '&:hover svg': {
      color: theme.palette.secondary.main,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  cardError: {
    border: '1px solid #f44336',
    borderRadius: 3,
    '& .MuiCardHeader-content .MuiCardHeader-subheader': {
      color: '#f44034cc',
    },
  },
  list: {
    width: '100%',
    height: 250,
    // backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
    background: '#f9f9f9',
  },
  settingsHeader: {
    height: 130,
    background: 'url(' + PUBLIC_URL + '/images/filter_background.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 20px 10px rgba(0,0,0,0.1)',
    paddingTop: 65,
    textShadow: '2px 1px 2px grey',
    '& h2': {
      fontSize: 40,
      textShadow: '2px 1px 10px white',
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
  },
  deleteButton: {
    // marginLeft: 10,
    padding: 0,
    // position: 'absolute',
    // right: 4,
    '& .deleteButtonIconUnactive': {
      opacity: 1,
      position: 'absolute',
    },
    '& .deleteButtonIconActive': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .deleteButtonIconUnactive': {
      opacity: 0,
    },
    '&:hover .deleteButtonIconActive': {
      color: theme.palette.common.error,
      opacity: 1,
    },
  },
  cloneButton: {
    // marginLeft: 10,
    padding: 0,
    // position: 'absolute',
    // right: 32,
    '& .cloneButtonIconUnactive': {
      opacity: 1,
      position: 'absolute',
    },
    '& .cloneButtonIconActive': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .cloneButtonIconUnactive': {
      opacity: 0,
    },
    '&:hover .cloneButtonIconActive': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },
  editButton: {
    // marginLeft: 10,
    padding: 0,
    // position: 'absolute',
    // right: 65,
    '& .editButtonIconUnactive': {
      opacity: 1,
      position: 'absolute',
    },
    '& .editButtonIconActive': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .editButtonIconUnactive': {
      opacity: 0,
    },
    '&:hover .editButtonIconActive': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },
  tooltip: {
    maxWidth: 500,
  },
  dialogFilter: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 1000,
    },
    '& .MuiListItem-dense': {
      padding: 0,
    },
    '& .MuiListItem-dense .MuiCheckbox-root': {
      padding: '0px 0px 0px 17px',
    },
    '& .MuiListItem-dense .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 5,
    },
  },
  search: {
    position: 'relative',
    marginRight: '12px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      marginTop: 3,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
    },
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  warningMessage: {
    paddingTop: 10,
    color: theme.palette.common.warning,
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
  },
  colorPickerTitle: {
    background:
      'linear-gradient(0deg, rgb(255 255 255) 56%, rgba(255,255,255,0) 56%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  teamsSelect: {
    '& > .MuiSelect-select': {
      padding: 12,
      minHeight: 32,
    },
    '& > svg': {
      right: 7,
    },
  },
  errorHelper: {
    color: theme.palette.error.main,
  },
})

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

const mapStateToProps = (state, ownProps) => {
  const { filters, teams, batches } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  return {
    isFetchingFilters: filters.isFetchingFilters,
    filters: filters.filters.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return 0
    }),
    teams: teams.teams,
    batch: batch,
  }
}

class CompoundFilterButton extends React.Component {
  constructor(props) {
    super(props)

    const useOldTable = USE_OLD_TABLE()
    this.state = {
      selectedFilterId: localStorage.getItem('GLOBAL_filter_selected')
        ? JSON.parse(localStorage.getItem('GLOBAL_filter_selected'))
        : 0,
      openFilter: false,
      openConfirmDelete: false,
      checked: [],
      left: [],
      right: [],
      name: 'New compound filter',
      filterToDelete: null,
      filterToEdit: null,
      filtersTitle: '',
      timerLeft: null,
      timerRight: null,
      displayLeftClear: false,
      displayRightClear: false,
      searchLeft: '',
      searchRight: '',
      filterColumn: {},
      filterOperator: {},
      filterValue: {},
      indexOfFilters: [],
      // filterColumn: { 0: null },
      // filterOperator: { 0: null },
      // filterValue: { 0: null },
      // indexOfFilters: [0],
      lastIndexOfFilter: 0,
      filterTeams: [],
      unitaryAnalyses: [],
      isFetching: true,
      editLock: true,
      openFilterTooltip: false,
      openFilterSelect: false,
      keyToUseForColumn: useOldTable ? 'key' : 'field',
      nameToUseForColumn: useOldTable ? 'label' : 'headerName',
    }
    this.searchLeftRef = React.createRef()
    this.searchRightRef = React.createRef()
  }

  componentDidMount() {
    const { dispatch, batch } = this.props
    dispatch(fetchTeams())
    this.setState({
      unitaryAnalyses: Object.entries(batch.content.molecules)
        .map((mol) => ({ name: mol[1].name, istd: mol[1].istd_name === mol[1].name }))
        .sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        }),
      isFetching: false,
    })
    // const me = this
    // dispatch(fetchTeams())
    // const batchId = this.props.match.params.batchId
    // dispatch(fetchBatch(batchId))
    //   .then(function (resp) {
    //     if (resp && resp.batch && resp.batch.content) {
    //       me.setState({
    //         unitaryAnalyses: Object.entries(resp.batch.content.molecules)
    //           .map((mol) => ({ name: mol[1].name, istd: mol[1].istd_name === mol[1].name }))
    //           .sort(function (a, b) {
    //             if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    //             if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    //             return 0
    //           }),
    //       })
    //     }
    //   })
    //   .finally(() => me.setState({ isFetching: false }))
  }

  onSearch(e, ref) {
    if (ref === this.searchLeftRef) {
      const { timerLeft } = this.state
      const newValue = e.target.value
      clearTimeout(timerLeft)
      const newTimer = setTimeout(
        function () {
          this.setState({ checked: [], searchLeft: newValue })
        }.bind(this),
        500,
      )
      this.setState({ timerLeft: newTimer, displayLeftClear: newValue !== '' })
    } else {
      const { timerRight } = this.state
      const newValue = e.target.value
      clearTimeout(timerRight)
      const newTimer = setTimeout(
        function () {
          this.setState({ checked: [], searchRight: newValue })
        }.bind(this),
        500,
      )
      this.setState({ timerRight: newTimer, displayRightClear: newValue !== '' })
    }
  }

  clearSearch(e, ref) {
    if (ref === this.searchLeftRef) {
      this.searchLeftRef.current.children[0].value = ''
      this.setState({ searchLeft: '', displayLeftClear: false })
    } else {
      this.searchRightRef.current.children[0].value = ''
      this.setState({ searchRight: '', displayRightClear: false })
    }
  }

  render() {
    const { dispatch, classes, filters, deviceType, columns, teams } = this.props
    const {
      isFetching,
      unitaryAnalyses,
      openFilter,
      selectedFilterId,
      checked,
      left,
      right,
      name,
      openConfirmDelete,
      filterToDelete,
      filterToEdit,
      filtersTitle,
      searchLeft,
      searchRight,
      filterColumn,
      filterOperator,
      filterValue,
      filterTeams,
      indexOfFilters,
      lastIndexOfFilter,
      editLock,
      openFilterTooltip,
      openFilterSelect,
      keyToUseForColumn,
      nameToUseForColumn,
    } = this.state

    const openFilterDialog = (event, filterToEdit) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({
        openFilter: true,
        name: filterToEdit ? filterToEdit.name : 'New filter',
        searchLeft: '',
        searchRight: '',
        filtersTitle: filters.map((custom) => custom.name).join('#$#') + '#$#',
        // filterColumn: { 0: null },
        // filterOperator: { 0: null },
        // filterValue: { 0: null },
        // indexOfFilters: [0],
        filterTeams: [],
        filterColumn: {},
        filterOperator: {},
        filterValue: {},
        indexOfFilters: [],
        lastIndexOfFilter: 0,
        editLock: true,
      })

      if (filterToEdit) {
        // Load filter compounds
        const originLeftString = unitaryAnalyses.map((ol) => JSON.stringify(ol))
        filterToEdit.content.compounds.forEach((compound) => {
          const _index = originLeftString.indexOf(JSON.stringify(compound))
          if (_index > -1) {
            originLeftString.splice(_index, 1)
          }
        })
        // Load filter columns
        let filterColumn = { 0: null }
        let filterOperator = { 0: null }
        let filterValue = { 0: null }
        let indexOfFilters = []
        let lastIndexOfFilter = 0
        if (filterToEdit.content.columnFilters) {
          filterToEdit.content.columnFilters.forEach((filter, index) => {
            filterColumn[index] = filter.column
            filterOperator[index] = filter.operator
            filterValue[index] = filter.value
            indexOfFilters.push(index)
            lastIndexOfFilter = index
          })
        }
        this.setState({
          filterTeams: filterToEdit.teams.map((t) => t.id),
          lastIndexOfFilter: lastIndexOfFilter,
          indexOfFilters: indexOfFilters,
          filterColumn: filterColumn,
          filterOperator: filterOperator,
          filterValue: filterValue,
          left: originLeftString.map((ol) => JSON.parse(ol)),
          right: filterToEdit.content.compounds.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
          }),
        })
      } else {
        this.setState({
          left: unitaryAnalyses,
          right: [],
          checked: unitaryAnalyses.filter((ua) => ua.istd),
        })
      }
    }

    // Don't understand why I should do this to prevent click on dialog trigger row clic behind...
    const handleNothing = (event) => {
      event.stopPropagation()
      event.preventDefault()
    }

    const handleFilterClose = (event, save, edit) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ openFilter: false })
      if (save) {
        const rightSorted = right.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        })
        const columnFilters = Object.keys(filterColumn)
          .map((c) => ({
            column: filterColumn[c],
            operator: filterOperator[c],
            value: filterValue[c],
          }))
          .filter((f) => f.column !== null && f.operator !== null && f.value !== null)
        const taskId = new Date().getTime() + '_add_filter_' + name + deviceType + right.length
        const task = {
          id: taskId,
          title: (
            <Trans i18nKey="view.analysis.filterButton.title">
              {{
                val: edit
                  ? t('view.analysis.filterButton.edit')
                  : t('view.analysis.filterButton.new'),
              }}
              filter
            </Trans>
          ),
          operator: localStorage.getItem('SESSION_user_name'),
          date: new Date().getTime(),
          action: edit ? 'edit_filter' : 'add_filter',
          percentage: 50,
          state: { value: 'running', message: '' },
          operation: (
            <Trans i18nKey="view.analysis.filterButton.operation">
              the {{ val1: deviceType }} filter is named {{ val2: name }}
            </Trans>
          ),
          items: rightSorted.map((rs) => ({ name: rs.name, id: rs.name + '_id' })),
        }
        dispatch(fetchAddTask(task))
        dispatch(
          addOrEditFilter(
            name,
            deviceType,
            rightSorted,
            columnFilters,
            teams
              .filter((t) => filterTeams.indexOf(t._id) !== -1)
              .map((t) => ({ id: t._id, name: t.name })),
            filterToEdit ? filterToEdit._id : false,
          ),
        )
          .then(function () {
            dispatch(fetchFinishTask(taskId, 'success', t('view.analysis.filterButton.fine')))
            displayPopupMessage(
              dispatch,
              'success',
              <Trans i18nKey="view.analysis.filterButton.title">
                {{
                  val: edit
                    ? t('view.analysis.filterButton.edit')
                    : t('view.analysis.filterButton.new'),
                }}
                filter
              </Trans>,
              <Fragment>
                <div>
                  <Trans i18nKey="view.analysis.filterButton.correct">
                    The filter {{ val1: name }} is correctly
                    {{
                      val2: edit
                        ? t('view.analysis.filterButton.edited')
                        : t('view.analysis.filterButton.added'),
                    }}
                    .
                  </Trans>
                </div>
              </Fragment>,
            )
            // If the filter selected is the edited filter, reload the compounds list
            if (filterToEdit && filterToEdit._id === selectedFilterId) {
              reloadUnitaryAnalysesCompound()
            }
          })
          .catch(function (error) {
            dispatch(fetchFinishTask(taskId, 'error', error))
            displayPopupMessage(
              dispatch,
              'error',
              <Trans i18nKey="view.analysis.filterButton.title">
                {{
                  val: edit
                    ? t('view.analysis.filterButton.edit')
                    : t('view.analysis.filterButton.new'),
                }}
                filter
              </Trans>,
              <Fragment>
                <div>
                  <Trans i18nKey="view.analysis.filterButton.not_correct">
                    The filter {{ val1: name }} is correctly
                    {{
                      val2: edit
                        ? t('view.analysis.filterButton.edited')
                        : t('view.analysis.filterButton.added'),
                    }}
                    .
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          })
      }
      this.setState({ filterToEdit: null })
    }

    const handleConfirmClose = (event, save) => {
      event.stopPropagation()
      event.preventDefault()
      const me = this
      this.setState({ openConfirmDelete: false })
      if (save) {
        const taskId = new Date().getTime() + '_remove_filter_' + filterToDelete._id
        const task = {
          id: taskId,
          title: t('view.analysis.filterButton.remove_filter'),
          operator: localStorage.getItem('SESSION_user_name'),
          date: new Date().getTime(),
          action: 'remove_filter',
          percentage: 50,
          state: { value: 'running', message: '' },
          operation: (
            <Trans i18nKey="view.analysis.filterButton.remove_op">
              the {{ val1: deviceType }} filter {{ val2: filterToDelete.name }} will be removed
            </Trans>
          ),
          items: filterToDelete.content.compounds.map((rs) => ({
            name: rs.name,
            id: rs.name + '_id',
          })),
        }
        dispatch(fetchAddTask(task))
        dispatch(fetchRemoveFilter(filterToDelete._id))
          .then(function () {
            dispatch(fetchFinishTask(taskId, 'success', t('view.analysis.filterButton.fine')))
            displayPopupMessage(
              dispatch,
              'success',
              t('view.analysis.filterButton.remove_filter'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.analysis.filterButton.correctly_delete">
                    The filter {{ val: filterToDelete.name }} is correctly deleted.
                  </Trans>
                </div>
              </Fragment>,
            )
            // If the filter selected is the deleted filter, reload the compounds list without filter
            if (filterToDelete && filterToDelete._id === selectedFilterId) {
              //Update the filter button
              me.setState({
                selectedFilterId: 0,
              })
              localStorage.setItem('GLOBAL_filter_selected', JSON.stringify(0))
              // Reload the list
              reloadUnitaryAnalysesCompound()
            }
          })
          .catch(function (error) {
            dispatch(fetchFinishTask(taskId, 'error', error))
            displayPopupMessage(
              dispatch,
              'error',
              t('view.analysis.filterButton.remove_filter'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.analysis.filterButton.correctly_delete">
                    The filter {{ val: filterToDelete.name }} isn't correctly deleted:
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          })
      }
    }

    const handleChange = (event, origin) => {
      event.stopPropagation()
      event.preventDefault()
      const realKey = event.target.value
      switch (realKey) {
        case '-1':
          openFilterDialog(event)
          return true
        case '0':
          this.setState({ selectedFilterId: 0 })
          localStorage.setItem('GLOBAL_filter_selected', JSON.stringify(0))
          break
        default:
          this.setState({ selectedFilterId: realKey })
          localStorage.setItem('GLOBAL_filter_selected', JSON.stringify(realKey))
          break
      }
      reloadUnitaryAnalysesCompound()
    }

    const reloadUnitaryAnalysesCompound = () => {
      dispatch(
        fetchUnitaryAnalysesCompound(
          this.props.match.params.batchId,
          this.props.match.params.moleculeEvent,
          localStorage.getItem('COMPOUND_filter_untreated')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_untreated')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_requested')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_requested')) === true
            : true,
          localStorage.getItem('COMPOUND_filter_detected')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_detected')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_notDetected')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_notDetected')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_relaunch')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_relaunch')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_std')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_std')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_mb')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_mb')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_mbd')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_mbd')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_stde')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_stde')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_blk')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_blk')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_spl')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_spl')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_oth')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_oth')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_allDetected')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_allDetected')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_allSuspected')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_allSuspected')) === true
            : false,
          localStorage.getItem('COMPOUND_filter_allExcluded')
            ? JSON.parse(localStorage.getItem('COMPOUND_filter_allExcluded')) === true
            : false,
          null,
          localStorage.getItem('COMPOUND_sorter'),
        ),
      )
    }

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value)
      const newChecked = [...checked]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      this.setState({ checked: newChecked })
    }

    const numberOfChecked = (items) => intersection(checked, items).length

    const handleToggleAll = (items) => () => {
      if (numberOfChecked(items) === items.length) {
        this.setState({ checked: not(checked, items) })
      } else {
        this.setState({ checked: union(checked, items) })
      }
    }

    const handleCheckedRight = () => {
      this.setState({ right: right.concat(leftChecked) })
      this.setState({ left: not(left, leftChecked) })
      this.setState({ checked: not(checked, leftChecked) })
    }

    const handleCheckedLeft = () => {
      this.setState({ left: left.concat(rightChecked) })
      this.setState({ right: not(right, rightChecked) })
      this.setState({ checked: not(checked, rightChecked) })
    }

    const handleNameChanged = (event) => {
      const value = event.target.value
      if (value === '' || filtersTitle.indexOf(value + '#$#') === -1) {
        this.setState({ name: value })
      } else {
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.analysis.filterButton.name_exist'),
          <Fragment>
            <div>
              <Trans i18nKey="view.analysis.filterButton.name_exist1">
                You cannot use the name {{ val: value }} for this filter.
              </Trans>
            </div>
            <div>{t('view.analysis.filterButton.name_exist2')}</div>
          </Fragment>,
        )
      }
    }

    const customList = (title, items, ref, error) => (
      <Card style={{ background: '#f3f3f3' }}>
        <div style={{ display: 'flex', background: '#f9f9f9' }}>
          <CardHeader
            className={clsx(classes.cardHeader, error ? classes.cardError : null)}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                  numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
              />
            }
            title={title}
            subheader={
              `${numberOfChecked(items)}/${items.length} ` +
              t('view.analysis.filterButton.selected')
            }
          />
          <div style={{ padding: '8px 16px' }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t('view.analysis.filterButton.search')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => this.onSearch(e, ref)}
                ref={ref}
              />
              {ref === this.searchLeftRef
                ? this.state.displayLeftClear && (
                    <IconButton
                      className={clsx(classes.clearIcon, classes.searchIcon)}
                      aria-label="clear"
                      onClick={(e) => this.clearSearch(e, ref)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )
                : this.state.displayRightClear && (
                    <IconButton
                      className={clsx(classes.clearIcon, classes.searchIcon)}
                      aria-label="clear"
                      onClick={(e) => this.clearSearch(e, ref)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
            </div>
          </div>
        </div>
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map((value, index) => {
            const labelId = `transfer-list-all-item-${value.name}-label`

            return (
              <ListItem
                key={value.name + index}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText style={{ flex: 'none' }} id={labelId} primary={`${value.name}`} />
                {value.istd && (
                  <div
                    style={{
                      marginLeft: 16,
                      marginTop: 2,
                      fontSize: 9,
                      padding: '1px 4px',
                      transition: 'all 0.5s ease',
                      background: '#757575',
                      color: 'white',
                      borderRadius: 5,
                    }}
                  >
                    {t('view.analysis.filterButton.istd')}
                  </div>
                )}
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Card>
    )

    const deleteCustomFilter = (event, filter, index) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ openConfirmDelete: true, filterToDelete: filter })
    }

    const editCustomFilter = (event, filter, index) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ filterToEdit: filter })
      openFilterDialog(event, filter)
    }

    const cloneCustomFilter = (event, filter, index) => {
      const me = this
      event.stopPropagation()
      event.preventDefault()
      dispatch(
        addOrEditFilter(
          filter.name + '_cloned',
          filter.content.type,
          filter.content.compounds,
          filter.content.columnFilters,
          teams,
          false,
        ),
      )
        .then(function (resp) {
          me.setState({ filterToEdit: resp.newFilter })
          openFilterDialog(event, resp.newFilter)
        })
        .catch(function (error) {
          displayPopupMessage(
            dispatch,
            'error',
            t('view.analysis.filterButton.clone'),
            <Fragment>
              <div>
                <Trans i18nKey="view.analysis.filterButton.clone_error">
                  The filter {{ val: name }} isn't correctly cloned:
                </Trans>
              </div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        })
    }

    const applySearchFilter = (list, listId) => {
      if (listId === 'left' && searchLeft !== '') {
        return list.filter((compound) =>
          compound.name.toLowerCase().includes(searchLeft.toLowerCase()),
        )
      } else if (listId === 'right' && searchRight !== '') {
        return list.filter((compound) =>
          compound.name.toLowerCase().includes(searchRight.toLowerCase()),
        )
      } else {
        return list
      }
    }

    const selectedFilterFromFilters =
      selectedFilterId !== 0 && filters
        ? filters.filter((f) => f._id === selectedFilterId)[0]
        : null

    const handleFilterColumn = (event, index) => {
      const newFilterColumn = event.target.value
      this.setState({ filterColumn: { ...this.state.filterColumn, [index]: newFilterColumn } })
    }
    const handleFilterOperator = (event, index) => {
      const newFilterOperator = event.target.value
      this.setState({
        filterOperator: { ...this.state.filterOperator, [index]: newFilterOperator },
      })
    }
    const handleFilterValue = (event, index) => {
      const newFilterValue = event.target.value
      this.setState({ filterValue: { ...this.state.filterValue, [index]: newFilterValue } })
    }
    const addNewFilter = () => {
      let tabIndex = this.state.indexOfFilters
      tabIndex.push(this.state.lastIndexOfFilter + 1)
      this.setState({
        lastIndexOfFilter: this.state.lastIndexOfFilter + 1,
        indexOfFilters: tabIndex,
        filterColumn: { ...this.state.filterColumn, [this.state.lastIndexOfFilter + 1]: null },
        filterOperator: { ...this.state.filterOperator, [this.state.lastIndexOfFilter + 1]: null },
        filterValue: { ...this.state.filterValue, [this.state.lastIndexOfFilter + 1]: null },
      })
    }
    const deleteFilter = (index) => {
      // if (this.state.indexOfFilters.length > 1) {
      Reflect.deleteProperty(this.state.filterColumn, index)
      Reflect.deleteProperty(this.state.filterOperator, index)
      Reflect.deleteProperty(this.state.filterValue, index)
      this.setState({
        indexOfFilters: this.state.indexOfFilters.filter((id) => id !== index),
        filterColumn: this.state.filterColumn,
        filterOperator: this.state.filterOperator,
        filterValue: this.state.filterValue,
      })
      // } else {
      //   this.setState({
      //     filterColumn: { [index]: null },
      //     filterOperator: { [index]: null },
      //     filterValue: { [index]: null },
      //   })
      // }
    }

    const getFilterType = (key) => {
      const column = columns.filter((col) => col[keyToUseForColumn] === key)[0]
      if (column) {
        return column.type
      } else {
        return null
      }
    }

    const handleTeamsChange = (event) => {
      const {
        target: { value },
      } = event
      this.setState({ filterTeams: typeof value === 'string' ? value.split(',') : value })
    }

    const handleCloseFilterTooltip = () => {
      this.setState({ openFilterTooltip: false })
    }

    const handleOpenFilterTooltip = () => {
      if (!openFilterSelect) {
        this.setState({ openFilterTooltip: true })
      }
    }

    const handleCloseFilterSelect = () => {
      this.setState({ openFilterSelect: false })
    }

    const handleOpenFilterSelect = () => {
      this.setState({ openFilterTooltip: false, openFilterSelect: true })
    }

    return (
      <Fragment>
        <Dialog
          open={openConfirmDelete}
          // onBackdropClick={(event) => handleConfirmClose(event)}
          onClose={(event) => handleConfirmClose(event)}
          TransitionComponent={TransitionTop}
        >
          <div onClick={(event) => handleNothing(event)}>
            <StyledDialogTitle>{t('view.analysis.filterButton.remove_filter')}</StyledDialogTitle>
            <DialogContent style={{ padding: 20 }}>
              <Trans i18nKey="view.analysis.filterButton.are_sure">
                Are you sure you want delete the filter
                <b>{{ val: filterToDelete ? filterToDelete.name : '' }}</b>?
              </Trans>
              <div className={classes.warningMessage}>
                <WarningIcon style={{ float: 'left' }} />{' '}
                <span style={{ marginTop: 5, float: 'left', marginLeft: 10 }}>
                  {t('view.analysis.filterButton.careful')}
                </span>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={(event) => handleConfirmClose(event, true)} color="primary">
                {t('view.analysis.filterButton.delete_button')}
              </Button>
              <Button onClick={(event) => handleConfirmClose(event)} color="primary">
                {t('view.analysis.filterButton.cancel')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          className={classes.dialogFilter}
          open={openFilter}
          // onBackdropClick={(event) => handleFilterClose(event)}
          onClose={(event) => handleFilterClose(event)}
          TransitionComponent={TransitionTop}
        >
          <div onClick={(event) => handleNothing(event)}>
            <DialogTitle className={classes.settingsHeader}>
              <Trans i18nKey="view.analysis.filterButton.title">
                {{
                  val: filterToEdit
                    ? t('view.analysis.filterButton.edit')
                    : t('view.analysis.filterButton.new'),
                }}
                filter
              </Trans>
            </DialogTitle>
            <DialogContent style={{ padding: 20, backgroundColor: '#f9f9f9' }}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  style={{ width: '100%' }}
                >
                  <TextField
                    style={{
                      width: '30%',
                      margin: '10px 7px 0px',
                      background: 'white',
                      borderRadius: 7,
                    }}
                    error={name === ''}
                    id="outlined-error-helper-text"
                    label={t('view.analysis.filterButton.filter_name')}
                    value={name}
                    autoFocus
                    required
                    variant="outlined"
                    onChange={handleNameChanged}
                  />
                  <FormControl
                    required
                    style={{
                      width: '70%',
                      minHeight: 56,
                      margin: '10px 7px 0px',
                      background: 'white',
                      borderRadius: 7,
                    }}
                    error={filterTeams.length < 1}
                  >
                    <InputLabel style={{ position: 'absolute', top: -7, left: 13 }}>
                      {t('view.analysis.filterButton.teams')}
                    </InputLabel>
                    <Select
                      // variant="outlined"
                      className={classes.teamsSelect}
                      multiple
                      value={filterTeams}
                      onChange={handleTeamsChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Teams *" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {teams
                            .filter((t) => selected.indexOf(t._id) !== -1)
                            .map((value) => (
                              <Chip key={value._id} label={value.name} />
                            ))}
                        </Box>
                      )}
                    >
                      {teams.map((team) => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <div style={{ width: '100%', marginTop: 30 }}>
                <div
                  className={classes.colorPickerCnt}
                  style={{
                    width: '100%',
                    height: '100%',
                    background: 'white',
                  }}
                >
                  <Stack
                    style={{ width: '100%' }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={0}
                  >
                    <Stack
                      style={{
                        width: '100%',
                        padding: '0px 20px',
                        maxHeight: 175,
                        overflow: 'auto',
                        display: 'block',
                        marginTop: 5,
                      }}
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={2}
                    >
                      {this.state.indexOfFilters.map((index, idx) => (
                        <Stack
                          key={idx}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          style={{ width: '100%', margin: '0px 0px 10px' }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                            style={{ width: '30%' }}
                            error={
                              (this.state.indexOfFilters.every(
                                (idx) => filterColumn[idx] === null,
                              ) &&
                                right.length < 1) ||
                              (filterColumn[index] !== null &&
                                columns
                                  .filter(
                                    (col) =>
                                      col.type !== 'custom' && col.type !== 'checkboxSelection',
                                  )
                                  .map((col) => col[keyToUseForColumn])
                                  .indexOf(filterColumn[index]) === -1)
                            }
                          >
                            <InputLabel id="columns-label">
                              {t('view.analysis.filterButton.columns')}
                            </InputLabel>
                            <Select
                              labelId="columns-standard-label"
                              id="columns-standard"
                              value={filterColumn[index]}
                              onChange={(event) => handleFilterColumn(event, index)}
                              label={t('view.analysis.filterButton.columns')}
                            >
                              {columns
                                .filter(
                                  (col) =>
                                    col.type !== 'custom' && col.type !== 'checkboxSelection',
                                )
                                .sort(function (a, b) {
                                  if (a.label < b.label) return -1
                                  if (a.label > b.label) return 1
                                  return 0
                                })
                                .map((column, index) => (
                                  <MenuItem key={index} value={column[keyToUseForColumn]}>
                                    {column[nameToUseForColumn]}
                                  </MenuItem>
                                ))}
                            </Select>
                            {filterColumn[index] !== null &&
                              columns
                                .filter(
                                  (col) =>
                                    col.type !== 'custom' && col.type !== 'checkboxSelection',
                                )
                                .map((col) => col[keyToUseForColumn])
                                .indexOf(filterColumn[index]) === -1 && (
                                <FormHelperText>
                                  <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                    className={classes.errorHelper}
                                  >
                                    <WarningAmberIcon
                                      style={{ fontSize: '0.8rem', marginRight: 5 }}
                                    />
                                    <Trans i18nKey="view.analysis.filterButton.helper">
                                      The column {{ val: filterColumn[index] }} isn't available
                                      here.
                                    </Trans>
                                  </Stack>
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            required
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                            style={{ width: '30%' }}
                            disabled={filterColumn[index] === null}
                            error={
                              (filterColumn[index] !== null && filterOperator[index] === null) ||
                              (filterColumn[index] !== null &&
                                columns
                                  .filter(
                                    (col) =>
                                      col.type !== 'custom' && col.type !== 'checkboxSelection',
                                  )
                                  .map((col) => col[keyToUseForColumn])
                                  .indexOf(filterColumn[index]) === -1)
                            }
                          >
                            <InputLabel id="operator-label">
                              {t('view.analysis.filterButton.operator')}
                            </InputLabel>
                            <Select
                              labelId="operator-standard-label"
                              id="operator-standard"
                              value={filterOperator[index]}
                              onChange={(event) => handleFilterOperator(event, index)}
                              label={t('view.analysis.filterButton.operator')}
                            >
                              {filterColumn[index] &&
                                (getFilterType(filterColumn[index]) === 'text' ? (
                                  [
                                    <MenuItem value={'contains'}>
                                      {t('view.analysis.filterButton.contains')}
                                    </MenuItem>,
                                    <MenuItem value={'equals'}>
                                      {t('view.analysis.filterButton.equals')}
                                    </MenuItem>,
                                    <MenuItem value={'starts'}>
                                      {t('view.analysis.filterButton.starts_with')}
                                    </MenuItem>,
                                    <MenuItem value={'ends'}>
                                      {t('view.analysis.filterButton.ends_with')}
                                    </MenuItem>,
                                    <MenuItem value={'empty'}>
                                      {t('view.analysis.filterButton.is_empty')}
                                    </MenuItem>,
                                    <MenuItem value={'notEmpty'}>
                                      {t('view.analysis.filterButton.is_not_empty')}
                                    </MenuItem>,
                                  ]
                                ) : getFilterType(filterColumn[index]) === 'number' ? (
                                  [
                                    <MenuItem value={'='}>{'='}</MenuItem>,
                                    <MenuItem value={'!='}>{'!='}</MenuItem>,
                                    <MenuItem value={'>'}>{'>'}</MenuItem>,
                                    <MenuItem value={'>='}>{'>='}</MenuItem>,
                                    <MenuItem value={'<'}>{'<'}</MenuItem>,
                                    <MenuItem value={'<='}>{'<='}</MenuItem>,
                                    <MenuItem value={'empty'}>
                                      {t('view.analysis.filterButton.is_empty')}
                                    </MenuItem>,
                                    <MenuItem value={'notEmpty'}>
                                      {t('view.analysis.filterButton.is_not_empty')}
                                    </MenuItem>,
                                  ]
                                ) : getFilterType(filterColumn[index]) === 'boolean' ? (
                                  <MenuItem value={'is'}>
                                    {t('view.analysis.filterButton.is')}
                                  </MenuItem>
                                ) : getFilterType(filterColumn[index]) === 'custom' ? (
                                  <MenuItem value={'custom'}>
                                    {t('view.analysis.filterButton.custom')}
                                  </MenuItem>
                                ) : (
                                  <span></span>
                                ))}
                            </Select>
                          </FormControl>
                          {filterColumn[index] &&
                          getFilterType(filterColumn[index]) === 'boolean' ? (
                            <FormControl
                              required
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                              style={{ width: '30%' }}
                              error={filterColumn[index] !== null && filterValue[index] === null}
                            >
                              <InputLabel id="columns-label">
                                {t('view.analysis.filterButton.value')}
                              </InputLabel>
                              <Select
                                labelId="value-standard-label"
                                id="value-standard"
                                value={filterValue[index]}
                                onChange={(event) => handleFilterValue(event, index)}
                                label={t('view.analysis.filterButton.value')}
                              >
                                <MenuItem value={'true'}>
                                  {t('view.analysis.filterButton.true')}
                                </MenuItem>
                                <MenuItem value={'false'}>
                                  {t('view.analysis.filterButton.false')}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              required
                              id="value-basic"
                              value={filterValue[index]}
                              type={
                                filterColumn[index] &&
                                getFilterType(filterColumn[index]) === 'number'
                                  ? 'number'
                                  : 'text'
                              }
                              onChange={(event) => handleFilterValue(event, index)}
                              disabled={filterColumn[index] === null}
                              error={filterColumn[index] !== null && filterValue[index] === null}
                              label={t('view.analysis.filterButton.value')}
                              variant="standard"
                              style={{ width: '30%' }}
                            />
                          )}
                          <IconButton
                            color="error"
                            size="small"
                            aria-label="delete"
                            onClick={() => deleteFilter(index)}
                            style={{ marginTop: 20 }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      ))}
                    </Stack>
                    <Button
                      color="secondary"
                      onClick={addNewFilter}
                      variant="text"
                      startIcon={<AddIcon />}
                    >
                      {t('view.analysis.filterButton.add_filter')}
                    </Button>
                  </Stack>
                  <div className={classes.colorPickerTitle} style={{ left: 26 }}>
                    {t('view.analysis.filterButton.filters')}
                  </div>
                </div>
              </div>
              <div
                style={{ width: '100%', marginTop: 30, background: 'white', position: 'relative' }}
              >
                <div
                  className={classes.colorPickerCnt}
                  style={{ width: '100%', filter: editLock ? 'blur(4px)' : 'none' }}
                >
                  {isFetching ? (
                    <div style={{ width: 512, height: 305 }}>
                      <Loading mode={'miniCircle'} />
                    </div>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      className={classes.root}
                      style={{ margin: 10 }}
                    >
                      <Grid item style={{ maxWidth: 425 }}>
                        {customList(
                          t('view.analysis.filterButton.available'),
                          applySearchFilter(left, 'left'),
                          this.searchLeftRef,
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                          >
                            &gt;
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                          >
                            &lt;
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item style={{ maxWidth: 425 }}>
                        {customList(
                          <Trans i18nKey="view.analysis.filterButton.in_the">
                            In the
                            {{
                              val: filterToEdit ? '' : ' ' + t('view.analysis.filterButton.new2'),
                            }}
                            filter
                          </Trans>,
                          applySearchFilter(right, 'right'),
                          this.searchRightRef,
                          right.length < 1 &&
                            (Object.keys(filterColumn).every(
                              (colIndex) => filterColumn[colIndex] === null,
                            ) ||
                              Object.keys(filterOperator).every(
                                (colIndex) => filterOperator[colIndex] === null,
                              ) ||
                              Object.keys(filterValue).every(
                                (colIndex) => filterValue[colIndex] === null,
                              )),
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <div className={classes.colorPickerTitle} style={{ left: 21 }}>
                    {t('view.analysis.filterButton.compound')}
                  </div>
                </div>
                {editLock && (
                  <Stack
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      color="secondary"
                      style={{ width: '80%' }}
                    >
                      {t('view.compound.compoundFilter.edit_warning')}
                    </Typography>
                    <Button
                      onClick={() => this.setState({ editLock: false })}
                      variant="outlined"
                      color="secondary"
                      startIcon={<WarningAmberIcon />}
                    >
                      {t('view.compound.compoundFilter.edit_anyway')}
                    </Button>
                  </Stack>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              {/*filterColumn: { 0: null },*/}
              {/*filterOperator: { 0: null },*/}
              {/*filterValue: { 0: null },*/}
              <Button
                disabled={
                  name === '' ||
                  filterTeams.length < 1 ||
                  (right.length < 1 &&
                    (Object.keys(filterColumn).every(
                      (colIndex) => filterColumn[colIndex] === null,
                    ) ||
                      Object.keys(filterOperator).every(
                        (colIndex) => filterOperator[colIndex] === null,
                      ) ||
                      Object.keys(filterValue).every((colIndex) => filterValue[colIndex] === null)))
                }
                onClick={(event) => handleFilterClose(event, true, filterToEdit)}
                color="primary"
              >
                {filterToEdit
                  ? t('view.analysis.filterButton.update')
                  : t('view.analysis.filterButton.save')}
              </Button>
              <Button onClick={(event) => handleFilterClose(event)} color="primary">
                {t('view.analysis.filterButton.cancel')}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Badge
          className={classes.badgeCount}
          max={10000}
          showZero={false}
          badgeContent={
            selectedFilterId && selectedFilterFromFilters?.content?.columnFilters?.length > 0 ? (
              <span style={{ marginBottom: 2 }}>
                {/*{selectedFilterFromFilters.content.compounds &&*/}
                {/*  selectedFilterFromFilters.content.compounds.length > 0 && (*/}
                {/*    <span style={{ marginRight: 5 }}>*/}
                {/*      <Molecule*/}
                {/*        style={{ width: 15, height: 15, marginRight: 2, marginBottom: -2 }}*/}
                {/*      />*/}
                {/*      {selectedFilterFromFilters.content.compounds.length}*/}
                {/*    </span>*/}
                {/*  )}*/}
                {selectedFilterFromFilters.content.columnFilters &&
                  selectedFilterFromFilters.content.columnFilters.length > 0 && (
                    <span>
                      <FilterAltIcon style={{ width: 15, height: 15, marginBottom: -2 }} />
                      {selectedFilterFromFilters.content.columnFilters.length}
                    </span>
                  )}
              </span>
            ) : (
              0
            )
          }
        >
          <Tooltip
            open={openFilterTooltip}
            onClose={handleCloseFilterTooltip}
            onOpen={handleOpenFilterTooltip}
            classes={{ tooltip: classes.tooltip }}
            arrow
            placement="right"
            disableHoverListener={!(selectedFilterFromFilters?.content?.columnFilters?.length > 0)}
            title={
              selectedFilterFromFilters?.content?.columnFilters?.length > 0 ? (
                <div style={{ fontSize: 13, padding: 5 }}>
                  <div style={{ fontSize: 15, padding: 5, marginBottom: 0 }}>
                    {t('view.analysis.filterButton.column_filter')}
                  </div>
                  <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                    {selectedFilterFromFilters.content.columnFilters.map((columnFilter, index) => (
                      <Chip
                        size="small"
                        key={index}
                        style={{
                          backgroundColor: '#ffffff2b',
                          borderColor: 'white',
                          color: 'white',
                          margin: '5px 5px 0px 0px',
                        }}
                        label={
                          columnFilter.column +
                          ' ' +
                          columnFilter.operator +
                          ' ' +
                          columnFilter.value
                        }
                        icon={<FilterAltIcon style={{ color: 'white' }} />}
                        variant="outlined"
                      />
                    ))}
                  </div>
                </div>
              ) : (
                ''
              )
            }
          >
            <FormControl
              className={clsx(
                classes.formControl,
                selectedFilterId && selectedFilterFromFilters ? classes.formControlActive : null,
              )}
            >
              <FilterListIcon className={classes.iconFilter} />
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedFilterId ? selectedFilterId : 0}
                renderValue={function () {
                  return selectedFilterId && selectedFilterFromFilters ? (
                    selectedFilterFromFilters.name
                  ) : (
                    <em>{t('view.analysis.filterButton.none')}</em>
                  )
                }}
                onChange={handleChange}
                open={openFilterSelect}
                onClose={handleCloseFilterSelect}
                onOpen={handleOpenFilterSelect}
                className={classes.formSelect}
              >
                <MenuItem key={'0'} value={'0'}>
                  <em>{t('view.analysis.filterButton.none')}</em>
                </MenuItem>
                {filters.map((filter, index) => (
                  <MenuItem key={filter._id} value={filter._id} filter={filter}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      style={{ width: '100%' }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        {filter.name}
                        {filter?.content?.compounds?.length > 0 && (
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            key={filter._id}
                            arrow
                            placement="left"
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                <div style={{ fontSize: 15, padding: 5, marginBottom: 10 }}>
                                  {t('view.analysis.filterButton.compound_not_applied')}:
                                </div>
                                <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                                  {filter.content.compounds.map((compound, index) => (
                                    <Chip
                                      key={index}
                                      style={{
                                        backgroundColor: '#ffffff2b',
                                        borderColor: 'white',
                                        color: 'white',
                                        margin: '5px 5px 0px 0px',
                                      }}
                                      label={compound.name}
                                      icon={<Molecule style={{ color: 'white' }} />}
                                      deleteIcon={
                                        compound.istd ? (
                                          <div
                                            style={{
                                              fontSize: 9,
                                              padding: '1px 4px',
                                              background: 'white',
                                              color: 'grey',
                                              borderRadius: 5,
                                              width: 25,
                                              height: 15,
                                            }}
                                          >
                                            {t('view.analysis.filterButton.istd')}
                                          </div>
                                        ) : (
                                          <span style={{ display: 'none' }} />
                                        )
                                      }
                                      variant="outlined"
                                      onDelete={() => true}
                                    />
                                  ))}
                                </div>
                              </div>
                            }
                          >
                            <span className={classes.badgeFilter} style={{ opacity: 0.4 }}>
                              <Molecule
                                style={{ width: 15, height: 15, marginRight: 5, marginTop: 1 }}
                              />
                              {filter.content.compounds.length}
                            </span>
                          </Tooltip>
                        )}
                        {filter?.content?.columnFilters?.length > 0 && (
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            key={filter._id + 'column'}
                            arrow
                            placement="left"
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                <div style={{ fontSize: 15, padding: 5, marginBottom: 10 }}>
                                  {t('view.analysis.filterButton.column_filter')}
                                </div>
                                <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                                  {filter.content.columnFilters.map((columnFilter, index) => (
                                    <Chip
                                      key={index}
                                      style={{
                                        backgroundColor: '#ffffff2b',
                                        borderColor: 'white',
                                        color: 'white',
                                        margin: '5px 5px 0px 0px',
                                      }}
                                      label={
                                        columnFilter.column +
                                        ' ' +
                                        columnFilter.operator +
                                        ' ' +
                                        columnFilter.value
                                      }
                                      icon={<FilterAltIcon style={{ color: 'white' }} />}
                                      variant="outlined"
                                    />
                                  ))}
                                </div>
                              </div>
                            }
                          >
                            <span className={classes.badgeFilter}>
                              <FilterAltIcon
                                style={{ width: 15, height: 15, marginRight: 5, marginTop: 1 }}
                              />
                              {filter.content.columnFilters.length}
                            </span>
                          </Tooltip>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.analysis.filterButton.edit2')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.editButton}
                            aria-label="edit"
                            onClick={(event) => editCustomFilter(event, filter, index + 1)}
                          >
                            <span>
                              <EditOutlinedIcon className={'editButtonIconUnactive'} />
                              <EditIcon className={'editButtonIconActive'} />
                            </span>
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.analysis.filterButton.clone')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.cloneButton}
                            aria-label="clone"
                            onClick={(event) => cloneCustomFilter(event, filter, index + 1)}
                          >
                            <span>
                              <FileCopyOutlinedIcon className={'cloneButtonIconUnactive'} />
                              <FileCopyIcon className={'cloneButtonIconActive'} />
                            </span>
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.analysis.filterButton.delete')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.deleteButton}
                            aria-label="delete"
                            onClick={(event) => deleteCustomFilter(event, filter, index + 1)}
                          >
                            <span>
                              <DeleteOutlineIcon className={'deleteButtonIconUnactive'} />
                              <DeleteIcon className={'deleteButtonIconActive'} />
                            </span>
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
                <MenuItem key={-1} value={'-1'} className={classes.addButtonMenu}>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.analysis.filterButton.add_new_filter')}
                      </div>
                    }
                  >
                    <AddBoxIcon className={classes.addButton} />
                  </Tooltip>
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Badge>
      </Fragment>
    )
  }
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(CompoundFilterButton)))
