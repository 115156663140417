import { API_BO_URL, API_URL, FLASK_URL } from '../../utils/config'
import { getBatchById, getBatchRawById } from './selectors'
import { forEach, isNil } from 'lodash'
import {
  RECEIVE_SEARCH_ANALYSES,
  receiveAnalyses,
  receiveCreateSubBatchReduceAnalyses,
  receiveMoveSampleToSubBatchReduceAnalyses,
  receiveReduceAnalyses,
  receiveSearchAnalyses,
  receiveUpdateAnalyse,
  requestChangeAnalysesType,
  REQUEST_SEARCH_ANALYSES,
  requestSearchAnalyses,
  requestUndoAnalysesType,
  receiveUpdateAnalysesFormula,
} from '../analyses/actions'
import { receiveUpdateCalibration } from '../unitaryCalibrations/actions'
import { convertErrorCodeToType } from '../tools'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { fetchChangeSettings } from '../settings/actions'
import { receiveUpdateUnitaryAnalyses } from '../unitaryAnalyses/actions'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

export const REQUEST_BATCHES = 'REQUEST_BATCHES'
export const RECEIVE_BATCHES = 'RECEIVE_BATCHES'
export const RECEIVE_BATCH = 'RECEIVE_BATCH'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const ITEM_NOT_FOUND = 'ITEM_NOT_FOUND'
export const RECEIVE_UPDATE_BATCHES = 'RECEIVE_UPDATE_BATCHES'
export const REQUEST_CREATE_SUB_BATCH = 'REQUEST_CREATE_SUB_BATCH'
export const RECEIVE_CREATE_SUB_BATCH = 'RECEIVE_CREATE_SUB_BATCH'
export const RECEIVE_MOVE_SUB_BATCH = 'RECEIVE_MOVE_SUB_BATCH'
export const RECEIVE_CHANGE_ANALYSES_TYPE = 'RECEIVE_CHANGE_ANALYSES_TYPE'
export const REQUEST_CHANGE_ANALYSES_TYPE = 'REQUEST_CHANGE_ANALYSES_TYPE'
export const RECEIVE_CREATE_TRAINING_BATCH = 'RECEIVE_CREATE_TRAINING_BATCH'
export const REQUEST_SEARCH_BATCHES = 'REQUEST_SEARCH_BATCHES'
export const RECEIVE_SEARCH_BATCHES = 'RECEIVE_SEARCH_BATCHES'
export const RECEIVE_DEVICES = 'RECEIVE_DEVICES'
export const RECEIVE_UPDATE_PRIORITY = 'RECEIVE_UPDATE_PRIORITY'
export const RECEIVE_UPDATE_NOTE = 'RECEIVE_UPDATE_NOTE'
export const POLLING_BATCHES = 'POLLING_BATCHES'
export const RECEIVE_SERVICES = 'RECEIVE_SERVICES'
export const RECEIVE_TYPES = 'RECEIVE_TYPES'
export const CLEAN_CURRENT_BATCH_LEASE = 'CLEAN_CURRENT_BATCH_LEASE'
export const RECEIVE_CURRENT_BATCH_LEASE = 'RECEIVE_CURRENT_BATCH_LEASE'
export const REQUEST_STAMP_BATCH = 'REQUEST_STAMP_BATCH'
export const RECEIVE_STAMP_BATCH = 'RECEIVE_STAMP_BATCH'
export const REQUEST_RETIRE_BATCH = 'REQUEST_RETIRE_BATCH'
export const RECEIVE_RETIRE_BATCH = 'RECEIVE_RETIRE_BATCH'
export const REQUEST_CALIBREF_BATCH = 'REQUEST_CALIBREF_BATCH'
export const RECEIVE_CALIBREF_BATCH = 'RECEIVE_CALIBREF_BATCH'
export const REQUEST_RESTORE_BATCH = 'REQUEST_RESTORE_BATCH'
export const RECEIVE_RESTORE_BATCH = 'RECEIVE_RESTORE_BATCH'

export function requestBatches(from, to, dateLock, polling) {
  return {
    type: REQUEST_BATCHES,
    from: from,
    to: to,
    dateLock: dateLock,
    polling: polling,
  }
}

export function receiveBatches(batches, authenticationError, polling, dateLock, errorReturn) {
  return {
    type: authenticationError
      ? AUTHENTICATION_FAILURE
      : polling
      ? POLLING_BATCHES
      : RECEIVE_BATCHES,
    batches: batches,
    dateLock: dateLock,
    errorReturn: errorReturn,
  }
}

export function receiveBatch(batch, authenticationError, batchNotFound) {
  return {
    type: authenticationError
      ? AUTHENTICATION_FAILURE
      : batchNotFound
      ? ITEM_NOT_FOUND
      : RECEIVE_BATCH,
    batch: batch,
  }
}

export function receiveBatchesDevices(devices, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_DEVICES,
    devices: devices,
  }
}

export function receiveBatchesServices(services, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_SERVICES,
    services: services,
  }
}

export function receiveBatchesTypes(types, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_TYPES,
    types: types,
  }
}

export function fetchBatchesServices() {
  return async (dispatch) => {
    fetch(`${API_BO_URL()}.batch/distinct/content.service_configuration`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveBatchesServices(json, false))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            return dispatch(receiveSearchAnalyses([], true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get Batch devices ',
            <Fragment>
              <div>An error does not allow the get batch services:</div>
              <div>{generateErrorPart(error)}</div>
            </Fragment>,
            5000,
          )
          return {
            status: 'KO',
            message: error.toString(),
          }
        }
      })
  }
}

export function fetchBatchesTypes() {
  return async (dispatch) => {
    fetch(`${API_BO_URL()}.batch/distinct/content.chromatography_type`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveBatchesTypes(json, false))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            return dispatch(receiveSearchAnalyses([], true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get Batch types ',
            <Fragment>
              <div>An error does not allow the get batch types:</div>
              <div>{generateErrorPart(error)}</div>
            </Fragment>,
            5000,
          )
          return {
            status: 'KO',
            message: error.toString(),
          }
        }
      })
  }
}

export function fetchBatchesDevices(backlogFilterService, backlogFilterType) {
  return async (dispatch) => {
    let filters = []
    if (backlogFilterType && backlogFilterType !== 'all') {
      filters.push({
        property: 'content.chromatography_type',
        value: backlogFilterType,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (backlogFilterService && backlogFilterService !== 'all') {
      filters.push({
        property: 'content.service_configuration',
        value: backlogFilterService,
        filterType: 'string',
        operator: 'eq',
      })
    }
    let op = encodeURIComponent(JSON.stringify(filters))

    fetch(`${API_BO_URL()}.batch/distinct/content.device/?filter=${op}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveBatchesDevices(json, false))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            return dispatch(receiveSearchAnalyses([], true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get Batch devices ',
            <Fragment>
              <div>An error does not allow the get batch devices:</div>
              {/*<div>{JSON.parse(text).message}</div>*/}
              <div>{generateErrorPart(error)}</div>
            </Fragment>,
            5000,
          )
          return {
            status: 'KO',
            message: error.toString(),
          }
        }
      })
  }
}

let controllerFetchBatchesSaved = null
export function fetchBatches(from, to, wip, service, type, device, polling) {
  return async (dispatch, getState) => {
    const { batches } = getState()

    // Abort the polling request in case of a user request is fetching
    // Because the polling will abort the user request and return 0 items
    if (polling && batches.isFetching) {
      return
    }

    if (controllerFetchBatchesSaved) {
      controllerFetchBatchesSaved.abort()
    }
    const controller = new AbortController()
    controllerFetchBatchesSaved = controller
    const dateLock = new Date().getTime()
    dispatch(requestBatches(!polling ? from : null, !polling ? to : null, dateLock, polling))

    // This case is because the quick filter is unchecked
    // So we don't use the date filter anymore and have to limit the request
    const limitIsNoDateFilter =
      from === null && to === null ? '&sort=creationDate&dir=DESC&limit=100' : ''

    const showRetired = localStorage.getItem('SETTINGS_show_retired')
      ? localStorage.getItem('SETTINGS_show_retired') === 'true'
      : false

    let filters = []
    if (!showRetired) {
      filters.push({
        property: 'content.retired',
        value: false,
        filterType: 'boolean',
        operator: 'eq',
      })
    }
    if (from && to) {
      filters.push({
        property: 'content.date_ts',
        value: from,
        filterType: 'number',
        operator: 'gte',
      })
      filters.push({
        property: 'content.date_ts',
        value: to,
        filterType: 'number',
        operator: 'lte',
      })
    }
    if (wip) {
      filters.push({
        property: 'content.analyses_progression_requested',
        value: 100,
        filterType: 'number',
        operator: 'lt',
      })
      filters.push({
        property: 'content.calibration_progression_requested',
        value: 100,
        filterType: 'number',
        operator: 'lt',
      })
    }
    if (service && service !== 'all') {
      filters.push({
        property: 'content.service_configuration',
        value: service,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (type && type !== 'all') {
      filters.push({
        property: 'content.chromatography_type',
        value: type,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (device && device !== 'all') {
      filters.push({
        property: 'content.device',
        value: device,
        filterType: 'string',
        operator: 'eq',
      })
    }
    // save the filters before update them with modification date
    let op = encodeURIComponent(JSON.stringify(filters))
    let count = 1
    let updatedIds = []
    if (polling) {
      count = 0
      filters.push({
        property: 'modificationDate',
        value: batches.lastModificationDate,
        filterType: 'number',
        operator: 'gt',
      })
      let opFil = encodeURIComponent(JSON.stringify(filters))
      // const response = await fetch(`${API_BO_URL()}.batch/distinct/location?filter=${opFil}`, {
      const response = await fetch(
        `${API_BO_URL()}.batch/page/?filter=${opFil}&include=name&include=modificationDate${limitIsNoDateFilter}`,
        {
          signal: controller.signal,
          method: 'GET',
          credentials: 'include',
        },
      )
        .then(function (response) {
          if (response.ok) {
            return response
          }
          if (response.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            throw new Error("You're not logged.")
          }
          return response.text().then((text) => {
            throw JSON.parse(text).message
          })
        })
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          // Object.keys(json).forEach((key) => (count = count + json[key].count))
          count = json.result.length
          if (batches.lastModificationDate !== 0) {
            updatedIds = json.result.map((j) => j._id)
          }
        })
        .catch(function (error) {
          // Do not do anything in case of an aborted request by a new one
          // if (error.name === 'AbortError') {
          //   console.warn('Fetch aborted')
          //   return
          // }
          // Do not do anything in case of an aborted request by a new one
          if (!(error instanceof DOMException && error.code === 20)) {
            displayPopupMessage(
              dispatch,
              'error',
              'Failed to count new Batches ',
              <Fragment>
                <div>An error does not allow the count new batches:</div>
                {/*<div>{JSON.parse(text).message}</div>*/}
                <div>{error.toString()}</div>
              </Fragment>,
              5000,
            )
          }
        })
        .finally(() => {
          if (controllerFetchBatchesSaved === controller) {
            controllerFetchBatchesSaved = null
          }
          controller.abort()
        })
    }
    // If count > 0 it's because there is a new batch or a new update of a batch (only for the polling)
    // count > 0 if it's a new user request too
    if (count > 0) {
      const response = await fetch(
        `${API_BO_URL()}.batch/page/?filter=${op}${limitIsNoDateFilter}`,
        // const response = await fetch(
        //   `${API_BO_URL()}.batch/page/?filter=${op}&include=modificationDate&include=name&include=content.date_ts&include=content.priority&include=content.device&include=content.category&include=content.version`,
        {
          signal: controller.signal,
          method: 'GET',
          credentials: 'include',
        },
      )
        .then(function (response) {
          if (response.ok) {
            return response
          }
          if (response.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            throw new Error("You're not logged.")
          }
          return response.text().then((text) => {
            throw JSON.parse(text).message
          })
        })
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          if (json.size > 100) {
            displayPopupMessage(
              dispatch,
              'info',
              t('view.backlog.info_limit.title'),
              <Fragment>
                <Trans i18nKey="view.backlog.info_limit.msg1">
                  The complete database list contains '{{ val: json.size }}' records.
                </Trans>
                <div>{t('view.backlog.info_limit.msg2')}</div>
                <div style={{ marginTop: 10 }}>{t('view.backlog.info_limit.msg3')}</div>
              </Fragment>,
            )
          }
          return dispatch(
            receiveBatches(
              json.result.map(function (j) {
                if (updatedIds.indexOf(j._id) !== -1) {
                  return { ...j, newUpdated: true }
                } else {
                  return j
                }
              }),
              false,
              polling,
              dateLock,
            ),
          )
        })
        .catch(function (error) {
          // Do not do anything in case of an aborted request by a new one
          if (error.name === 'AbortError') {
            console.warn('Fetch aborted')
            return
          }
          // return error.text().then((text) => {
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get Batches ',
            <Fragment>
              <div>An error does not allow the get batches:</div>
              {/*<div>{JSON.parse(text).message}</div>*/}
              <div>{error.toString()}</div>
            </Fragment>,
            5000,
          )
          return dispatch(receiveBatches([], false, polling, null, true))
          // })
        })
        .finally(() => {
          if (controllerFetchBatchesSaved === controller) {
            controllerFetchBatchesSaved = null
          }
          controller.abort()
        })
      // const json = await response.json()
      // const authenticationError = response.status === 403
      // return dispatch(receiveBatches(json.result, authenticationError, polling))
    }
  }
}

export function fetchBatch(batchId) {
  return async (dispatch) => {
    dispatch(requestBatches())
    return fetch(`${API_BO_URL()}.batch/${batchId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveBatch(json, false, false))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get Batch ',
            <Fragment>
              <div>An error does not allow the batch to be recovered:</div>
              <div>{generateErrorPart(error)}</div>
              <div style={{ marginTop: 10 }}>
                Try to reload with F5, if the problem persists, please contact your administrator.
              </div>
            </Fragment>,
            10000,
          )
          return dispatch(receiveBatch(null, false, true))
        }
      })
  }
}

export function receiveUpdateBatches(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_BATCHES,
    objectsUpdated: json,
  }
}

export function fetchCreateSubBatch(batchId, batchName, selection, option) {
  const analysesId = selection.map((sp) => sp._id)
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['new_batch_name'] = batchName
      data['analyses'] = analysesId
      data['option'] = option

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      fetch(`${FLASK_URL()}/batch/${batchId}/create_subbatch`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update analyses store in case of move to remove old analysis from origin batch
          if (option === 'move') {
            dispatch(receiveCreateSubBatchReduceAnalyses(json, selection, option, batchId, false))
          }
          resolve(dispatch(receiveCreateSubBatch(json, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveCreateSubBatch(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_CREATE_SUB_BATCH,
    objectsUpdated: json,
  }
}

export function fetchMoveSampleToSubBatch(dest_batch_id, selection, option, batchId) {
  const analysesId = selection.map((sp) => sp._id)
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['dest_batch_id'] = dest_batch_id
      data['analyses'] = analysesId
      data['option'] = option

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }

      fetch(`${FLASK_URL()}/batch/${dest_batch_id}/transfer`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update analyses store to remove old analysis from origin batch in case of move
          // Update analyses store to add new analyses in destination batch
          dispatch(
            receiveMoveSampleToSubBatchReduceAnalyses(
              json,
              selection,
              option,
              batchId,
              dest_batch_id,
              false,
            ),
          )
          resolve(dispatch(receiveMoveSampleToSubBatch(json, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveMoveSampleToSubBatch(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_MOVE_SUB_BATCH,
    objectsUpdated: json,
  }
}

export function fetchChangeAnalysesType(batch_id, type, data, selection) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestChangeAnalysesType(selection))
      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batch_id, analysis_id: null, body: data }),
      }
      fetch(`${FLASK_URL()}/batch/${batch_id}/analyses_type`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          // Update analyses
          dispatch(receiveUpdateAnalyse(json, false))
          // Update calibration table
          dispatch(receiveUpdateCalibration(json, '', false))
          resolve(dispatch(receiveChangeAnalysesType(json, false)))
        })
        .catch((error) => {
          dispatch(requestUndoAnalysesType(selection))
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
function receiveChangeAnalysesType(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_CHANGE_ANALYSES_TYPE,
    objectsUpdated: json,
  }
}

export function fetchCreateTrainingBatch(batchId, trainingBatchName) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const url = `${FLASK_URL()}/batch/${batchId}/training`
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          batch_id: batchId,
          analysis_id: null,
          body: {
            new_batch_name: trainingBatchName,
          },
        }),
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          resolve(dispatch(receiveCreateTrainingBatch(data.result)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveCreateTrainingBatch(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_CREATE_TRAINING_BATCH,
    trainingBatch: json,
  }
}

// let controllerSearchBatchesSaved = null
export function searchBatches(keyword, filterService, filterType, filterConfiguration) {
  return async (dispatch) => {
    // if (controllerSearchBatchesSaved) {
    //   controllerSearchBatchesSaved.abort()
    // }
    // const controller = new AbortController()
    // controllerSearchBatchesSaved = controller
    const dateLock = new Date().getTime()
    dispatch(requestSearchBatches(keyword, dateLock))
    const showRetired = localStorage.getItem('SETTINGS_show_retired')
      ? localStorage.getItem('SETTINGS_show_retired') === 'true'
      : false
    const includes =
      '&include=' +
      [
        '_id',
        'name',
        'content.batch.name',
        'content.batch.id',
        'content.type',
        'content.file',
        'content.folder',
        'content.date_ts',
        'content.retired',
        'content.configuration',
      ].join('&include=')
    const p1 = new Promise((resolve, reject) => {
      let filterName = [
        {
          property: 'name',
          value: keyword,
          filterType: 'string',
          operator: 'like',
        },
      ]
      if (!showRetired) {
        filterName.push({
          property: 'content.retired',
          value: false,
          filterType: 'boolean',
          operator: 'eq',
        })
      }
      if (filterType) {
        filterName.push({
          property: 'content.chromatography_type',
          value: filterType,
          filterType: 'string',
          operator: 'eq',
        })
      }
      if (filterConfiguration) {
        filterName.push({
          property: 'content.configuration',
          value: filterConfiguration,
          filterType: 'string',
          operator: 'eq',
        })
      }
      let n = encodeURIComponent(JSON.stringify(filterName))
      fetch(`${API_BO_URL()}.batch/page/?filter=${n}${includes}`, {
        // signal: controller.signal,
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
    const p2 = new Promise((resolve, reject) => {
      let filterPath = [
        {
          property: 'content.folder',
          value: keyword,
          filterType: 'string',
          operator: 'like',
        },
      ]
      if (!showRetired) {
        filterPath.push({
          property: 'content.retired',
          value: false,
          filterType: 'boolean',
          operator: 'eq',
        })
      }
      if (filterType) {
        filterPath.push({
          property: 'content.chromatography_type',
          value: filterType,
          filterType: 'string',
          operator: 'eq',
        })
      }
      if (filterConfiguration) {
        filterPath.push({
          property: 'content.configuration',
          value: filterConfiguration,
          filterType: 'string',
          operator: 'eq',
        })
      }
      let p = encodeURIComponent(JSON.stringify(filterPath))
      fetch(`${API_BO_URL()}.batch/page/?filter=${p}${includes}`, {
        // signal: controller.signal,
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })

    Promise.all([p1, p2]).then(
      function (returns) {
        const jsonName = returns[0]
        const jsonPath = returns[1]
        // remove duplicate
        const json = jsonPath.result.concat(
          jsonName.result.filter(
            (item) => jsonPath.result.map((path) => path._id).indexOf(item._id) < 0,
          ),
        )
        return dispatch(receiveSearchBatches(json, false, dateLock))
      },
      function (error) {
        // Do not do anything in case of an aborted request by a new one
        // if (!(error instanceof DOMException && error.code === 20)) {
        dispatch(receiveSearchBatches([], false))
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get batches',
          <Fragment>
            <div>An error does not allow the get batches searched:</div>
            <div>{generateErrorPart(error)}</div>
          </Fragment>,
        )
        // }
      },
    )
    // .then(() => {
    //   if (controllerSearchBatchesSaved === controller) {
    //     controllerSearchBatchesSaved = null
    //   }
    //   controller.abort()
    // })
  }
}
export function requestSearchBatches(keyword, dateLock) {
  return {
    type: REQUEST_SEARCH_BATCHES,
    keyword: keyword,
    dateLock: dateLock,
  }
}

export function receiveSearchBatches(batches, authenticationError, dateLock) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_SEARCH_BATCHES,
    searchedBatches: batches,
    dateLock: dateLock,
  }
}

export function fetchUpdatePriority_PATCH(batch, priority) {
  return async (dispatch) => {
    let operation = [
      {
        op: 'replace',
        key: 'content.priority',
        value: priority,
      },
    ]
    try {
      const response = await fetch(`${API_BO_URL()}.batch/${batch._id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(operation),
      })
      const authenticationError = response.status === 403
      if (authenticationError) {
        return dispatch(fetchChangeSettings('loginPopup', true))
      }
      const json = await response.json()
      return dispatch(receiveUpdatePriority(json, authenticationError))
    } catch (e) {
      console.log(e)
      return dispatch(receiveUpdatePriority([], false, e))
    }
  }
}

export function fetchUpdatePriority(updateBatch, priority) {
  return async (dispatch, getState) => {
    const { batches } = getState()
    let searchBatch = batches.items.filter((batch) => updateBatch._id === batch._id)[0]
    if (searchBatch) {
      const patch_json = [
        {
          op: 'replace',
          key: 'content.priority',
          value: priority,
        },
      ]
      // try {
      //   const response = await fetch(`${API_BO_URL()}.batch/${updateBatch._id}`, {
      //     method: 'PATCH',
      //     credentials: 'include',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify(patch_json),
      //   })
      //   // const json = await response.json()
      //   searchBatch.content.priority = priority
      //   const authenticationError = response.status === 403
      //   if (authenticationError) {
      //     return dispatch(fetchChangeSettings('loginPopup', true))
      //   }
      //   return dispatch(receiveUpdatePriority(searchBatch, authenticationError))
      // } catch (e) {
      //   console.log(e)
      //   return dispatch(receiveUpdatePriority([], false, e))
      // }
      return new Promise((resolve, reject) => {
        fetch(`${API_BO_URL()}.batch/${updateBatch._id}`, {
          method: 'PATCH',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(patch_json),
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((data) => {
            searchBatch.content.priority = priority
            dispatch(receiveUpdatePriority(searchBatch, false))
            resolve(data)
          })
          .catch((error) => {
            const authenticationError = error.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            dispatch(receiveUpdatePriority([], authenticationError, error))
            reject(error)
          })
      })
    }
  }
}

export function receiveUpdatePriority(batch, authenticationError, error) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_PRIORITY,
    updatedBatch: batch,
    error: error,
  }
}

export function fetchAddBatchNote(updateBatch, notes) {
  return async (dispatch) => {
    let fullRaw = await getBatchRawById(updateBatch._id, dispatch)
    if (fullRaw) {
      let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
      dataToUpdate.content.notes = notes
      try {
        const response = await fetch(`${API_BO_URL()}.batch/${updateBatch._id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToUpdate),
        })
        const error = response.status !== 200
        const authenticationError = response.status === 403
        if (authenticationError) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        const json = await response.json()
        if (error) {
          console.log(response.statusText)
          return dispatch(
            receiveUpdateNote([], convertErrorCodeToType(response.status), response.statusText),
          )
        } else {
          return dispatch(receiveUpdateNote(json, false))
        }
      } catch (e) {
        console.log(e)
        return dispatch(receiveUpdateNote([], convertErrorCodeToType(), e))
      }
    }
  }
}

export function fetchRemoveBatchNote(batch, note) {
  return async (dispatch, getState) => {
    const { batches } = getState()
    // Get the same object of the note to get the good index
    const data = getBatchById(batches, batch._id)

    let fullRaw = await getBatchRawById(batch._id, dispatch)
    if (fullRaw && data) {
      let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
      let index = -1
      index = data.content.notes.indexOf(note)
      if (index > -1) {
        dataToUpdate.content.notes.splice(index, 1)
        try {
          const response = await fetch(`${API_BO_URL()}.batch/${batch._id}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataToUpdate),
          })
          const authenticationError = response.status === 403
          if (authenticationError) {
            return dispatch(fetchChangeSettings('loginPopup', true))
          }
          const json = await response.json()
          const error = response.status !== 200
          if (error) {
            console.log(response.statusText)
            return dispatch(
              receiveUpdateNote([], convertErrorCodeToType(response.status), response.statusText),
            )
          } else {
            return dispatch(receiveUpdateNote(json, false))
          }
        } catch (e) {
          console.log(e)
          return dispatch(receiveUpdateNote([], convertErrorCodeToType(), e))
        }
      } else {
        return dispatch(
          receiveUpdateNote(
            [],
            convertErrorCodeToType(),
            'The note to removed was not founded in the Batch',
          ),
        )
      }
    }
  }
}

export function receiveUpdateNote(batch, typeError, error) {
  return {
    type: typeError ? typeError : RECEIVE_UPDATE_NOTE,
    updatedBatch: batch,
    error: error,
  }
}

export function fetchCurrentBatchLease(batchId, cleanBefore) {
  return async (dispatch) => {
    if (cleanBefore) {
      dispatch(cleanCurrentBatche())
    }
    return fetch(`${API_BO_URL()}.batch/${batchId}/?include=_id&include=content.lease`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveCurrentBatchLease(json, false, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
          return dispatch(receiveCurrentBatchLease(null, true, false))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Batch lease',
          <Fragment>
            <div>An error does not allow the batch lease to be recovered:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>
              Try to reload with F5, if the problem persists, please contact your administrator.
            </div>
          </Fragment>,
        )
        if (error.status === 204) {
          return dispatch(receiveCurrentBatchLease(null, false, true))
        }
      })
  }
}

export function cleanCurrentBatche() {
  return {
    type: CLEAN_CURRENT_BATCH_LEASE,
  }
}

export function receiveCurrentBatchLease(json, authenticationError, batchNotFound) {
  return {
    type: authenticationError
      ? AUTHENTICATION_FAILURE
      : batchNotFound
      ? ITEM_NOT_FOUND
      : RECEIVE_CURRENT_BATCH_LEASE,
    currentBatchLease:
      !authenticationError && !batchNotFound && json
        ? json.content.lease && typeof json.content.lease === 'number'
          ? { date: json.content.lease, operator: null, reason: null }
          : json.content.lease &&
            json.content.lease !== null &&
            json.content.lease !== undefined &&
            typeof json.content.lease === 'object'
          ? json.content.lease
          : { date: 0, operator: null, reason: null }
        : null,
  }
}

export function fetchStampBatch(batchId) {
  return async (dispatch) => {
    dispatch(requestStampBatch())
    return new Promise((resolve, reject) => {
      fetch(`${FLASK_URL()}/${batchId}/stamp`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: {} }),
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(dispatch(receiveStampBatch(json)))
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to stamp the batch',
            <Fragment>
              <div>An error does not allow to stamp the batch:</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
          reject(error)
        })
    })
  }
}

export function requestStampBatch() {
  return {
    type: REQUEST_STAMP_BATCH,
  }
}

export function receiveStampBatch(batch) {
  return {
    type: RECEIVE_STAMP_BATCH,
    stampedBatch: batch,
  }
}

export function fetchRetireBatch(batchId) {
  return async (dispatch) => {
    dispatch(requestRetireBatch())
    return new Promise((resolve, reject) => {
      fetch(`${FLASK_URL()}/batch/${batchId}/retire`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: {} }),
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(dispatch(receiveRetireBatch(json?.batch)))
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to retire the batch',
            <Fragment>
              <div>An error does not allow to retire the batch:</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
          reject(error)
        })
    })
  }
}

export function requestRetireBatch() {
  return {
    type: REQUEST_RETIRE_BATCH,
  }
}

export function receiveRetireBatch(batch) {
  return {
    type: RECEIVE_RETIRE_BATCH,
    retiredBatch: batch,
  }
}

export function fetchSetAsCalibRefBatch(batchId) {
  return async (dispatch) => {
    dispatch(requestCalibRefBatch())
    return new Promise((resolve, reject) => {
      fetch(`${FLASK_URL()}/batch/${batchId}/calibration_reference`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: {} }),
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(dispatch(receiveCalibRefBatches(json)))
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to set the batch as calibration reference',
            <Fragment>
              <div>An error does not allow to set the batch as a calibration reference:</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
          reject(error)
        })
    })
  }
}

export function requestCalibRefBatch() {
  return {
    type: REQUEST_CALIBREF_BATCH,
  }
}

export function receiveCalibRefBatches(json) {
  return {
    type: RECEIVE_CALIBREF_BATCH,
    calibRefBatches: json?.batches_modified,
  }
}

export function fetchBatchRestore(batchToRestore) {
  return async (dispatch) => {
    dispatch(requestRestoreBatch())
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', batchToRestore)
      fetch(`${FLASK_URL()}/upload-cod-batch-archive`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((restoredBatch) => {
          resolve(dispatch(receiveRestoreBatch(restoredBatch)))
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function requestRestoreBatch() {
  return {
    type: REQUEST_RESTORE_BATCH,
  }
}

export function receiveRestoreBatch(restoredBatch) {
  return {
    type: RECEIVE_RESTORE_BATCH,
    restoredBatch: restoredBatch,
  }
}
