import React, { Component, Fragment, useEffect, useMemo, useRef } from 'react'
import { searchAnalyses } from '../../redux/analyses/actions'
import { fetchBatches, fetchCurrentBatchLease, searchBatches } from '../../redux/batches/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGetTask, fetchGetToolbarTasks } from '../../redux/tasks/actions'
import { getTimeEndDay, getTimeStartDay } from './Utils'
import Dashboard from '../Dashboard'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { fetchGetLogs } from '../../redux/logs/actions'
import { DATABASE } from '../../utils/config'

const TASKS_POLLING_DEFAULT = 10000
const TASK_POLLING_DEFAULT = 1000

// export function usePolling(callback, originDelay) {
//   const savedCallback = useRef()
//   const dispatch = useDispatch()
//
//   const [delay, setDelay] = React.useState(originDelay)
//
//   // Remember the latest function.
//   useEffect(() => {
//     savedCallback.current = callback
//   }, [callback])
//
//   // Set up the interval.
//   useEffect(() => {
//     function tick() {
//       setDelay(null)
//       dispatch(savedCallback.current()).then(() => setDelay(originDelay))
//     }
//     if (delay !== null) {
//       let id = setInterval(tick, delay)
//       return () => clearInterval(id)
//     }
//   }, [delay])
// }

const Polling = withRouter((props) => {
  const dispatch = useDispatch()
  const originDelay = localStorage.getItem('SETTINGS_task_polling')
    ? parseFloat(localStorage.getItem('SETTINGS_task_polling'))
    : TASKS_POLLING_DEFAULT

  const [delayTask, setDelayTask] = React.useState(TASK_POLLING_DEFAULT)
  const [delayTasks, setDelayTasks] = React.useState(originDelay)
  const [delayBatches, setDelayBatches] = React.useState(originDelay)
  const [delayBatchLease, setDelayBatchLease] = React.useState(originDelay)
  const [delayLogs, setDelayLogs] = React.useState(
    1000 *
      (localStorage.getItem('LOG_polling') ? parseFloat(localStorage.getItem('LOG_polling')) : 30),
  )

  const logsOpen = useSelector((state) => state.settings.logsPopup)
  const taskId = useSelector((state) => state.settings.taskId)
  const task = useSelector((state) => state.tasks.task)
  const errorLogin = useSelector((state) => state.settings.loginPopup)
  const errorService = useSelector((state) => state.settings.configurationService)
  const errorDatabase = useSelector((state) => state.settings.configurationDatabase.length > 0)
  const errorConfig = useMemo(() => {
    return errorLogin || errorService || errorDatabase
  }, [errorLogin, errorService, errorDatabase])

  // Set up the interval for tasks.
  useEffect(() => {
    function tickTask() {
      if (props.history.location.pathname.indexOf(`/${DATABASE()}/logs`) !== 0) {
        setDelayTasks(null)
        // If not logged or bad service or bad DB, bypass the polling
        if (errorConfig) {
          setDelayTasks(originDelay)
        } else {
          dispatch(fetchGetToolbarTasks()).then(() => setDelayTasks(originDelay))
        }
      }
    }

    if (delayTasks !== null) {
      let idT = setInterval(tickTask, delayTasks)
      return () => clearInterval(idT)
    }
  }, [delayTasks, dispatch, originDelay, errorConfig])

  // Set up the interval for current batch lease.
  useEffect(() => {
    function tickBatchLease() {
      if (
        props.history.location.pathname !== `/${DATABASE()}` &&
        props.history.location.pathname.indexOf(`/${DATABASE()}/logs`) !== 0
      ) {
        const batchId = props.location.pathname
          .replace(`/${DATABASE()}/batch`, '')
          .replace(`/${DATABASE()}/global`, '')
          .split('/')[1]
        if (batchId && batchId !== '') {
          setDelayBatchLease(null)
          // If not logged or bad service or bad DB, bypass the polling
          if (errorConfig) {
            setDelayBatchLease(originDelay)
          } else {
            dispatch(fetchCurrentBatchLease(batchId)).then(() => setDelayBatchLease(originDelay))
          }
        } else {
          setDelayBatchLease(originDelay)
        }
      }
    }

    if (delayBatchLease !== null) {
      let idT = setInterval(tickBatchLease, delayBatchLease)
      return () => clearInterval(idT)
    }
  }, [delayBatchLease, dispatch, originDelay, errorConfig])

  // Set up the interval for batches.
  useEffect(() => {
    function tickBatches() {
      if (props.history.location.pathname === `/${DATABASE()}`) {
        setDelayBatches(null)
        const selectedFromDate = localStorage.getItem('BACKLOG_filter_fromDate')
          ? new Date(localStorage.getItem('BACKLOG_filter_fromDate'))
          : new Date(getTimeStartDay() - 604800000)

        const selectedToDate = localStorage.getItem('BACKLOG_filter_toDate')
          ? new Date(localStorage.getItem('BACKLOG_filter_toDate'))
          : new Date(getTimeEndDay())
        const backlogFilterService = localStorage.getItem('BACKLOG_filter_service')
          ? localStorage.getItem('BACKLOG_filter_service')
          : 'all'
        const backlogFilterType = localStorage.getItem('BACKLOG_filter_type')
          ? localStorage.getItem('BACKLOG_filter_type')
          : 'all'
        const backlogFilterDevice = localStorage.getItem('BACKLOG_filter_device')
          ? localStorage.getItem('BACKLOG_filter_device')
          : 'all'

        const wipChecked = localStorage.getItem('BACKLOG_filter_wip')
          ? JSON.parse(localStorage.getItem('BACKLOG_filter_wip')) === true
          : false

        const dateFilterActive = localStorage.getItem('BATCH_activeDateFilter')
          ? localStorage.getItem('BATCH_activeDateFilter') === 'true'
          : false

        // If not logged or bad service or bad DB, bypass the polling
        if (errorConfig) {
          setDelayBatches(originDelay)
        } else {
          dispatch(
            fetchBatches(
              dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
              dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
              wipChecked,
              backlogFilterService,
              backlogFilterType,
              backlogFilterDevice,
              true,
            ),
          ).then(() => setDelayBatches(originDelay))
        }
      }
    }

    if (delayBatches !== null) {
      let idB = setInterval(tickBatches, delayBatches)
      return () => clearInterval(idB)
    }
  }, [delayBatches, dispatch, originDelay, props.history.location.pathname, errorConfig])

  // Set up the interval for upload task.
  useEffect(() => {
    function tickTask() {
      if (
        taskId &&
        (!task || (task && ['FINISHED', 'FAILED', 'CANCELLED'].indexOf(task.status) === -1))
      ) {
        setDelayTask(null)
        // If not logged or bad service or bad DB, bypass the polling
        if (errorConfig) {
          setDelayTask(TASKS_POLLING_DEFAULT)
        } else {
          dispatch(fetchGetTask(taskId)).then(() => setDelayTask(TASKS_POLLING_DEFAULT))
        }
      }
    }

    if (delayTask !== null) {
      let idT = setInterval(tickTask, delayTask)
      return () => clearInterval(idT)
    }
  }, [delayTask, dispatch, taskId, task, errorConfig])

  const [logsInterval, setLogsInterval] = React.useState(null)
  // Set up the interval for current batch lease.
  useEffect(() => {
    function getDelayLog() {
      return (
        1000 *
        (localStorage.getItem('LOG_polling') ? parseFloat(localStorage.getItem('LOG_polling')) : 30)
      )
    }
    function tickLogs() {
      if (logsOpen) {
        setDelayLogs(null)
        // If not logged or bad service or bad DB, bypass the polling
        if (errorConfig) {
          setDelayLogs(delayLogs)
        } else {
          dispatch(fetchGetLogs(true)).then(() => setDelayLogs(getDelayLog()))
        }
      } else {
        setDelayLogs(getDelayLog())
      }
    }
    if (delayLogs !== null && logsOpen) {
      let idL = setInterval(tickLogs, getDelayLog())
      setLogsInterval(idL)
      return () => clearInterval(idL)
    } else if (logsInterval !== null) {
      clearInterval(logsInterval)
    }
  }, [dispatch, delayLogs, errorConfig, logsOpen])

  return <Fragment />
})

export default Polling
