import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  RECEIVE_CREATE_FILTER,
  RECEIVE_DELETE_FILTER,
} from './actions'
import React from 'react'

const initialState = {
  deviceType: '',
  isFetchingFilters: false,
  filters: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_FILTERS:
      return {
        ...state,
        deviceType: action.deviceType,
        isFetchingFilters: true,
      }
    case RECEIVE_FILTERS:
      return {
        ...state,
        deviceType: '',
        isFetchingFilters: false,
        filters: action.filters,
      }
    case RECEIVE_CREATE_FILTER:
      let updatedFiltersCreate = state.filters
      if (action.editId) {
        updatedFiltersCreate = updatedFiltersCreate.filter((filter) => filter._id !== action.editId)
      }
      updatedFiltersCreate = [...updatedFiltersCreate, action.newFilter]
      return {
        ...state,
        filters: updatedFiltersCreate,
      }
    case RECEIVE_DELETE_FILTER:
      const filterId = action.filterToDeleteId
      const updatedFiltersDelete = state.filters.filter((filter) => filter._id !== filterId)
      return {
        ...state,
        filters: updatedFiltersDelete,
      }
    default:
      return state
  }
}
