import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React, { Fragment, useEffect, useMemo } from 'react'
import {
  fetchAllUnitaryAnalysesForBatch,
  fetchTerminateTheWorker,
} from '../../redux/prefetch/actions'
import {
  CACHE_IS_USED,
  DATABASE,
  DEFAULT_CACHE_MAX_SIZE,
  DEFAULT_CACHE_POLLING,
  GET_LAST_CACHE_MODIFICATION,
  PREFETCH_LIMIT,
} from '../../utils/config'
import { addObjectsToCacheInWorker } from '../../redux/indexedDbUtils'

const Prefetch = withRouter((props) => {
  const dispatch = useDispatch()
  const cachePrefetching = localStorage.getItem('SETTINGS_cache_prefetch') === 'true'
  const path = props.location.pathname

  const batchId = useMemo(() => {
    // Extract the batchId from URL
    const match = path.match(
      `^/${DATABASE()}/(?:batch/|global/|)([a-zA-Z0-9]*)(?:/(analysis|compound)/([a-zA-Z0-9]*))?(?:/([a-zA-Z0-9]*))?$`,
    )
    return match && match !== '' && match[1] !== 'reload' ? match[1] : null
  }, [path])

  const maxCacheSize =
    (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE) *
    1024 *
    1024 // 100MB
  const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'

  function getPrefetchPolling() {
    return (
      (parseInt(localStorage.getItem('SETTINGS_cache_polling'), 10) || DEFAULT_CACHE_POLLING) * 1000
    )
  }

  // useEffect(() => {
  //   if (batchId && cachePrefetching) {
  //     let numbersObjectsDownloaded = 0
  //     // Start the prefetching
  //     dispatch(fetchAllUnitaryAnalysesForBatch(batchId)).then((resp) => {
  //       numbersObjectsDownloaded = resp.numberDownloaded
  //     })
  //   } else {
  //     // Stop the prefetching
  //   }
  // }, [batchId, cachePrefetching])

  const fetchData = (batchId, isActiveRef) => {
    // Vérifie si la récupération est toujours active
    if (!isActiveRef.current) return
    dispatch(fetchAllUnitaryAnalysesForBatch(batchId))
      .then((numbersObjectsDownloaded) => {
        if (numbersObjectsDownloaded === PREFETCH_LIMIT()) {
          // Continue fetching immediately
          if (isActiveRef.current) fetchData(batchId, isActiveRef)
        } else if (numbersObjectsDownloaded < PREFETCH_LIMIT()) {
          // Test again after 10 seconds
          setTimeout(function () {
            if (isActiveRef.current) fetchData(batchId, isActiveRef)
          }, getPrefetchPolling())
        }
      })
      .catch(() => {
        setTimeout(function () {
          if (isActiveRef.current) fetchData(batchId, isActiveRef)
        }, getPrefetchPolling())
      })
  }

  useEffect(() => {
    // Flag pour contrôler si la boucle doit continuer
    const isActiveRef = { current: true }
    if (batchId && cachePrefetching && CACHE_IS_USED()) {
      // In case of the batch changed, we have to reset the lastModification index
      if (batchId !== GET_LAST_CACHE_MODIFICATION()?.['batchId']) {
        localStorage.setItem(
          'CACHE_last_modificationDate',
          JSON.stringify({
            modificationDate: 0,
            batchId: batchId,
          }),
        )
      }
      fetchData(batchId, isActiveRef)
    } else {
      isActiveRef.current = false
      // Stop the prefetching
      // dispatch(fetchTerminateTheWorker())
    }
    return () => {
      // Cleanup on unmount
      // Lorsque le composant est démonté ou que batchId change, on arrête la récupération
      isActiveRef.current = false
    }
  }, [batchId, cachePrefetching])

  // Test with the getting inside web worker, coes not work cause cookies
  // useEffect(() => {
  //   let intervalId
  //
  //   const fetchData = async () => {
  //     try {
  //       const numberDownloaded = await addObjectsToCacheInWorker(batchId, maxCacheSize, cachePolicy)
  //       // dispatch(fetchAllUnitaryAnalysesForBatch(batchId))
  //
  //       if (numberDownloaded === PREFETCH_LIMIT) {
  //         // Continue fetching immédiatement
  //         await fetchData()
  //       } else if (numberDownloaded < PREFETCH_LIMIT) {
  //         // Recommence après 10 secondes
  //         setTimeout(fetchData, 10000)
  //       }
  //
  //       // Dispatcher une action synchrone avec le résultat final
  //       dispatch({
  //         type: 'PREFETCH_SUCCESS',
  //         payload: {
  //           batchId,
  //           numberDownloaded,
  //         },
  //       })
  //     } catch (error) {
  //       // Gérer les erreurs ici et relancer après un délai
  //       console.error('Error during prefetch:', error)
  //       setTimeout(fetchData, 10000)
  //
  //       // Dispatcher une action synchrone pour l'erreur
  //       dispatch({
  //         type: 'PREFETCH_ERROR',
  //         error,
  //       })
  //     }
  //   }
  //
  //   if (batchId && cachePrefetching && CACHE_IS_USED()) {
  //     fetchData()
  //   } else {
  //     // Stop prefetching
  //     dispatch({
  //       type: 'PREFETCH_TERMINATE',
  //     })
  //   }
  //
  //   return () => {
  //     // Cleanup on unmount
  //     if (intervalId) clearInterval(intervalId)
  //   }
  // }, [batchId, cachePrefetching])
  return <Fragment />
})
export default Prefetch
