import Menu from '@material-ui/core/Menu'
import NewMenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { isClippyActivated, tellMeMoreImg } from '../Utils'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import ListItemText from '@mui/material/ListItemText'
import { t } from 'i18next'
import Divider from '@mui/material/Divider'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React, { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { fetchDisplayDetail } from '../../../redux/detail/actions'
import { DATABASE } from '../../../utils/config'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
}))

export default function ContextMenu({
  unitaryAnalyse,
  onClose,
  contextMenu,
  aiAssistantActivated,
  chartRef,
  collection,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)
  const match = useRouteMatch()

  const aiActivated = useMemo(() => aiAssistantActivated, [aiAssistantActivated])
  const unitaryAnalysesGlobal = useMemo(() => unitaryAnalyse, [unitaryAnalyse])
  // const externalModeActive = useSelector(
  //   (state) => typeof state.detail.externalMode === 'boolean' && state.detail.externalMode,
  // )
  // const externalUaIDDisplayed = useSelector((state) => state.detail.detail.item?._id)

  function handleCloseContextMenu() {
    onClose()
  }

  function handlerTellMeMore(ua) {
    if (chartRef?.current) {
      const chartToBase64 = chartRef.current.chartInstance.toBase64Image('image/png', 1)
      tellMeMoreImg(dispatch, chartToBase64, ua)
    }
  }

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleCloseContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
      }
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.stopPropagation()
            e.preventDefault()
            handleCloseContextMenu()
          },
        },
      }}
    >
      {aiActivated && (
        <NewMenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handlerTellMeMore(unitaryAnalysesGlobal)
            handleCloseContextMenu()
          }}
        >
          <ListItemIcon>
            {isClippyActivated() ? (
              <AttachFileIcon fontSize="small" />
            ) : (
              <SmartToyIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>{t('view.analysis.analysis.tell_me_more')}</ListItemText>
        </NewMenuItem>
      )}
      {aiActivated && <Divider sx={{ my: 0.5 }} />}
      <NewMenuItem
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          dispatch(fetchDisplayDetail(unitaryAnalyse, 0, false, e.currentTarget, collection))
          handleCloseContextMenu()
        }}
      >
        <ListItemIcon>
          <ArtTrackIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('view.analysis.analysis.contextMenu.see_detail')}</ListItemText>
      </NewMenuItem>
      {/*{(!externalModeActive || externalUaIDDisplayed !== unitaryAnalyse._id) && (*/}
      <NewMenuItem
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          dispatch(fetchDisplayDetail(unitaryAnalyse, 0, false, e.currentTarget, collection, true))
          handleCloseContextMenu()
          // if (!externalModeActive) {
          window.open(
            `${window.location.origin}${window.location.pathname}?${DATABASE()}/detail/${
              unitaryAnalyse._id
            }`,
            `${unitaryAnalyse.name}${collection === 'compound' && unitaryAnalyse.analyse}`,
            `width=${window.innerWidth - 50},height=${
              window.innerHeight - 50
            },left=${35},top=${25}`,
          )
          // }
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t('view.analysis.analysis.contextMenu.open_detail')}
          {/*{externalModeActive*/}
          {/*  ? t('view.analysis.analysis.contextMenu.update_detail')*/}
          {/*  : t('view.analysis.analysis.contextMenu.open_detail')}*/}
        </ListItemText>
      </NewMenuItem>
      {/*)}*/}
    </Menu>
  )
}
