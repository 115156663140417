import React, { Fragment, Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import { alpha, withStyles } from '@material-ui/core/styles'
import { connect, useSelector } from 'react-redux'
import Navigation from '../Navigation'
import { getBatchById } from '../../redux/batches/selectors'
import { fetchAnalyses } from '../../redux/analyses/actions'
import {
  fetchChangeSelectedChannel,
  fetchQCUnitaryAnalysesCompound,
  fetchUnitaryAnalysesCompound,
  fetchUnitaryAnalysesCompoundAllISTD,
  sortUnitaryAnalyses,
  sortUnitaryCompounds,
} from '../../redux/unitaryAnalyses/actions'
import { fetchBatch } from '../../redux/batches/actions'
import Information from './Information'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import Paper from '@material-ui/core/Paper'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import GridMultiGrid from '../common/Grid_MultiGrid'
import { getUnitaryAnalysisCompoundById } from '../../redux/unitaryAnalyses/selectors'
import {
  handleValidation,
  updateMainChannel,
  resetUnitaryAnalyses,
  callValidationDispatch,
  convertMassConcentrationValue,
  convertVolumeConcentrationValue,
  displayHumanReadable,
  customFormatDecimals,
  canIModifyTheCalibration,
  getHeaderTooltipCmp,
  tellMeMore,
  isClippyActivated,
} from '../common/Utils'
import { StyledDialogTitle, TransitionTop, Molecule, Nothing, Relance } from '../common/Style'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import Calibration from '../common/Calibration'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'
import { Tooltip } from '@material-ui/core'
import CompoundMultiSelectionDialog from '../dialog/CompoundMultiSelectionDialog'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import { displayPopupMessage } from '../common/Message'
import RetiredCmp from '../common/RetiredCmp'
import AIScoreTableTooltip from '../common/AIScoreTableTooltip'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Badge from '@mui/material/Badge'
import {
  CONFIGURATION_PROFILE,
  DATABASE,
  DRAWER_WIDTH,
  LAYOUT_CONFIG_VALUE,
} from '../../utils/config'
import AnalysisDetail from './AnalysisDetail'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { Trans, withTranslation } from 'react-i18next'
import { t } from 'i18next'
import { fetchFilters } from '../../redux/filters/actions'
import { fetchConfigurationFeatures } from '../../redux/configuration/actions'
import EmptyCompound from './EmptyCompound'
import { fetchDisplayChromatoDetail } from '../../redux/detail/actions'
import PersonIcon from '@mui/icons-material/Person'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import { getColoredCell, openAnalysisInNewTab } from './Tools'
import ChromatoTable from './ChromatoTable'
import { getFlagName } from '../analysis/Tools'
import {
  GridActionsCellItem,
  GridCellCheckboxRenderer,
  GridHeaderCheckbox,
} from '@mui/x-data-grid-pro'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { fetchDisplayJson } from '../../redux/json/actions'
import SearchIcon from '@material-ui/icons/Search'
import {
  flagsFilterOperators,
  peaksFilterOperators,
  typeFilterOperators,
  validationFilterOperators,
} from '../common/ColumnFilters'
import ProfilerContainer from '../ProfilerContainer'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SmartToyIcon from '@mui/icons-material/SmartToy'

const styles = (theme) => ({
  mainSplitter: {
    height: '100%',
    position: 'relative',
    paddingLeft: 60,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  mainSplitterFull: {
    height: 'calc(100% - 28px)',
    // paddingLeft: 241,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  centerSplitter: {
    padding: '0px 5px',
    '& > .layout-pane': {
      paddingTop: 0,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 12px 2px',
      height: 'auto !important',
    },
    '& > .layout-splitter': {
      height: '0 !important',
      opacity: 0,
    },
  },
  containerSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  deeperSplitter: {
    '& > .layout-pane': {
      padding: '1px 5px 14px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 5px 2px 2px',
      minWidth: 580,
    },
  },
  containerCustomSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      top: 3,
      padding: '13px 5px 0px 2px',
      minWidth: 580,
    },
  },
  containerOneSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      top: 3,
      padding: '13px 5px 0px 2px',
      minWidth: 200,
    },
  },
  deeperCustomSplitter: {
    '& > .layout-pane': {
      padding: '5px 5px 12px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 5px 5px',
    },
  },
  deeperCustomSplitterBis: {
    '& > .layout-pane': {
      padding: '5px 5px 12px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 5px 5px',
    },
  },
  deeperCustomSplitterDetail: {
    height: 'calc(100% - 6px)',
    width: '100%',
    '& > .layout-pane': {
      padding: '5px 5px 12px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '5px 5px 12px 5px',
    },
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: alpha(theme.palette.secondary.light, 0.19),
    '&:hover': {
      background: alpha(theme.palette.secondary.light, 0.37),
    },
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  multiGrid: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: 10,
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  cellFlags: {
    padding: 7,
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  formControl: {
    width: '100%',
    '& .MuiInput-root::before': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0px 24px 0px 0px !important',
      fontSize: 14,
      textOverflow: 'ellipsis',
    },
  },
  requestedIcon: {
    opacity: 0.6,
  },
  moveChip: {
    margin: 3,
    marginBottom: 15,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  warning: {
    color: theme.palette.common.warning,
    position: 'relative',
    left: -30,
  },
  settingsIcon: {
    position: 'absolute',
  },
  settingsButton: {
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    // backgroundColor: 'white',
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 1,
    left: 62,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  firstSplitter: {
    height: 'calc(100% - 64px)',
    '& > .layout-pane': {
      padding: '1px 8px 14px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 0px 2px 2px',
      minWidth: DRAWER_WIDTH(),
    },
  },
  transitionNumber: {
    border: '1px solid',
    borderRadius: 14,
    padding: '0px 8px',
    marginRight: 10,
  },
  picIconColor: {
    opacity: 0.5,
    color: 'black',
    marginTop: -2,
  },
  concInjBadge: {
    '& .MuiBadge-root': {
      paddingRight: 20,
      fontWeight: 'var(--unstable_DataGrid-headWeight)',
    },
    '& .MuiBadge-badge': {
      right: 13,
      top: 15,
    },
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  flagWarning: {
    color: theme.palette.common.warning,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  flagError: {
    color: theme.palette.common.error,
    verticalAlign: 'bottom',
    fontSize: 20,
  },
  chipTooltip: {
    fontSize: 14,
  },
  helperTooltip: {
    fontSize: 13,
    padding: 5,
    fontWeight: 100,
    lineHeight: '1.3em',
  },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start' },
})

const mapStateToProps = (state, ownProps) => {
  const { batches, analyses, unitaryAnalyses, drawer, configuration, settings, global } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  return {
    batch,
    pinDrawer: drawer.pin,
    store: unitaryAnalyses,
    isFetchingBatch: batches.isFetching,
    authenticationError: batches.error,
    batchNotFound: batches.batchNotFound,
    compounds: unitaryAnalyses.unitaryAnalysesCompound.map((uaf) => ({
      ...uaf,
      id: uaf._id,
    })),
    isFetchingCompounds: unitaryAnalyses.isFetchingUnitaryAnalysesCompound,
    isFetchingAllUnitaryAnalysesCompoundIstd:
      unitaryAnalyses.isFetchingAllUnitaryAnalysesCompoundIstd,
    allUnitaryAnalysesCompoundIstd: unitaryAnalyses.allUnitaryAnalysesCompoundIstd,
    configFeatures: configuration.configFeatures,
    isFetchingConfigFeatures: configuration.isFetchingConfigFeatures,
    isDatabaseOffline: settings.configurationDatabase.length > 0,
    isGlobalConfigsLoaded: global.isGlobalConfigsLoaded,
    customerConfig: global.customerConfig,
    config: state.configuration.configurations.filter(
      (config) => config._id === CONFIGURATION_PROFILE(),
    )[0],
    featuresConfig: global.featuresConfig,
    wikiCODHelper: settings.wikiCODHelper,
    matrixes: analyses.items.reduce((acc, obj) => {
      acc[obj._id] = obj.content?.sample_infos?.matrice
      return acc
    }, {}),
  }
}

const logout = (props) => {
  console.log("Please login, you're not logged.")
  localStorage.removeItem('SESSION_user_name')
  localStorage.removeItem('SESSION_user_json')
  props.history.push('/?login')
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    background: '#d3d3d340',
    padding: 5,
    height: 14,
    color: '#000000b8',
  },
}))(Badge)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      withTranslation()(
        class Compound extends React.Component {
          constructor(props) {
            super(props)
            this.state = {
              open: false,
              itemToUpdate: null,
              newMainChannel: 0,
              scrollTableId: 0,
              tableItemSelectedForColor: null,
              openConfirmReset: false,
              openSelectionMultiDialog: false,
              resetUA: null,
              selection: [],
              selectionType: [],
              visibleRows: null,
              columns: this.getColumns(props.classes) || [],
            }

            this.navigationRef = React.createRef()
            this.contentRef = React.createRef()
            this.contentCalibrationRef = React.createRef()
            this.contentCustomRef = React.createRef()
            this.contentCustomCalibrationRef = React.createRef()
            this.contentCustomAnalysisDetail = React.createRef()
            this.tableMultiGridRef = React.createRef()
            this.contentFirstCntRef = React.createRef()
            this.onSelect = this.onSelect.bind(this)
            this.multiValidate = this.multiValidate.bind(this)
            this.handleReset = this.handleReset.bind(this)
            this.handleConfirmResetClose = this.handleConfirmResetClose.bind(this)
            this.handleMultiSelectionConfirm = this.handleMultiSelectionConfirm.bind(this)
            this.openSelectionMultipleDialog = this.openSelectionMultipleDialog.bind(this)

            this.elementRef = null
            this.handleKeyDown = this.handleKeyDown.bind(this)
          }

          refGridChromato = React.createRef()
          refTableAnalyse = React.createRef()
          refCalibration = React.createRef()

          // elementRef = React.createRef()

          handleKeyDown(event) {
            const { dispatch, compounds } = this.props
            const { tableItemSelectedForColor } = this.state
            let next_item = null

            if (event.keyCode === 38 && compounds.length > 1) {
              // Flèche haut
              if (tableItemSelectedForColor) {
                // Select the next item
                let currentIndex = null
                compounds.forEach((compound, index) => {
                  if (compound._id === tableItemSelectedForColor) {
                    currentIndex = index
                    return
                  }
                })
                next_item = compounds[currentIndex - 1]
                // ? compounds[currentIndex - 1]
                // : compounds[compounds.length - 1]
              } else {
                // Select the last item
                next_item = compounds[compounds.length - 1]
              }
            } else if (event.keyCode === 40 && compounds.length > 1) {
              // Flèche bas
              if (tableItemSelectedForColor) {
                // Select the next item
                let currentIndex = null
                compounds.forEach((compound, index) => {
                  if (compound._id === tableItemSelectedForColor) {
                    currentIndex = index
                    return
                  }
                })
                next_item = compounds[currentIndex + 1]
                // ? compounds[currentIndex + 1] : compounds[0]
              } else {
                // Select the first item
                next_item = compounds[0]
              }
            }
            if (next_item) {
              this.setState({ scrollTableId: next_item._id })
              this.setState({ tableItemSelectedForColor: next_item._id })
              this.refGridChromato.current.setGridItemSelectedForColor(next_item._id)
              this.refCalibration.current.displayCalib(
                next_item.event,
                next_item.selectedChannel,
                null,
                next_item,
              )
              this.refGridChromato.current.scrollTo(next_item._id)
            }

            // this.setState({ scrollTableId: item._id })
            // this.refGridChromato.current.setGridItemSelectedForColor(item._id)
            // this.setState({ tableItemSelectedForColor: item._id })
            // this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
            //
            // this.refGridChromato.current.scrollTo(item._id)
            // this.setState({ tableItemSelectedForColor: item._id })
            // this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
          }

          loadData() {
            const { dispatch, isDatabaseOffline, match, history } = this.props

            if (!isDatabaseOffline) {
              dispatch(fetchAnalyses(match.params.batchId))

              // Open the chromato detail if an ID is set in the URL
              const chromatoId = match.params.chromatoId
              if (chromatoId) {
                const regex = /ua=/i
                dispatch(fetchDisplayChromatoDetail(chromatoId.replace(regex, '')))
              }

              const moleculeEvent = match.params.moleculeEvent

              const batchId = match.params.batchId
              dispatch(fetchBatch(batchId)).then(function (resp) {
                if (resp?.type === 'ITEM_NOT_FOUND') {
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.compound.compound.not_found_title'),
                    <Trans i18nKey="view.compound.compound.not_found_msg">
                      The batch with id {{ val: match.params.batchId }} can not be found.
                    </Trans>,
                  )
                  history.push('/' + DATABASE())
                }
                // Load the configuration features from the batch
                if (resp?.batch?.content?.configuration) {
                  dispatch(fetchConfigurationFeatures(resp.batch.content.configuration)).then(
                    function (resp) {
                      // Store the batch configuration id
                      resp?.configFeatures?._id &&
                        localStorage.setItem(
                          'SETTINGS_configuration_profile',
                          resp.configFeatures._id,
                        )
                    },
                  )
                }
                if (resp?.batch?.content?.chromatography_type) {
                  dispatch(fetchFilters(resp.batch.content.chromatography_type))
                }
                if (localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi') {
                  if (
                    resp?.batch?.content?.molecules?.[moleculeEvent]?.istd &&
                    resp?.batch?.content?.internal_standards?.[
                      resp.batch.content.molecules[moleculeEvent].istd
                    ]
                  ) {
                    dispatch(
                      fetchUnitaryAnalysesCompoundAllISTD(
                        batchId,
                        resp.batch.content.internal_standards[
                          resp.batch.content.molecules[moleculeEvent].istd
                        ].event,
                      ),
                    )
                  }
                }
              })
              dispatch(
                fetchUnitaryAnalysesCompound(
                  match.params.batchId,
                  match.params.moleculeEvent,
                  localStorage.getItem('COMPOUND_filter_untreated')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_untreated')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_requested')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_requested')) === true
                    : true,
                  localStorage.getItem('COMPOUND_filter_detected')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_detected')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_notDetected')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_notDetected')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_relaunch')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_relaunch')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_std')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_std')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_mb')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_mb')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_mbd')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_mbd')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_stde')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_stde')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_blk')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_blk')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_spl')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_spl')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_oth')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_oth')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_allDetected')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_allDetected')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_allSuspected')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_allSuspected')) === true
                    : false,
                  localStorage.getItem('COMPOUND_filter_allExcluded')
                    ? JSON.parse(localStorage.getItem('COMPOUND_filter_allExcluded')) === true
                    : false,
                  null,
                  localStorage.getItem('COMPOUND_sorter'),
                ),
              )
              if (this.refCalibration.current && this.refCalibration.current.displayCalib) {
                this.refCalibration.current.displayCalib(match.params.moleculeEvent)
              }
              dispatch(
                fetchQCUnitaryAnalysesCompound(match.params.batchId, match.params.moleculeEvent),
              )
            }
          }
          async componentDidMount() {
            this.loadData()
          }

          componentDidUpdate(prevProps, prevState) {
            const { chromatoId, moleculeEvent } = this.props.match.params
            if (
              chromatoId !== prevProps.match.params.chromatoId ||
              moleculeEvent !== prevProps.match.params.moleculeEvent
            ) {
              this.loadData()
            }
            if (this.elementRef) {
              this.elementRef.addEventListener('keydown', this.handleKeyDown)
            }
            if (prevProps.classes !== this.props.classes) {
              this.setState({
                columns: this.getColumns(this.props.classes),
              })
            }
          }

          componentWillUnmount() {
            if (this.elementRef) {
              this.elementRef.removeEventListener('keydown', this.handleKeyDown)
            }
          }

          scrollToTable = (item) => {
            this.setState({ scrollTableId: item._id })
            this.refGridChromato.current.setGridItemSelectedForColor(item._id)
            this.setState({ tableItemSelectedForColor: item._id })
            // In case of ISTD, do not display the calibration
            // EDIT: do not display calibration but we have to use it to get the wip parameters for the recompute button
            // if (item.type === 'ISTD') {
            //   this.refCalibration.current.displayISTDMessage()
            // } else {
            this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
            // }
          }
          scrollToGrid = (item) => {
            this.refGridChromato.current.scrollTo(item._id)
            this.setState({ tableItemSelectedForColor: item._id })
            // In case of ISTD, do not display the calibration
            // EDIT: do not display calibration but we have to use it to get the wip parameters for the recompute button
            // if (item.type === 'ISTD') {
            //   this.refCalibration.current.displayISTDMessage()
            // } else {
            this.refCalibration.current.displayCalib(item.event, item.selectedChannel, null, item)
            // }
          }

          onCalibrationPointClick(ua_id) {
            this.refGridChromato.current.setGridItemSelectedForColor(ua_id)
            this.refGridChromato.current.scrollTo(ua_id)
            this.setState({ scrollTableId: ua_id, tableItemSelectedForColor: ua_id })
          }

          onChangeChannel(event, ua, mainChannel) {
            const { dispatch } = this.props
            event.stopPropagation()
            event.preventDefault()
            dispatch(fetchChangeSelectedChannel(ua._id, mainChannel, 'calibration')).then(
              this.setState({ tableItemSelectedForColor: ua._id }),
              this.refGridChromato.current.setGridItemSelectedForColor(ua._id),
              this.refCalibration.current.displayCalib(ua.event, mainChannel, null, ua),
            )
          }

          moleculeDoubleClick = (analyseId) => {
            this.props.history.push(
              '/?' + DATABASE() + '/' + this.props.match.params.batchId + '/analysis/' + analyseId,
            )
          }

          applyFromSelection() {
            const { isFetchingCompounds, compounds } = this.props

            if (!isFetchingCompounds && compounds) {
              const fromSelection = compounds.filter(
                (uaf) => uaf.analyseId === localStorage.getItem('NAVIGATION_current_analysisId'),
              )[0]
              if (fromSelection) {
                localStorage.removeItem('NAVIGATION_current_analysisId')
                setTimeout(
                  function () {
                    this.setState({
                      scrollTableId: fromSelection._id,
                      tableItemSelectedForColor: fromSelection._id,
                    })
                    if (this.refGridChromato.current) {
                      this.refGridChromato.current.setGridItemSelectedForColor(fromSelection._id)
                      this.refGridChromato.current.scrollTo(fromSelection._id)
                    }
                    if (this.refCalibration.current) {
                      // In case of ISTD, do not display the calibration
                      if (fromSelection.type === 'ISTD') {
                        this.refCalibration.current.displayISTDMessage()
                      } else {
                        this.refCalibration.current.displayCalib(
                          fromSelection.event,
                          fromSelection.mainChannel,
                          null,
                          // this.getSampleRefPoint(fromSelection),
                          fromSelection,
                        )
                      }
                    }
                  }.bind(this),
                  500,
                )
              }
            }
          }

          handleMultiSelectionConfirm(event, option) {
            const { selection } = this.state
            let customSelection = []
            switch (option) {
              case 1:
                // Keep all selection do nothing
                break
              case 2:
                // Keep samples
                selection.forEach(function (sel, index) {
                  if (sel.status !== 'ok' && sel.status !== 'ko') {
                    customSelection.push(index)
                  }
                })
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
              case 3:
                // Keep others
                selection.forEach(function (sel, index) {
                  if (sel.status === 'ok' || sel.status === 'ko') {
                    customSelection.push(index)
                  }
                })
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
              default:
                this.tableMultiGridRef.current.customSelection(customSelection)
                break
            }
            this.setState({ openSelectionMultiDialog: false })
          }

          openSelectionMultipleDialog(rows) {
            const { compounds } = this.props
            const infos = []
            rows.map(function (i) {
              let type = 'detectedNotDetected'
              if (compounds[i].status === 'ok' || compounds[i].status === 'ko') {
                type = 'okKo'
              }
              infos[type] = infos[type] ? infos[type] + 1 : 1
              return type
            })

            if (Object.keys(infos).length > 1) {
              this.setState({
                openSelectionMultiDialog: true,
              })
            }
          }

          handleConfirmResetClose(event, save) {
            const { resetUA, selection } = this.state
            const { dispatch } = this.props
            this.setState({ openConfirmReset: false })
            if (save) {
              let uAIds = [resetUA]
              resetUnitaryAnalyses(event, uAIds, dispatch)
            }
          }

          handleReset(event, unitaryAnalyse) {
            event.stopPropagation()
            event.preventDefault()
            this.setState({ openConfirmReset: true, resetUA: unitaryAnalyse })
          }

          canEditCalib(item) {
            const { batch, featuresConfig } = this.props
            const edit_calibration = featuresConfig?.content?.edit_calibration
            const param_exist = typeof edit_calibration === 'boolean'
            const getRights = canIModifyTheCalibration(batch)
            // In this case we want to know if the item can be edited or not
            if (item) {
              if (item.sampleType === 'Standard') {
                return param_exist ? edit_calibration && getRights : true
              } else {
                return true
              }
            } else {
              // Here it's to know if the edition is active or not
              return param_exist ? edit_calibration && getRights : true
            }
          }

          onSelect(selected) {
            const { compounds } = this.props
            let selection = selected.map((i) => compounds[i])
            const infos = []
            if (!this.canEditCalib()) {
              const updatedSelected = selected.filter(
                (s) => compounds[s]?.sampleType !== 'Standard',
              )
              const updatedSelection = updatedSelected.map((i) => compounds[i])
              if (updatedSelection.length !== selected.length) {
                selected = updatedSelected
                selection = updatedSelection
                this.tableMultiGridRef.current.customSelection(updatedSelected)
                displayPopupMessage(
                  this.props.dispatch,
                  'info',
                  t('view.batch.qualityControlTable.cantSelect.title'),
                  t('view.batch.qualityControlTable.cantSelect.msg'),
                )
              }
            }
            const types = selected
              .map(function (i) {
                let type = 'detectedNotDetected'
                if (compounds[i].status === 'ok' || compounds[i].status === 'ko') {
                  type = 'okKo'
                }
                infos[type] = infos[type] ? infos[type] + 1 : 1
                return type
              })
              .filter(function (item, pos, self) {
                return self.indexOf(item) === pos
              })
              .map((type) => ({ name: type, value: infos[type] }))
            this.setState({
              selection: selection,
              selectionType: types,
            })
          }

          multiValidate(value) {
            const { selectionType } = this.state
            this.tableMultiGridRef.current.clearSelection()
            const selection = this.state.selection
            const dispatch = this.props.dispatch
            callValidationDispatch(
              'unitary_compound',
              dispatch,
              selection,
              value,
              null,
              this.props.match.params.batchId,
              selectionType,
            )
          }
          handleTellMeMore(row) {
            const { dispatch, matrixes } = this.props
            if (row) {
              const matrix = matrixes?.[row.analyseId]
              tellMeMore(dispatch, row, matrix)
            }
          }

          setVisibleRows = (ids) => {
            this.setState({ visibleRows: ids })
          }

          sortAction = (order, direction) => {
            localStorage.setItem('COMPOUND_sorter', `${direction}$${order}`)
            this.props.dispatch(sortUnitaryCompounds(direction, order))
            try {
              this.tableMultiGridRef.current.clearSelection()
            } catch (e) {
              console.info('Trying to clear selection but does not work')
            }
          }

          onMoleculeDBLClickSignature = (item) => {
            this.props.history.push(
              '/?' +
                DATABASE() +
                '/' +
                this.props.match.params.batchId +
                '/analysis/' +
                item.analyseId,
            )
          }
          onMoleculeClickSignature = (item) => {
            this.scrollToGrid(item)
          }

          getGlobalScoreTitle = () => {
            const { isGlobalConfigsLoaded, customerConfig, i18n } = this.props
            const currentLanguage = i18n.language.slice(0, 2)
            if (
              isGlobalConfigsLoaded &&
              customerConfig &&
              customerConfig.content.hasOwnProperty('columnIA') &&
              currentLanguage &&
              customerConfig.content.columnIA.hasOwnProperty(currentLanguage)
            ) {
              return customerConfig.content.columnIA[currentLanguage]
            } else {
              return t('view.analysis.analysis.column.ai_score')
            }
          }

          onWhichUpdateMainChannel = (event, item) => {
            this.setState({ open: true, newMainChannel: event.target.value, itemToUpdate: item })
          }

          getColumns = (classes) => {
            const { configFeatures, config, wikiCODHelper, dispatch, featuresConfig } = this.props
            const me = this
            const aiAssistantActivated =
              featuresConfig &&
              typeof featuresConfig?.content?.ai_assistant === 'boolean' &&
              featuresConfig?.content?.ai_assistant

            const concentration_report =
              config &&
              config.content.analysis_parameters_by_analysis &&
              config.content.analysis_parameters_by_analysis.sample &&
              config.content.analysis_parameters_by_analysis.sample.formula_infos &&
              config.content.analysis_parameters_by_analysis.sample.formula_infos.unite
                .slice(-1)
                .toLowerCase() === 'l'
                ? 'vol'
                : 'mass'

            const reportedConcentrationUnit =
              concentration_report === 'vol'
                ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
                  ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
                  : 'µg/l'
                : localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
                ? localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
                : 'mg/kg'

            const volumeConcentrationUnit = localStorage.getItem(
              'SETTINGS_volume_concentration_unit',
            )
              ? localStorage.getItem('SETTINGS_volume_concentration_unit')
              : 'µg/l'

            let columns = [
              {
                field: '__check__',
                width: 50,
                type: 'checkboxSelection',
                cellClassName: 'check-box-selection',
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                disableReorder: true,
                disableExport: true,
                renderHeader: (params) => (
                  <React.Fragment>
                    <Badge badgeContent={this.state.selection.length} color="secondary">
                      <GridHeaderCheckbox {...params} />
                    </Badge>
                  </React.Fragment>
                ),
                renderCell: (params) => <GridCellCheckboxRenderer {...params} />,
              },
              {
                field: 'flags',
                type: 'custom',
                headerName: t('view.compound.compound.column.flags'),
                renderCell: ({ row }) => (
                  <AlphacodFlagIcon item={row} hideGeneralTab={false} collection={'analyse'} />
                ),
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                valueFormatter: (params) => {
                  return getFlagName(params.api.getRow(params.id))
                },
                filterOperators: flagsFilterOperators,
              },
              // { key: 'notes', label: 'Notes', size: 60 },
              {
                field: 'requested',
                headerName: t('view.compound.compound.column.req'),
                type: 'boolean',
                renderCell: function ({ value }) {
                  if (value === 'True') {
                    return <PersonPinIcon title="Requested" className={classes.requestedIcon} />
                  }
                  return ''
                },
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              },
              {
                field: 'modified',
                headerName: t('view.compound.compound.column.modified'),
                type: 'boolean',
                renderCell: function ({ row }) {
                  return (
                    <ErrorBoundaryGuard>
                      <AlphacodModifiedButtonIcon item={row} />
                    </ErrorBoundaryGuard>
                  )
                },
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              },
              {
                field: '#',
                headerName: '#',
                type: 'number',
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              },
              {
                field: 'peak',
                headerName: t('view.analysis.analysis.column.modifPeak'),
                type: 'custom',
                renderCell: function ({ row }) {
                  return (
                    (row?.peak === 2000 || row?.peak === 3000) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div
                            style={{
                              fontSize: 13,
                              padding: 5,
                            }}
                          >
                            {row.peak === 2000
                              ? t('view.analysis.analysis.peakUpdate.auto')
                              : t('view.analysis.analysis.peakUpdate.user')}
                          </div>
                        }
                      >
                        {row.peak === 2000 ? (
                          <AutoGraphIcon className={classes.picIconColor} />
                        ) : (
                          <PersonIcon className={classes.picIconColor} />
                        )}
                      </Tooltip>
                    )
                  )
                },
                valueGetter: ({ value }) =>
                  value === 2000 ? 'auto' : value === 3000 ? 'manual' : '',
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                filterOperators: peaksFilterOperators,
              },
              {
                field: 'analyse',
                headerName: t('view.compound.compound.column.analysis'),
                type: 'text',
                renderCell: function ({ value }) {
                  return (
                    <Tooltip
                      key={'tooltip_analyse'}
                      arrow
                      placement="top"
                      title={<div style={{ fontSize: 13, padding: 5 }}>{value}</div>}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          display: 'inline-block',
                          width: 'fit-content',
                        }}
                      >
                        {value}
                      </div>
                    </Tooltip>
                  )
                },
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              },
              {
                field: 'status',
                headerName: t('view.compound.compound.column.state'),
                type: 'text',
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              },
              {
                field: 'global_score',
                headerName: this.getGlobalScoreTitle() + ' (%)',
                renderHeader: (params) =>
                  getHeaderTooltipCmp(params, wikiCODHelper, this.getGlobalScoreTitle()),
                type: 'number',
                customFormat: true,
                renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                  const expertMode = localStorage.getItem('SETTINGS_expert_mode') === 'true'
                  const returnValue =
                    value && value !== '' && value !== null
                      ? customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + '%'
                      : value
                  return expertMode ? (
                    <ErrorBoundaryGuard isDialog>
                      <AIScoreTableTooltip item={row} value={returnValue} />
                    </ErrorBoundaryGuard>
                  ) : (
                    returnValue
                  )
                },
                valueFormatter: ({ value }) =>
                  (value / 100).toLocaleString(undefined, { maximumFractionDigits: 20 }),
                valueGetter: ({ value }) => value * 100,
              },
              {
                field: 'validation',
                headerName: t('view.compound.compound.column.validation'),
                type: 'custom',
                renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                  return (
                    <div className={classes.actionContainer}>
                      <IconButton
                        disabled={!me.canEditCalib(row)}
                        aria-label="detected"
                        onClick={(event) => {
                          handleValidation(event, row, '1', 'unitary_compound', dispatch)
                        }}
                      >
                        {value === '1' ? (
                          row.status === 'ok' || row.status === 'ko' ? (
                            <CheckIcon className={classes.selected} />
                          ) : (
                            <Molecule className={classes.selected} />
                          )
                        ) : row.status === 'ok' || row.status === 'ko' ? (
                          <CheckIcon className={classes.notSelected} />
                        ) : (
                          <Molecule className={classes.notSelected} />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={!me.canEditCalib(row)}
                        aria-label="notDetected"
                        onClick={(event) =>
                          handleValidation(event, row, '2', 'unitary_compound', dispatch)
                        }
                      >
                        {value === '2' ? (
                          row.status === 'ok' || row.status === 'ko' ? (
                            <ClearIcon className={classes.selected} />
                          ) : (
                            <Nothing className={classes.selected} />
                          )
                        ) : row.status === 'ok' || row.status === 'ko' ? (
                          <ClearIcon className={classes.notSelected} />
                        ) : (
                          <Nothing className={classes.notSelected} />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={!me.canEditCalib(row)}
                        aria-label="relaunch"
                        onClick={(event) =>
                          handleValidation(event, row, '3', 'unitary_compound', dispatch)
                        }
                      >
                        {value === '3' ? (
                          <Relance className={classes.selected} />
                        ) : (
                          <Relance className={classes.notSelected} />
                        )}
                      </IconButton>
                    </div>
                  )
                },
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                valueFormatter: ({ value }) => {
                  switch (value) {
                    case '1':
                      return 'detected'
                    case '2':
                      return 'not detected'
                    case '3':
                      return 'relaunch'
                    case '0':
                    default:
                      return ''
                  }
                },
                filterOperators: validationFilterOperators,
              },
              {
                field: 'recovery',
                headerName: t('view.analysis.analysis.column.recovery') + ' (%)',
                renderHeader: (params) =>
                  getHeaderTooltipCmp(
                    params,
                    wikiCODHelper,
                    t('view.analysis.analysis.column.recovery'),
                  ),
                type: 'number',
                customFormat: true,
                renderCell: function ({ value, colDef: { columnInfos } }) {
                  return value
                    ? `${parseFloat(
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ),
                      )}%`
                    : value
                },
                valueFormatter: ({ value }) =>
                  (value / 100)?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                valueGetter: ({ value }) => (typeof value === 'number' ? value * 100 : null),
              },
              {
                field: 'recovery_factor',
                headerName: t('view.analysis.analysis.column.recovery_factor'),
                type: 'number',
                customFormat: true,
                renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                  return value
                    ? customFormatDecimals(value, displayHumanReadable(value, 2, 2), columnInfos)
                    : value
                },
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                valueFormatter: ({ value }) =>
                  value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
              },
            ]

            if (configFeatures && typeof configFeatures.lmr === 'boolean' && configFeatures.lmr) {
              columns.push({
                field: 'lmr',
                headerName: t('view.analysis.analysis.column.lmr'),
                type: 'text',
                renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
              })
            }

            columns.push(
              ...[
                {
                  field: 'dev_ion_ratio',
                  headerName: t('view.compound.compound.column.dev_ion_ratio') + ' (%)',
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      t('view.compound.compound.column.dev_ion_ratio'),
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, colDef: { columnInfos } }) {
                    return value
                      ? `${parseFloat(
                          customFormatDecimals(
                            value,
                            displayHumanReadable(value, 2, 2, true),
                            columnInfos,
                          ),
                        )}%`
                      : value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'ion_ratio',
                  headerName: t('view.compound.compound.column.ion_ratio'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    return value
                      ? customFormatDecimals(value, displayHumanReadable(value, 2, 2), columnInfos)
                      : value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'mass_conc',
                  // label: 'Mass conc',
                  headerName:
                    t('view.compound.compound.column.mass_conc_header') +
                    ` (${reportedConcentrationUnit})`,
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      <Trans i18nKey="view.compound.compound.column.mass_conc">
                        Conc report({{ val: reportedConcentrationUnit }})
                      </Trans>,
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertMassConcentrationValue(
                        value,
                        row,
                        null,
                        concentration_report,
                      )
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
              ],
            )

            for (
              let step = 1;
              step <=
              Number(
                localStorage.getItem(
                  '__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION',
                ),
              );
              step++
            ) {
              columns.push({
                field: `mass_conc_${step}`,
                // label: 'Mass conc',
                headerName:
                  t('view.compound.compound.column.mass_conc_header') +
                  ` ch${step} (${reportedConcentrationUnit})`,
                type: 'number',
                customFormat: true,
                renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                  if (value && value !== '' && value !== null) {
                    const con_val = convertMassConcentrationValue(
                      value,
                      row,
                      null,
                      concentration_report,
                    )
                    return customFormatDecimals(
                      con_val,
                      displayHumanReadable(con_val, 4),
                      columnInfos,
                    )
                  }
                  return value
                },
                renderHeader: (params) =>
                  getHeaderTooltipCmp(
                    params,
                    wikiCODHelper,
                    <span className={classes.concInjBadge}>
                      <StyledBadge badgeContent={'ch' + step}>
                        <Trans i18nKey="view.compound.compound.column.mass_conc">
                          Conc report({{ val: reportedConcentrationUnit }})
                        </Trans>
                      </StyledBadge>
                    </span>,
                  ),
                valueFormatter: ({ value }) =>
                  value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
              })
            }

            columns.push(
              ...[
                {
                  field: 'amt_conc',
                  // label: 'Vol conc',
                  headerName:
                    t('view.compound.compound.column.conc_inj_header') +
                    ` (${volumeConcentrationUnit})`,
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      <Trans i18nKey="view.compound.compound.column.conc_inj">
                        Conc inj ({{ val: volumeConcentrationUnit }})
                      </Trans>,
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return getColoredCell(
                        row,
                        'amt_conc',
                        customFormatDecimals(
                          convertVolumeConcentrationValue(value, row),
                          convertVolumeConcentrationValue(displayHumanReadable(value, 4), row),
                          columnInfos,
                        ),
                        classes,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'mass_conc_rdt',
                  headerName:
                    t('view.compound.compound.column.mass_conc_rdt_header') +
                    ` (${reportedConcentrationUnit})`,
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      <Trans i18nKey="view.compound.compound.column.mass_conc_rdt">
                        Conc RDT({{ val: reportedConcentrationUnit }})
                      </Trans>,
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertMassConcentrationValue(
                        value,
                        row,
                        null,
                        concentration_report,
                      )
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
              ],
            )

            for (
              let step = 1;
              step <=
              Number(
                localStorage.getItem(
                  '__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION',
                ),
              );
              step++
            ) {
              columns.push({
                field: `amt_conc_${step}`,
                type: 'number',
                customFormat: true,
                renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                  if (value && value !== '' && value !== null) {
                    return getColoredCell(
                      row,
                      `amt_conc_${step}`,
                      customFormatDecimals(
                        convertVolumeConcentrationValue(value, row),
                        convertVolumeConcentrationValue(displayHumanReadable(value, 4), row),
                        columnInfos,
                      ),
                      classes,
                    )
                  }
                  return value
                },
                valueFormatter: ({ value }) =>
                  value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                headerName:
                  t('view.compound.compound.column.amt_conc_channels_header') +
                  ` ch${step} (${reportedConcentrationUnit})`,
                renderHeader: (params) =>
                  getHeaderTooltipCmp(
                    params,
                    wikiCODHelper,
                    <span className={classes.concInjBadge}>
                      <StyledBadge badgeContent={'ch' + step}>
                        <Trans i18nKey="view.compound.compound.column.conc_inj">
                          Conc inj ({{ val: volumeConcentrationUnit }})
                        </Trans>
                      </StyledBadge>
                    </span>,
                  ),
              })
            }

            columns.push(
              ...[
                // {
                //   key: 'amt_conc',
                //   // label: 'Vol conc',
                //   label: 'Conc(' + volumeConcentrationUnit + ')',
                //   size: 100,
                //   customDisplay: function (value, item) {
                //     if (value && value !== '' && value !== null) {
                //       return convertVolumeConcentrationValue(value, item).toFixed(4)
                //     }
                //     return value
                //   },
                // },
                {
                  field: 'loq',
                  headerName:
                    t('view.compound.compound.column.lq_header') + ` (${volumeConcentrationUnit})`,
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      <Trans i18nKey="view.compound.compound.column.lq">
                        LQ({{ val: volumeConcentrationUnit }})
                      </Trans>,
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      const con_val = convertVolumeConcentrationValue(value, row)
                      return customFormatDecimals(
                        con_val,
                        displayHumanReadable(con_val, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'area',
                  headerName: t('view.compound.compound.column.area'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 0),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'isArea',
                  headerName: t('view.compound.compound.column.is_area'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 0),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'areaRatio',
                  headerName: t('view.compound.compound.column.area_ratio'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return getColoredCell(
                        row,
                        `areaRatio`,
                        customFormatDecimals(value, displayHumanReadable(value, 2, 2), columnInfos),
                        classes,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'istd_state',
                  headerName: t('view.compound.compound.column.istd_state'),
                  type: 'text',
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                },
                {
                  field: 'istd_dev_area',
                  headerName: t('view.compound.compound.column.istd_dev_area') + ' (%)',
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      t('view.compound.compound.column.istd_dev_area'),
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, colDef: { columnInfos } }) {
                    return value
                      ? `${parseFloat(
                          customFormatDecimals(
                            value,
                            displayHumanReadable(value, 2, 2, true),
                            columnInfos,
                          ),
                        )}%`
                      : value
                  },
                },
                {
                  field: 's_n',
                  headerName: t('view.compound.compound.column.sn'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      let val = '> 100'
                      if (value < 100) {
                        val = customFormatDecimals(
                          value,
                          displayHumanReadable(value, 1, 1),
                          columnInfos,
                        )
                      }
                      return getColoredCell(row, `s_n`, val, classes)
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'sampleType',
                  type: 'text',
                  headerName: t('view.compound.compound.column.sample_type'),
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                },
                {
                  field: 'level',
                  headerName: t('view.compound.compound.column.level'),
                  type: 'number',
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'conc_target',
                  headerName:
                    t('view.compound.compound.column.conc_target_header') +
                    ` (${volumeConcentrationUnit})`,
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      <Trans i18nKey="view.compound.compound.column.conc_target">
                        Conc target({{ val: volumeConcentrationUnit }})
                      </Trans>,
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        convertVolumeConcentrationValue(value, row),
                        displayHumanReadable(convertVolumeConcentrationValue(value, row), 4),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'height',
                  headerName: t('view.compound.compound.column.height'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'type',
                  headerName: t('view.compound.compound.column.type'),
                  type: 'text',
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  filterOperators: typeFilterOperators,
                },
                {
                  field: 'm_z',
                  filterable: false,
                  headerName: t('view.compound.compound.column.mz'),
                  type: 'custom',
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    return (
                      <FormControl className={classes.formControl}>
                        <Select
                          value={row.m_z}
                          name={'m_z'}
                          onChange={(event) => me.onWhichUpdateMainChannel(event, row)}
                          displayEmpty
                          autoWidth
                          inputProps={{
                            readOnly: !me.canEditCalib(row),
                            'aria-label': 'Main Channel',
                          }}
                        >
                          {[row.main_channel_data]
                            .concat(row.channels_data)
                            .map((channel, index) => (
                              <MenuItem key={index} value={channel.channelNumber}>
                                <span className={classes.transitionNumber}>
                                  {channel.channelNumber}
                                </span>
                                {`${channel.q1} > ${channel.q3} ${
                                  channel.concentration &&
                                  channel.concentration !== '' &&
                                  channel.concentration !== null
                                    ? '(' +
                                      t('view.compound.compound.conc') +
                                      '=' +
                                      parseFloat(channel.concentration).toFixed(1) +
                                      ')'
                                    : ''
                                }`}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: (params) => {
                    const row = params.api.getRow(params.id)
                    const channel = [row.main_channel_data]
                      .concat(row.channels_data)
                      .filter((ch) => ch.channelNumber === params.value)[0]
                    return `${channel?.q1} > ${channel?.q3} ${
                      channel?.concentration &&
                      channel?.concentration !== '' &&
                      channel?.concentration !== null
                        ? '(' +
                          t('view.analysis.analysis.concentration') +
                          '=' +
                          parseFloat(channel.concentration).toFixed(1) +
                          ')'
                        : ''
                    }`
                  },
                },
                {
                  field: 'refIons',
                  filterable: false,
                  headerName: t('view.compound.compound.column.ref_ions'),
                  type: 'custom',
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                },
                {
                  field: 'rt_peak',
                  headerName: t('view.compound.compound.column.rt_peak'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 2, 2),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'devRrt',
                  headerName: t('view.compound.compound.column.dev_rrt') + ' (%)',
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      t('view.compound.compound.column.dev_rrt'),
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return getColoredCell(
                        row,
                        `devRrt`,
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %',
                        classes,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'devRt',
                  headerName: t('view.compound.compound.column.dev_rt') + ' (%)',
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      t('view.compound.compound.column.dev_rt'),
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return (
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %'
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'devAccuracy',
                  headerName: t('view.compound.compound.column.dev_acc') + ' (%)',
                  renderHeader: (params) =>
                    getHeaderTooltipCmp(
                      params,
                      wikiCODHelper,
                      t('view.compound.compound.column.dev_acc'),
                    ),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return getColoredCell(
                        row,
                        `devAccuracy`,
                        customFormatDecimals(
                          value,
                          displayHumanReadable(value, 2, 2, true),
                          columnInfos,
                        ) + ' %',
                        classes,
                      )
                    }
                    return value
                  },
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'tailing_factor',
                  headerName: t('view.compound.compound.column.tailing_factor'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 3, 3),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'asymetry_factor',
                  headerName: t('view.compound.compound.column.asymetry_factor'),
                  type: 'number',
                  customFormat: true,
                  renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
                    if (value && value !== '' && value !== null) {
                      return customFormatDecimals(
                        value,
                        displayHumanReadable(value, 3, 3),
                        columnInfos,
                      )
                    }
                    return value
                  },
                  renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
                  valueFormatter: ({ value }) =>
                    value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
                },
                {
                  field: 'actions',
                  type: 'actions',
                  hideable: false,
                  resizable: false,
                  sortable: false,
                  filterable: false,
                  disableColumnMenu: false,
                  disableReorder: true,
                  disableExport: true,
                  pinnable: false,
                  width: 50,
                  minWidth: 50,
                  maxWidth: 50,
                  getActions: ({ row, ...otherParams }) => {
                    const tabAction = []
                    if (aiAssistantActivated) {
                      tabAction.push([
                        <GridActionsCellItem
                          className={classes.actionMenu}
                          showInMenu
                          icon={isClippyActivated() ? <AttachFileIcon /> : <SmartToyIcon />}
                          label={t('view.analysis.analysis.tell_me_more')}
                          onClick={() => {
                            me.handleTellMeMore(row)
                          }}
                        />,
                        <GridActionsCellItem
                          disabled={true}
                          className={classes.actionDivider}
                          showInMenu
                          label={''}
                        />,
                      ])
                    }
                    tabAction.push([
                      <GridActionsCellItem
                        className={classes.actionMenu}
                        showInMenu
                        icon={<OpenInBrowserIcon />}
                        label={t('view.compound.compound.actions.open')}
                        onClick={() =>
                          this.props.history.push(
                            '/?' + DATABASE() + '/' + row.batchId + '/compound/' + row.event,
                          )
                        }
                      />,
                      <GridActionsCellItem
                        className={classes.actionMenu}
                        showInMenu
                        icon={<OpenInNewIcon />}
                        label={t('view.compound.compound.actions.openNew')}
                        onClick={() => openAnalysisInNewTab(row)}
                      />,
                      <GridActionsCellItem
                        disabled={true}
                        className={classes.actionDivider}
                        showInMenu
                        label={''}
                      />,
                      <GridActionsCellItem
                        onClick={() => {
                          dispatch(fetchDisplayJson(row._id, 'compound'))
                        }}
                        className={classes.actionMenu}
                        showInMenu
                        icon={<SearchIcon />}
                        label={t('view.compound.compound.actions.explore')}
                      />,
                    ])
                    return tabAction.flat()
                  },
                },
              ],
            )
            return columns
          }

          // getColumnsMemoized = () => {
          //   if (!this.memoizedColumns) {
          //     this.memoizedColumns = this.getColumns()
          //   }
          //   return this.memoizedColumns
          // }

          render() {
            const {
              classes,
              pinDrawer,
              dispatch,
              batch,
              store,
              authenticationError,
              batchNotFound,
              compounds,
              isFetchingCompounds,
              isFetchingAllUnitaryAnalysesCompoundIstd,
              allUnitaryAnalysesCompoundIstd,
              configFeatures,
              isFetchingConfigFeatures,
              isDatabaseOffline,
              isGlobalConfigsLoaded,
              customerConfig,
              i18n,
              config,
              featuresConfig,
              wikiCODHelper,
            } = this.props

            const me = this
            // const columns = me.getColumnsMemoized()
            const layout = LAYOUT_CONFIG_VALUE('compound')
            const idTableConst = 'myCompoundMoleculesTable'
            const tableMultiGridRef = this.tableMultiGridRef
            const parentMultiGridRef = React.createRef()
            const apiRef = this.apiRef
            const currentLanguage = i18n.language.slice(0, 2)

            //Save the current UA for futur navigation in other panel
            localStorage.setItem(
              'NAVIGATION_current_moleculeEvent',
              this.props.match.params.moleculeEvent,
            )

            const {
              open,
              openConfirmReset,
              openSelectionMultiDialog,
              resetUA,
              selection,
              selectionType,
              visibleRows,
              columns,
            } = this.state

            const selectionEmpty = this.state.selection.length === 0
            const selectionMulti = this.state.selectionType.length === 2

            const aiAssistantActivated =
              featuresConfig &&
              typeof featuresConfig?.content?.ai_assistant === 'boolean' &&
              featuresConfig?.content?.ai_assistant

            if (isDatabaseOffline) {
              return <EmptyCompound />
            }

            if (authenticationError) {
              // logout(this.props)
              // displayPopupMessage(dispatch, 'error', 'Batch not found', `Can not get batch.`)
              return dispatch(fetchChangeSettings('loginPopup', true))
            }
            // if (batchNotFound) {
            //   displayPopupMessage(
            //     dispatch,
            //     'warning',
            //     t('view.compound.compound.not_found_title'),
            //     <Trans i18nKey="view.compound.compound.not_found_msg">
            //       The batch with id {{ val: this.props.match.params.batchId }} can not be found.
            //     </Trans>,
            //   )
            //   this.props.history.push('/')
            // }
            // if (!batch || isFetchingConfigFeatures) {
            //   return <Loading mode="alpha" size={10} />
            // }

            // testBatchLease(batch, dispatch, this.props.history)

            const handleClose = function (event, multiAnalyse) {
              const { itemToUpdate, newMainChannel } = this.state
              this.setState({ open: false })
              if (typeof multiAnalyse === 'boolean')
                updateMainChannel(dispatch, newMainChannel, itemToUpdate, 'compound', multiAnalyse)
            }.bind(this)

            const onSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_navigation_secondaryPaneSize',
                this.navigationRef.current.state.secondaryPaneSize,
              )
            }.bind(this)

            const onContentSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize',
                this.contentRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentCustomSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize_custom',
                this.contentCustomRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentOneSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize_one',
                this.contentCustomRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentFirstSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_first_secondaryPaneSize',
                this.contentFirstCntRef.current.state.secondaryPaneSize,
              )
            }.bind(this)

            const onContentCalibrationSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_calibration_secondaryPaneSize',
                this.contentCalibrationRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentCustomCalibrationSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_calibration_secondaryPaneSize_custom',
                this.contentCustomCalibrationRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentOneCalibrationSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_calibration_secondaryPaneSize_one',
                this.contentCustomCalibrationRef.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentCustomAnalysisDetailSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize_custom_analysisDetail',
                this.contentCustomAnalysisDetail.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            const onContentOneAnalysisDetailSplitterSizeSave = function () {
              localStorage.setItem(
                'LAYOUT_compound_secondaryPaneSize_custom_analysisDetail',
                this.contentCustomAnalysisDetail.current.state.secondaryPaneSize,
              )
              this.refGridChromato.current.updateCharts()
            }.bind(this)

            if (localStorage.getItem('NAVIGATION_current_analysisId')) {
              this.applyFromSelection()
            }

            function saveSnapshot(apiRef) {
              const stateSnapshot = apiRef.exportState()
              if (Object.keys(stateSnapshot).length !== 0) {
                localStorage.setItem('ANALYSIS_state', JSON.stringify(stateSnapshot))
              }
            }

            const tableComponent = (
              <Paper
                elevation={0}
                style={{ height: '100%', width: '100%' }}
                className={classes.multiGrid}
                id="myCompoundMoleculesTable"
              >
                <ErrorBoundaryGuard>
                  <ProfilerContainer name="Compound table">
                    <ChromatoTable
                      ref={this.tableMultiGridRef}
                      chromatoGridRef={this.refGridChromato} // Ref to the chromato grid for selection
                      datas={compounds}
                      columns={columns}
                      isFetching={isFetchingCompounds}
                      onMoleculeClick={this.onMoleculeClickSignature}
                      onMoleculeDoubleClick={this.onMoleculeDBLClickSignature}
                      onSelect={this.onSelect}
                      sortAction={this.sortAction}
                      tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                      canEditCalib={this.canEditCalib()}
                      updateVisibleRowsIds={this.setVisibleRows}
                      scrollTo={this.state.scrollTableId}
                      onOpenMultiSelectionDialog={this.openSelectionMultipleDialog}
                    />
                  </ProfilerContainer>
                </ErrorBoundaryGuard>
                {!selectionEmpty && (
                  <span
                    className={classes.toolbar}
                    style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                  >
                    <div>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {selectionMulti
                              ? t('view.compound.compound.selection_as_detect_ok')
                              : selectionType[0].name === 'okKo'
                              ? t('view.compound.compound.selection_as_ok')
                              : t('view.compound.compound.selection_as_detect')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          style={selectionMulti ? { width: 67 } : {}}
                          aria-label="detected"
                          onClick={() => this.multiValidate(1)}
                        >
                          {selectionMulti ? (
                            <Fragment>
                              <Molecule /> / <CheckIcon />
                            </Fragment>
                          ) : selectionType[0].name === 'okKo' ? (
                            <CheckIcon />
                          ) : (
                            <Molecule />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {selectionMulti
                              ? t('view.compound.compound.selection_as_notdetect_ko')
                              : selectionType[0].name === 'okKo'
                              ? t('view.compound.compound.selection_as_ko')
                              : t('view.compound.compound.selection_as_notdetect')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          style={selectionMulti ? { width: 67 } : {}}
                          aria-label="notDetected"
                          onClick={() => this.multiValidate(2)}
                        >
                          {selectionMulti ? (
                            <Fragment>
                              <Nothing /> / <ClearIcon />
                            </Fragment>
                          ) : selectionType[0].name === 'okKo' ? (
                            <ClearIcon />
                          ) : (
                            <Nothing />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.compound.compound.relaunch')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          aria-label="relaunch"
                          onClick={() => this.multiValidate(3)}
                        >
                          <Relance />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </span>
                )}
              </Paper>
            )
            const chromatoComponent = (
              <ErrorBoundaryGuard>
                <GridMultiGrid
                  aiAssistantActivated={aiAssistantActivated}
                  featuresConfig={featuresConfig}
                  tableGridRef={this.tableMultiGridRef} // Ref to the table for selection
                  selection // Active or not the checkbox for selection
                  batch={batch}
                  wrappedComponentRef={this.refGridChromato}
                  onChromatoClick={(item) => this.scrollToTable(item)}
                  data={
                    layout === 'one'
                      ? compounds
                          .filter((row) =>
                            visibleRows !== null ? visibleRows.includes(row._id) : true,
                          )
                          .filter((d) => d._id === this.state.tableItemSelectedForColor)
                      : compounds.filter((row) =>
                          visibleRows !== null ? visibleRows.includes(row._id) : true,
                        )
                  }
                  store={store}
                  collection={'compound'}
                  isFetchingData={isFetchingCompounds}
                  title={function (item) {
                    return item.analyse
                  }}
                  onChangeChannel={(event, uaId, mainChannel) =>
                    this.onChangeChannel(event, uaId, mainChannel)
                  }
                  getAnalyseFunction={getUnitaryAnalysisCompoundById}
                  istd={allUnitaryAnalysesCompoundIstd}
                  isFetchingIstd={isFetchingAllUnitaryAnalysesCompoundIstd}
                />
              </ErrorBoundaryGuard>
            )
            const calibrationComponent = (
              <ErrorBoundaryGuard>
                <Calibration
                  batch={batch}
                  wrappedComponentRef={this.refCalibration}
                  collection={'compound'}
                  onCalibrationPointClick={(point) => this.onCalibrationPointClick(point)}
                />
              </ErrorBoundaryGuard>
            )

            const innerCmpNavigation = (
              <ErrorBoundaryGuard>
                {batch ? <Navigation batch={batch} /> : <Loading mode="miniCircle" size={10} />}
              </ErrorBoundaryGuard>
            )
            const innerCmp = (
              <SplitterLayout
                primaryIndex={1}
                secondaryInitialSize={0}
                customClassName={pinDrawer ? classes.mainSplitterFull : classes.mainSplitter}
              >
                <Fragment></Fragment>
                <SplitterLayout
                  vertical
                  primaryIndex={1}
                  secondaryInitialSize={120}
                  customClassName={classes.centerSplitter}
                >
                  <ErrorBoundaryGuard>
                    {batch ? (
                      <Information
                        batch={batch}
                        tableMultiGridRef={tableMultiGridRef}
                        parentMultiGridRef={parentMultiGridRef}
                        idTable={idTableConst}
                        propsColumns={columns}
                      />
                    ) : (
                      <Loading mode="miniCircle" size={10} />
                    )}
                  </ErrorBoundaryGuard>
                  {layout === 'default' ? (
                    <SplitterLayout
                      vertical
                      percentage
                      customClassName={classes.containerSplitter}
                      ref={this.contentRef}
                      onDragEnd={onContentSplitterSizeSave}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_compound_secondaryPaneSize')
                          ? parseFloat(localStorage.getItem('LAYOUT_compound_secondaryPaneSize'))
                          : 50
                      }
                    >
                      {tableComponent}
                      <SplitterLayout
                        customClassName={classes.deeperSplitter}
                        ref={this.contentCalibrationRef}
                        onDragEnd={onContentCalibrationSplitterSizeSave}
                        primaryMinSize={580}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_calibration_secondaryPaneSize')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_calibration_secondaryPaneSize'),
                              )
                            : 300
                        }
                      >
                        {chromatoComponent}
                        {calibrationComponent}
                      </SplitterLayout>
                    </SplitterLayout>
                  ) : layout === 'custom' ? (
                    <SplitterLayout
                      customClassName={classes.containerCustomSplitter}
                      ref={this.contentCustomRef}
                      onDragEnd={onContentCustomSplitterSizeSave}
                      primaryMinSize={580}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_compound_secondaryPaneSize_custom')
                          ? parseFloat(
                              localStorage.getItem('LAYOUT_compound_secondaryPaneSize_custom'),
                            )
                          : 300
                      }
                    >
                      {chromatoComponent}
                      <SplitterLayout
                        vertical
                        percentage
                        customClassName={classes.deeperCustomSplitter}
                        ref={this.contentCustomCalibrationRef}
                        onDragEnd={onContentCustomCalibrationSplitterSizeSave}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_custom')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_custom'),
                              )
                            : 50
                        }
                      >
                        {tableComponent}
                        {calibrationComponent}
                      </SplitterLayout>
                    </SplitterLayout>
                  ) : (
                    <SplitterLayout
                      customClassName={classes.containerOneSplitter}
                      ref={this.contentCustomRef}
                      onDragEnd={onContentOneSplitterSizeSave}
                      primaryMinSize={200}
                      secondaryMinSize={300}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_compound_secondaryPaneSize_one')
                          ? parseFloat(
                              localStorage.getItem('LAYOUT_compound_secondaryPaneSize_one'),
                            )
                          : 300
                      }
                    >
                      {chromatoComponent}
                      <SplitterLayout
                        vertical
                        percentage
                        customClassName={classes.deeperCustomSplitterBis}
                        ref={this.contentCustomCalibrationRef}
                        onDragEnd={onContentOneCalibrationSplitterSizeSave}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_one')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_calibration_secondaryPaneSize_one'),
                              )
                            : 50
                        }
                      >
                        {tableComponent}
                        <SplitterLayout
                          customClassName={classes.deeperCustomSplitterDetail}
                          ref={this.contentCustomAnalysisDetail}
                          onDragEnd={onContentOneAnalysisDetailSplitterSizeSave}
                          percentage
                          primaryMinSize={20}
                          secondaryMinSize={20}
                          secondaryInitialSize={
                            localStorage.getItem(
                              'LAYOUT_compound_secondaryPaneSize_custom_analysisDetail',
                            )
                              ? parseFloat(
                                  localStorage.getItem(
                                    'LAYOUT_compound_secondaryPaneSize_custom_analysisDetail',
                                  ),
                                )
                              : 50
                          }
                        >
                          {calibrationComponent}
                          <ErrorBoundaryGuard>
                            <AnalysisDetail
                              items={compounds.filter(
                                (d) => d._id === this.state.tableItemSelectedForColor,
                              )}
                              batch={batch}
                            />
                          </ErrorBoundaryGuard>
                        </SplitterLayout>
                      </SplitterLayout>
                    </SplitterLayout>
                  )}
                </SplitterLayout>
              </SplitterLayout>
            )

            return (
              <Suspense fallback={<Loading mode="miniCircle" />}>
                <>
                  <ErrorBoundaryGuard isDialog>
                    <RetiredCmp batch={batch} />
                  </ErrorBoundaryGuard>
                  <ErrorBoundaryGuard isDialog>
                    <CompoundMultiSelectionDialog
                      open={openSelectionMultiDialog}
                      closeFct={this.handleMultiSelectionConfirm}
                      selection={selection}
                      selectionType={selectionType}
                    />
                  </ErrorBoundaryGuard>
                  <Dialog
                    open={openConfirmReset}
                    onClose={(event) => this.handleConfirmResetClose(event, false)}
                    TransitionComponent={TransitionTop}
                    aria-labelledby="dialog-reset-title"
                  >
                    <StyledDialogTitle>
                      {t('view.compound.compound.reset_ua.title')}
                    </StyledDialogTitle>
                    <DialogContent>
                      <DialogContentText style={{ margin: 0 }}>
                        {t('view.compound.compound.reset_ua.msg')}
                      </DialogContentText>
                      <Chip
                        key={resetUA ? resetUA.name : ''}
                        className={classes.moveChip}
                        label={resetUA ? resetUA.name : ''}
                      />
                      <DialogContentText style={{ margin: 0, marginTop: 15, paddingLeft: 34 }}>
                        <WarningIcon className={classes.warning} />
                        <Trans i18nKey="view.compound.compound.reset_ua.warning">
                          This action will be reset the <i>calibration</i>, the <i>reintegration</i>
                          , the <i>main channel</i> and the <i>validation</i>.
                        </Trans>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={(event) => this.handleConfirmResetClose(event, false)}
                        color="primary"
                      >
                        {t('view.compound.compound.reset_ua.cancel')}
                      </Button>
                      <Button
                        onClick={(event) => this.handleConfirmResetClose(event, true)}
                        color="primary"
                      >
                        {t('view.compound.compound.reset_ua.yes')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={TransitionTop}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <StyledDialogTitle>
                      {t('view.compound.compound.main_ch_update.title')}
                    </StyledDialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {t('view.compound.compound.main_ch_update.msg')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={(event) => handleClose(event, true)}
                        color="primary"
                      >
                        {t('view.compound.compound.main_ch_update.all')}
                      </Button>
                      <Button onClick={(event) => handleClose(event, false)} color="primary">
                        {t('view.compound.compound.main_ch_update.current')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <div
                    style={{ height: 'inherit', width: 'inherit', outline: 'none' }}
                    ref={(element) => {
                      this.elementRef = element
                    }}
                    tabIndex={0}
                  >
                    {pinDrawer ? (
                      <SplitterLayout
                        customClassName={classes.firstSplitter}
                        ref={this.contentFirstCntRef}
                        onDragEnd={onContentFirstSplitterSizeSave}
                        secondaryMinSize={DRAWER_WIDTH()}
                        primaryMinSize={600}
                        primaryIndex={1}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_first_secondaryPaneSize')
                            ? parseFloat(localStorage.getItem('LAYOUT_first_secondaryPaneSize'))
                            : DRAWER_WIDTH()
                        }
                      >
                        {innerCmpNavigation}
                        {innerCmp}
                      </SplitterLayout>
                    ) : (
                      <Fragment>
                        {innerCmpNavigation} {innerCmp}
                      </Fragment>
                    )}
                  </div>
                </>
              </Suspense>
            )
          }
        },
      ),
    ),
  ),
)
