import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import cache from './cache/reducers'
import batches from './batches/reducers'
import analyses from './analyses/reducers'
import unitaryAnalyses from './unitaryAnalyses/reducers'
import unitaryCalibrations from './unitaryCalibrations/reducers'
import message from './message/reducers'
import detail from './detail/reducers'
import drawer from './drawer/reducers'
import lims from './lims/reducers'
import tasks from './tasks/reducers'
import auditTrail from './auditTrail/reducers'
import screening from './screening/reducers'
import json from './json/reducers'
import filters from './filters/reducers'
import test from './test/reducers'
import teams from './teams/reducers'
import settings from './settings/reducers'
import configuration from './configuration/reducers'
import global from './global/reducers'
import logs from './logs/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import accountings from './accountings/reducers'
import codDriveConfiguration from './codDriveConfiguration/reducers'
import assistant from './assistant/reducers'
import prefetch from './prefetch/reducers'
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
  initStateWithPrevTab,
} from 'redux-state-sync'

const rootReducer = combineReducers({
  cache,
  batches,
  analyses,
  unitaryAnalyses,
  unitaryCalibrations,
  message,
  settings,
  detail,
  drawer,
  lims,
  tasks,
  teams,
  auditTrail,
  screening,
  json,
  filters,
  test,
  configuration,
  global,
  logs,
  accountings,
  codDriveConfiguration,
  assistant,
  prefetch,
})
const configSyncMiddleware = {
  // TOGGLE_TODO will not be triggered in other tabs
  // blacklist: ['TOGGLE_TODO'],
  whitelist: [
    'CHANGE_SETTING',
    'GET_AI_MESSAGES',
    'ADD_AI_MESSAGE',
    'RECEIVE_AI_MESSAGE',
    'ADD_USER_MESSAGE',
    'CLEAR_AI_MESSAGES',
    'RECEIVE_ASSISTANT_STATUS',
    'TELL_ME_MORE',
    'ASSISTANT_WINDOW_OPENED',
    'ASSISTANT_WINDOW_CLOSED',
    'DISPLAY_DETAIL',
    'DETAIL_WINDOW_OPENED',
    'DETAIL_WINDOW_CLOSED',
  ],
  // predicate: action => action.type !== 'TOGGLE_TODO',
  // channel: 'my_broadcast_channel',
  broadcastChannelOption: {
    type: 'localstorage', //['native', 'idb', 'localstorage', 'node']
    webWorkerSupport: true,
  },
  initiateWithState: true,
  // prepareState: (state) => ({ assistant: state.assistant }),
}
const loggerMiddleware = createLogger()
const syncMiddleware = createStateSyncMiddleware(configSyncMiddleware)

const middlewares = [
  thunkMiddleware,
  syncMiddleware,
  // loggerMiddleware
]

const store = createStore(
  withReduxStateSync(rootReducer),
  {},
  composeWithDevTools(applyMiddleware(...middlewares)),
)
initStateWithPrevTab(store)
// initMessageListener(store)

// export default function configureStore(preloadedState) {
//   return createStore(
//     rootReducer,
//     preloadedState,
//     composeWithDevTools(applyMiddleware(...middlewares)),
//   )
// }

// export default function configureStore() {
//   return store
// }
export default store
