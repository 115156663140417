import React, { Fragment, useMemo } from 'react'
import { genericStyles } from './Style'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CardImage from '../../resources/images/card_login.jpg'
import Loading from '../Loading'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { API_URL, DATABASE, GET_TORII_BASE_URL } from '../../utils/config'
import {
  isOnChrome,
  getParentDomain,
  testServiceAvailability,
  testDatabaseAvailability,
} from './Utils'
import { displayPopupMessage, generateErrorPart } from './Message'
import Container from '@material-ui/core/Container'
import {
  Card,
  CssBaseline,
  FormGroup,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { fetchAddMessage } from '../../redux/message/actions'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@mui/material'
import { fetchGlobalConfigurations } from '../../redux/global/actions'
import { fetchConfigurations } from '../../redux/configuration/actions'

const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    // height: isMobile ? '100%' : '70%',
    width: 396,
    backgroundColor: 'transparent',
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginBottom: 12,
  },
  card: {
    // marginTop: theme.spacing(10),
  },
  cardImg: {
    // position: 'absolute',
    // top: 17,
    // height: 110,
    // width: 396,
    // objectFit: 'none',
    background: 'lightblue',
  },
  copyright: {
    marginTop: 10,
  },
  important: {
    fontSize: '0.9rem',
  },
  checkbox: {
    fontSize: '0.8rem',
    marginLeft: 8,
  },
  passwordField: {
    marginTop: 5,
    '& legend > span': {
      paddingRight: 13,
    },
    '& .Mui-focused button': {
      color: theme.palette.primary.main,
    },
    '& .MuiIconButton-root:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      transition: 'color 150ms ease-in',
    },
  },
  // video_background: {
  //   height: 141,
  //   width: '100%',
  //   objectFit: 'cover',
  //   backgroundImage: 'url(' + backgroundLoading + ')',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  // },
  title: {
    color: theme.palette.primary.dark,
  },
}))
const LoginView = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const genericClasses = genericStyles()
  const userPreferencesRef = React.createRef()
  const dispatch = useDispatch()
  const { history, onValidSuccess, fromDialog } = props
  const manualLogin = !(localStorage.getItem('__config.json__MANUAL_LOGIN') === 'false')
  const azureLogin = !(localStorage.getItem('__config.json__AZURE_LOGIN') === 'false')
  const [selectionModel, setSelectionModel] = React.useState([])
  const [showPassword, setShowPassword] = React.useState(false)
  const [waitingLogin, setWaitingLogin] = React.useState(false)
  const [cookiesChecked, setCookiesChecked] = React.useState(
    localStorage.getItem('LOGIN_cookies_accepted') === 'true',
  )
  const [eulaChecked, setEulaChecked] = React.useState(
    localStorage.getItem('LOGIN_eula_accepted') === 'true',
  )
  const [loginUser, setLoginUser] = React.useState()
  const [loginPassword, setLoginPassword] = React.useState()

  function _handleUserChange(e) {
    setLoginUser(e.target.value)
  }
  function _handlePasswordChange(e) {
    setLoginPassword(e.target.value)
  }
  function _keyPress(e) {
    if (e.keyCode === 13 && cookiesChecked && eulaChecked) {
      TestLogin()
    }
  }
  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }
  function handleMouseDownPassword(event) {
    event.preventDefault()
  }

  function TestLogin() {
    const body = {
      userName: loginUser,
      password: loginPassword,
      persist: true,
    }

    setWaitingLogin(true)
    try {
      fetch(`${API_URL()}/sessions/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            testServiceAvailability(dispatch)
            testDatabaseAvailability(dispatch)
              .then((dbLoaded) => {
                // Load global configs
                dispatch(fetchGlobalConfigurations())
                // Load configurations from DB
                dispatch(fetchConfigurations(DATABASE()))
              })
              .catch(() => {
                console.error('No DB loaded')
              })

            const session = await response.json()
            localStorage.setItem('SESSION_user_name', loginUser)
            // Save the user object
            localStorage.setItem('SESSION_user_json', JSON.stringify(session.cachedUser))
            dispatch(
              fetchAddMessage({
                title: localStorage.getItem('SESSION_user_name'),
                message: t('view.panel.toolbar.message.connexion'),
                type: 'success',
                date: new Date().getTime(),
                isNew: false,
                connexionMessage: true,
              }),
            )
            // Check if ToriiSessionId cookie exists and has a value
            var cookies = Object.fromEntries(
              document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
            )
            // Update SESSION_user_name and SESSION_user_json if cookie is valid
            if (cookies.ToriiSessionId == null || cookies.ToriiSessionId === '') {
              const d = new Date()
              d.setTime(d.getTime() + 1 * 60 * 60 * 1000) // Days (removed) x 24 Hours x 60 Minutes x 60 Seconds x 1000 Milliseconds
              document.cookie =
                'ToriiSessionId=' +
                session._id +
                ';expires=' +
                d.toUTCString() +
                ';path=/;SameSite=None;Secure;Domain=' +
                getParentDomain(window.location.hostname)
            }
            localStorage.setItem('LOGIN_cookies_accepted', 'true')
            localStorage.setItem('LOGIN_eula_accepted', 'true')
            setWaitingLogin(false)
            if (
              localStorage.getItem('SERVICE_pythonService_name') === undefined ||
              localStorage.getItem('SERVICE_pythonService_name') === null
            ) {
              let filter = [
                {
                  property: 'tags',
                  value: localStorage.getItem('__config.json__ALPHACOD_SERVICE_TAG'),
                  filterType: 'string',
                  operator: 'like',
                },
              ]
              let f = encodeURIComponent(JSON.stringify(filter))
              fetch(`${API_URL()}/pythonServices?filter=${f}`, {
                method: 'GET',
                credentials: 'include',
              })
                .then(async (response) => {
                  const pythonServices = await response.json()
                  if (pythonServices[0]) {
                    localStorage.setItem('SERVICE_pythonService_name', pythonServices[0].name)
                  }
                })
                .finally(() => {
                  if (!fromDialog) {
                    isOnChrome(dispatch)
                  }
                  dispatch(fetchChangeSettings('loginPopup', false))
                  onValidSuccess()
                  // // Save the default database name
                  // getFlaskDataBase(dispatch, localStorage.getItem('SERVICE_pythonService_name'))
                  //   .then(() => {
                  //     if (!fromDialog) {
                  //       isOnChrome(dispatch)
                  //     }
                  //   })
                  //   .catch(function (error) {
                  //     if (!fromDialog) {
                  //       isOnChrome(dispatch)
                  //     }
                  //   })
                  //   .finally(() => {
                  //     dispatch(fetchChangeSettings('loginPopup', false))
                  //     onValidSuccess()
                  //   })
                })
            } else {
              if (!fromDialog) {
                isOnChrome(dispatch)
              }
              dispatch(fetchChangeSettings('loginPopup', false))
              onValidSuccess()
              setWaitingLogin(false)
            }
          } else {
            // displayPopupMessage(
            //   dispatch,
            //   'error',
            //   t('view.common.loginView.error_title'),
            //   <Fragment>
            //     <div>{t('view.common.loginView.error_msg1')}</div>
            //     <div>{t('view.common.loginView.error_msg2')}</div>
            //     <div style={{ marginTop: 15 }}>{response.status + ': ' + response.statusText}</div>
            //   </Fragment>,
            // )
            // setWaitingLogin(false)

            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
        })
        .catch(function (error) {
          // displayPopupMessage(
          //   dispatch,
          //   'error',
          //   t('view.common.loginView.error_title'),
          //   <Fragment>
          //     <div>{t('view.common.loginView.error_msg1')}</div>
          //     <div>{t('view.common.loginView.error_msg2')}</div>
          //     <div style={{ marginTop: 15 }}>{error.toString()}</div>
          //   </Fragment>,
          // )
          displayPopupMessage(
            dispatch,
            'error',
            t('view.common.loginView.error_title'),
            <Fragment>
              <div>{t('view.common.loginView.error_msg1')}</div>
              <div>{t('view.common.loginView.error_msg2')}</div>
              {generateErrorPart(error)}
            </Fragment>,
            30000,
          )
          setWaitingLogin(false)
        })
    } catch (e) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.loginView.error_title'),
        <Fragment>
          <div>{t('view.common.loginView.error_msg1')}</div>
          {/*<div>{e.toString()}</div>*/}
          {/*{t('view.common.loginView.error_contact')}*/}
          {generateErrorPart(e)}
        </Fragment>,
        30000,
      )
      setWaitingLogin(false)
    }
  }

  function loginMicrosoftOauth() {
    window.location.href = `${GET_TORII_BASE_URL()}/microsoftLogin`
  }

  function handleCookiesChange(event) {
    setCookiesChecked(event.target.checked)
  }

  function handleEulaChange(event) {
    setEulaChecked(event.target.checked)
  }

  return (
    <Container component="main" style={{ padding: 0 }}>
      <CssBaseline />
      <Card className={classes.card}>
        {/*<CardMedia*/}
        {/*  className={classes.cardImg}*/}
        {/*  component="img"*/}
        {/*  alt="header image"*/}
        {/*  height="140"*/}
        {/*  image={CardImage}*/}
        {/*  title="Header image"*/}
        {/*/>*/}
        {/*<video autoPlay loop muted className={classes.video_background}>*/}
        {/*  <source src={sample} type="video/mp4"></source>*/}
        {/*</video>*/}
        <CardMedia
          className={classes.cardImg}
          component="img"
          alt="header image"
          height="140"
          image={CardImage}
          title="Header image"
        />
        <CardContent>
          <div className={classes.paper}>
            {localStorage.getItem('__config.json__APPLICATION_SUBTITLE') === '' ? (
              <Typography
                className={classes.title}
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
              >
                {localStorage.getItem('__config.json__APPLICATION_TITLE')}
              </Typography>
            ) : (
              <div className={classes.title}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          style={{
                            lineHeight: '20px',
                            textAlign: 'center',
                          }}
                        >
                          {localStorage.getItem('__config.json__APPLICATION_TITLE')}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          style={{ fontSize: '0.7em', float: 'right', lineHeight: '10px' }}
                        >
                          {localStorage.getItem('__config.json__APPLICATION_SUBTITLE')}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <form className={classes.form} noValidate>
              {manualLogin && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="user"
                  label={t('view.common.loginView.username')}
                  name="user"
                  autoComplete="user"
                  autoFocus
                  onChange={_handleUserChange}
                  onKeyDown={_keyPress}
                />
              )}
              {manualLogin && (
                <FormControl className={classes.passwordField} fullWidth variant="outlined">
                  <InputLabel htmlFor="password">
                    {t('view.common.loginView.password')} *
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={_handlePasswordChange}
                    onKeyDown={_keyPress}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    margin="none"
                    required
                    fullWidth
                    name="password"
                    label={t('view.common.loginView.password')}
                    autoComplete="current-password"
                  />
                </FormControl>
              )}
              <FormGroup>
                <FormControlLabel
                  style={{ marginTop: 15, marginLeft: 0, marginRight: 20 }}
                  control={
                    <Checkbox
                      size="small"
                      checked={cookiesChecked}
                      onChange={handleCookiesChange}
                    />
                  }
                  label={
                    <Typography className={classes.checkbox} align="justify">
                      {t('view.common.loginView.cookies')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginTop: 5, marginLeft: 0, marginRight: 20 }}
                  control={
                    <Checkbox size="small" checked={eulaChecked} onChange={handleEulaChange} />
                  }
                  label={
                    <Typography className={classes.checkbox} align="justify">
                      {t('view.common.loginView.eula')}{' '}
                      <Link
                        target="_blank"
                        rel="noopener"
                        style={{ fontStyle: 'italic' }}
                        color="inherit"
                        href={
                          localStorage.getItem('__config.json__ALPHACOD_WIKI_URL') +
                          'doku.php?id=fujitsu:alphacod:prod:en:eula'
                        }
                      >
                        End User License Agreement
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>

              {/*<FormControlLabel*/}
              {/*  control={<Checkbox value="remember" color="primary" />}*/}
              {/*  label="Remember me"*/}
              {/*/>*/}
              <Box my={3}>
                <Typography className={classes.important}>
                  {t('view.common.loginView.important')}
                </Typography>
                <Typography className={classes.important} align="justify">
                  {t('view.common.loginView.text')}
                </Typography>
              </Box>
              {waitingLogin ? (
                <Loading
                  mode="mini3dots"
                  style={{ width: '100%', height: 108, top: -19, left: 0, transform: 'none' }}
                />
              ) : (
                <Fragment>
                  {manualLogin && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => TestLogin()}
                      disabled={!cookiesChecked || !eulaChecked}
                    >
                      {t('view.common.loginView.sign_in')}
                    </Button>
                  )}
                  {manualLogin && azureLogin && (
                    <div style={{ height: 12, borderTop: '1px solid #c4c4c4' }}></div>
                  )}
                  {azureLogin && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => loginMicrosoftOauth()}
                      disabled={!cookiesChecked || !eulaChecked}
                    >
                      {t('view.common.loginView.sign_in_microsoft')}
                    </Button>
                  )}
                </Fragment>
              )}
              <Box className={classes.copyright}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {'Copyright © '}
                  <Link color="inherit" href="https://www.fujitsu.fr/">
                    Fujitsu Systems Europe
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Box>
            </form>
          </div>
        </CardContent>
      </Card>
    </Container>
  )
})
export default LoginView
