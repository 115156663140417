import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { connect, useDispatch, useSelector } from 'react-redux'
import { createTheme as createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Login from './Login'
import Logs from './Logs'
import Dashboard from './Dashboard'

import background from '../resources/images/background_light.webp'
import { Chart } from 'react-chartjs-2'
import Clean from './Clean'
import { API_URL, DATABASE, GET_PRIMARY_COLOR, GET_SECONDARY_COLOR } from '../utils/config'
import { fetchConfigurationFeatures, fetchConfigurations } from '../redux/configuration/actions'
import { fetchAddMessage } from '../redux/message/actions'
import { withTranslation } from 'react-i18next'
import { fetchGlobalConfigurations } from '../redux/global/actions'
import AIAssistant from './dialog/AIAssistant/AIAssistant'
import DetailWindow from './common/detail/DetailWindow'

const getContrastYIQ = function (hexcolor) {
  var r = parseInt(hexcolor.substr(1, 2), 16)
  var g = parseInt(hexcolor.substr(3, 2), 16)
  var b = parseInt(hexcolor.substr(5, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 170 ? '#000' : '#FFF'
}

// Creating theme for material UI. See https://material-ui.com/customization/palette/#palette
const createTheme = function () {
  return createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: GET_PRIMARY_COLOR(),
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: '#672fa7',
        main: GET_SECONDARY_COLOR(),
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
        contrastText: getContrastYIQ(GET_SECONDARY_COLOR()),
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,

      common: {
        success: '#20a91b',
        error: '#c70505',
        warning: '#e68d09',
        info: '#8d8d8d',
        infoBlue: '#547fa8',
      },
    },
  })
}

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  img.background-container {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.4;
  }
`

const PrivateRoute = ({ dispatch, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.getItem('SESSION_user_name')) {
        return <Component {...props} />
      }
      // Check if ToriiSessionId cookie exists and has a value
      var cookies = Object.fromEntries(
        document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
      )
      // Update SESSION_user_name and SESSION_user_json if cookie is valid
      if (cookies.ToriiSessionId != null && cookies.ToriiSessionId !== '') {
        fetch(`${API_URL()}/sessions/check`, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          if (response.ok) {
            const session = await response.json()
            // Save the Username
            var username = session.cachedUser.name
            localStorage.setItem('SESSION_user_name', username)
            // Save the user object
            localStorage.setItem('SESSION_user_json', JSON.stringify(session.cachedUser))
            // Refresh page to properly load User login
            window.location.reload()
            return
          }
        })
      }
      // Refresh page to properly load User login
      // window.location.reload();
      // return;
      // else redirect to login page
      return <Redirect to={{ pathname: '/?login', state: { from: props.location } }} />
      // return <Component {...props} />
    }}
  />
)

const mapStateToProps = (state) => {
  const { settings, global } = state
  return {
    configJsonLoaded: settings.configJsonLoaded,
    featuresConfig: global.featuresConfig,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withTranslation()(
      class Container extends React.Component {
        constructor(props, context) {
          super(props, context)
          this.state = {
            open: false,
            severity: 'info',
            message: '',
          }
        }
        componentDidMount() {
          document.body.style.userSelect = localStorage.getItem('SETTINGS_html_selection')
            ? localStorage.getItem('SETTINGS_html_selection')
            : 'none'

          function computeCoeffConst(xy_left, xy_right) {
            // y = coeff * x + konst
            let coeff_dir =
              xy_right.y - xy_left.y === 0 ? 0 : (xy_right.y - xy_left.y) / (xy_right.x - xy_left.x)
            let konst = xy_left.y - coeff_dir * xy_left.x
            // let y = coeff_dir * x + konst
            // console.log('coeff=' + coeff_dir + ', const=' + konst)
            return [coeff_dir, konst]
          }

          function computeY_with_coeff(x, coeff, konst, y_offset) {
            return Math.min(coeff * x + konst, y_offset)
          }

          function computeY_with_2_pts(x, xy_left, xy_right, y_offset) {
            let coeff_konst = computeCoeffConst(xy_left, xy_right)
            return coeff_konst[0] * x + coeff_konst[1]
            // return computeY_with_coeff(x, coeff_konst[0], coeff_konst[1], y_offset)
          }

          function computeOffset(xy_meta_pts, xy_data_pts) {
            let yp_left = { x: xy_data_pts[0].y, y: xy_meta_pts[0].y }
            let yp_right = { x: xy_data_pts[1].y, y: xy_meta_pts[1].y }
            // offset = coeff * y + konst => y=0 => offset = konst
            let coeff_const = computeCoeffConst(yp_left, yp_right)
            // console.log('Offset=' + coeff_const[1])
            return coeff_const[1]
          }

          function computeCurveY(x, XY_curve, coeff_baseline, konst_baseline, y_offset) {
            let x_index = XY_curve.findIndex((xy) => xy.x === x)
            // let y_baseline = coeff_baseline * x + konst_baseline
            let y_curve
            if (x_index >= 0) {
              y_curve = XY_curve[x_index].y
            } else {
              // Find 2 indexes around of x_index : before and after index
              let after_index_curve = XY_curve.findIndex((xy) => xy.x > x) // Find index after
              let before_index_curve = after_index_curve - 1
              // Compute linear interpolation (y_curve)
              if (
                XY_curve[before_index_curve] === undefined ||
                XY_curve[after_index_curve] === undefined
              ) {
                y_curve = computeY_with_2_pts(
                  x,
                  XY_curve[before_index_curve],
                  XY_curve[after_index_curve],
                  y_offset,
                )
              } else {
                y_curve = computeY_with_2_pts(
                  x,
                  XY_curve[before_index_curve],
                  XY_curve[after_index_curve],
                  y_offset,
                )
              }
            }
            return y_curve
            // return Math.max(y_curve, y_baseline, y_offset)
          }

          function computeIntersection(XY_curve, coeff_baseline, konst_baseline) {
            // console.log('Add intersection')
            let diff_tab = XY_curve.map((xy) => xy.y - coeff_baseline * xy.x - konst_baseline)
            diff_tab.pop() // remove last one
            // let diff_length = diff_tab.length
            let crossing_tab = diff_tab.map(function (_diff, _index) {
              if (_diff * diff_tab[_index + 1] < 0) {
                return _index
              }
              return 0
            })
            // Find intersection
            let xy_intersection = crossing_tab.map(function (intersect, index) {
              if (intersect !== 0) {
                // it's an intersection => computeCurveY == y_baseline == a * b + c
                // Get linear interpolation for index and index + 1 for curve: a * x + b
                let coeff_konst_curve = computeCoeffConst(XY_curve[index], XY_curve[index + 1])
                // Compute x_inter with intersection
                let x_inter =
                  (coeff_konst_curve[1] - konst_baseline) / (coeff_baseline - coeff_konst_curve[0])
                // Compute y_extra with y_extra value
                let y_inter = computeY_with_coeff(
                  x_inter,
                  coeff_konst_curve[0],
                  coeff_konst_curve[1],
                )
                return { index: index, x: x_inter, y: y_inter }
              }
            })
            // Remove undefined
            xy_intersection = xy_intersection.filter((element) => {
              return element !== undefined
            })
            // Update XY_curve
            let k = 0
            for (let i = 0; i < xy_intersection.length; ++i) {
              XY_curve.splice(xy_intersection[i].index + 1 + k, 0, {
                x: xy_intersection[i].x,
                y: xy_intersection[i].y,
              })
              k += 1
            }
            return { XY_curve_up: XY_curve, XY_intersection: xy_intersection }
          }

          Chart.pluginService.register({
            beforeDraw: function (chart) {
              if (
                chart.config.options.chartArea &&
                chart.config.options.chartArea.backgroundColor
              ) {
                var ctx = chart.chart.ctx
                var chartArea = chart.chartArea

                ctx.save()
                ctx.fillStyle = chart.config.options.chartArea.backgroundColor
                ctx.fillRect(
                  chartArea.left,
                  chartArea.top,
                  chartArea.right - chartArea.left,
                  chartArea.bottom - chartArea.top,
                )
                ctx.restore()
              }
            },
            afterDatasetsDraw: function (chart) {
              var ctx = chart.chart.ctx
              var datasets = chart.data.datasets
              ctx.save()

              // Draw if 2 datasets and not hidden and has peaks
              if (datasets.length >= 2) {
                for (var d = 0; d < datasets.length; d++) {
                  var dataset = datasets[d]
                  // fillBetweenSet is put on peaks dataset
                  if (dataset.fillBetweenSet === undefined) {
                    continue
                  }
                  // Get curve metadata
                  var meta_curve = chart.getDatasetMeta(d)
                  // Get Peaks metadata
                  var meta_baseline = chart.getDatasetMeta(dataset.fillBetweenSet)

                  // do not draw fill if one of the datasets is hidden and has peaks
                  if (
                    meta_curve.hidden ||
                    meta_baseline.hidden ||
                    dataset.data.length == 0 ||
                    datasets[dataset.fillBetweenSet].data.length === 0
                  ) {
                    continue
                  }

                  // Compute table: X, Y_curve, Y_baseline

                  // Get xy of 2 triangles: 2 pts ou + base du peaks 1er et dernier pts (2 pts min)
                  const xy_baseline_begin = {
                    x: meta_baseline.data[0]._view.x,
                    y: meta_baseline.data[0]._view.y,
                  }
                  const xy_baseline_end = {
                    x: meta_baseline.data[meta_baseline.data.length - 1]._view.x,
                    y: meta_baseline.data[meta_baseline.data.length - 1]._view.y,
                  }

                  // Compute offset
                  const xy_data_begin = datasets[dataset.fillBetweenSet].data[0]
                  const xy_data_end =
                    datasets[dataset.fillBetweenSet].data[
                      datasets[dataset.fillBetweenSet].data.length - 1
                    ]

                  const y_offset = computeOffset(
                    [xy_baseline_begin, xy_baseline_end],
                    [xy_data_begin, xy_data_end],
                  )

                  // Get all curve metada
                  const XY_curve_full = meta_curve.data.map((c) => ({ x: c._view.x, y: c._view.y }))
                  // console.log('Number of pts of curve: ' + XY_curve_full.length)
                  // Select the one between 2 peaks
                  let XY_curve = XY_curve_full.filter(
                    (xy) => xy.x <= xy_baseline_end.x && xy.x >= xy_baseline_begin.x,
                  )

                  // Add 2 x,y position of curve for 2 peaks
                  // const X_curve_full = XY_curve_full.map(xy => (xy.x))
                  const begin_index_curve = XY_curve_full.findIndex(
                    (xy) => xy.x >= xy_baseline_begin.x,
                  ) // Find index before
                  const end_index_curve =
                    XY_curve_full.findIndex((xy) => xy.x >= xy_baseline_end.x) - 1 // Find index after

                  // console.log('Number of pts between 2 peaks: ' + XY_curve.length)
                  if (
                    begin_index_curve > 0 &&
                    xy_baseline_begin !== undefined &&
                    XY_curve_full[begin_index_curve] !== undefined &&
                    XY_curve_full[begin_index_curve - 1] !== undefined &&
                    xy_baseline_begin.x < XY_curve_full[begin_index_curve].x
                  ) {
                    const y_left = computeY_with_2_pts(
                      xy_baseline_begin.x,
                      XY_curve_full[begin_index_curve - 1],
                      XY_curve_full[begin_index_curve],
                    )
                    // const y_left_2 = computeY_with_2_pts(xy_baseline_begin.x, XY_curve_full[begin_index_curve], XY_curve_full[begin_index_curve + 1])
                    // const y_left = Math.max(y_left_1, y_left_2)
                    // console.log('xy_baseline_begin.x=' + xy_baseline_begin.x +  'xy_baseline_begin.y' + xy_baseline_begin.y)
                    // console.log('y_offset=' + y_offset +  'y_start=' + y_left)
                    XY_curve.unshift({ x: xy_baseline_begin.x, y: y_left })
                  }

                  if (
                    end_index_curve > 0 &&
                    end_index_curve !== XY_curve_full.length - 1 &&
                    XY_curve_full[end_index_curve] !== undefined &&
                    XY_curve_full[end_index_curve + 1] !== undefined &&
                    xy_baseline_end !== undefined &&
                    xy_baseline_end.x > XY_curve_full[end_index_curve].x
                  ) {
                    const y_right = computeY_with_2_pts(
                      xy_baseline_end.x,
                      XY_curve_full[end_index_curve],
                      XY_curve_full[end_index_curve + 1],
                    )
                    XY_curve.push({ x: xy_baseline_end.x, y: y_right })
                  }
                  // console.log('Number of pts after add begin & end pts: ' + XY_curve.length)

                  // Compute coefficient and constant value for baseline (y = a * x + b)
                  const coeff_const_baseline = computeCoeffConst(xy_baseline_begin, xy_baseline_end)
                  const coeff_baseline = coeff_const_baseline[0]
                  const konst_baseline = coeff_const_baseline[1]

                  // Add instersection
                  let { XY_curve_up, XY_intersection } = computeIntersection(
                    XY_curve,
                    coeff_baseline,
                    konst_baseline,
                  )
                  // console.log('Number of pts  after add intersection: ' + XY_curve_up.length)
                  var X_curve = XY_curve_up.map((xy) => xy.x)

                  // Compute XY curve for color
                  let XY_curve_display = X_curve.map((x) => ({
                    x: x,
                    y: computeCurveY(x, XY_curve_up, coeff_baseline, konst_baseline, y_offset),
                  }))
                  // Compute XY_baseline
                  let XY_baseline_display = X_curve.map((x) => ({
                    x: x,
                    y: computeY_with_coeff(x, coeff_baseline, konst_baseline, y_offset),
                  }))

                  for (var p = 0; p < XY_curve_display.length - 1; p++) {
                    let curr_pt_curve = XY_curve_display[p]
                    let next_pt_curve = XY_curve_display[p + 1]

                    let curr_pt_baseline = XY_baseline_display[p]
                    let next_pt_baseline = XY_baseline_display[p + 1]

                    // console.log('curve['+ p + ']=(' + curr_pt_curve.x + ',' + curr_pt_curve.y + ')')
                    // console.log('baseline['+ p + ']=(' + curr_pt_baseline.x + ',' + curr_pt_baseline.y + ')')

                    // if null skip
                    if (curr_pt_curve == null || next_pt_curve == null) {
                      // Never
                      continue
                    } else if (
                      curr_pt_baseline.y < curr_pt_curve.y ||
                      next_pt_baseline.y < next_pt_curve.y
                    ) {
                      // do not draw fill if y is lower than the line of 2 peaks
                      continue
                    }

                    // Draw polygon
                    ctx.beginPath()
                    ctx.moveTo(curr_pt_curve.x, curr_pt_curve.y)
                    ctx.lineTo(curr_pt_curve.x, curr_pt_curve.y)
                    ctx.lineTo(next_pt_curve.x, next_pt_curve.y)
                    ctx.lineTo(next_pt_baseline.x, next_pt_baseline.y)
                    ctx.lineTo(curr_pt_baseline.x, curr_pt_baseline.y)
                    ctx.lineTo(curr_pt_curve.x, curr_pt_curve.y)
                    ctx.closePath()
                    ctx.fillStyle = dataset.fillBetweenColor || 'rgba(0,0,0,0.1)'
                    ctx.fill() // fill with shading
                  } // end for p loop
                } // end for d loop
              } // end of if datasets.length >= 2
            }, // end afterDatasetsDraw
          }) // end fillBetweenLinesPlugin)
        }
        render() {
          const { dispatch, classes, configJsonLoaded, match, featuresConfig } = this.props
          if (RegExp('\\?.+').test(this.props.location.search)) {
            const redirectUrl = this.props.location.search.split('?')[1]
            if (redirectUrl.toLowerCase() !== 'login') {
              this.props.location.pathname = '/' + redirectUrl
            }
          }
          const aiAssistantActivated =
            featuresConfig &&
            typeof featuresConfig?.content?.ai_assistant === 'boolean' &&
            featuresConfig?.content?.ai_assistant

          const theme = configJsonLoaded ? createTheme() : createMuiTheme({})

          return (
            <ThemeProvider theme={theme}>
              <Wrapper>
                <div
                  style={{
                    filter: 'blur(4px)',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                >
                  <img
                    id="APPLICATION_BACKGROUND_ID"
                    src={background}
                    alt="Background"
                    className="background-container"
                  />
                </div>
                {/*<TransitionGroup className={'transition-group'}>*/}
                {/*  <CSSTransition*/}
                {/*    key={this.props.location.key}*/}
                {/*    // timeout={{ enter: 300, exit: 300 }}*/}
                {/*    // classNames="fade"*/}
                {/*  >*/}
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route exact path="/?login" component={Login} />
                    <Route exact path="/clean" component={Clean} />
                    {aiAssistantActivated && (
                      <Route exact path="/assistant" component={AIAssistant} />
                    )}
                    <Route
                      path={`${match.path}:dataBaseName/detail/:unitaryAnalysisId?`}
                      component={DetailWindow}
                    />
                    {/*<Route path={`${match.path}coddrive/:codDriveId?`} component={CodDrive} />*/}
                    <Route
                      path={`${match.path}:dataBaseName/logs/:logsType?/:typeId?`}
                      component={Logs}
                    />
                    <PrivateRoute path="/" component={Dashboard} dispatch={dispatch} />
                  </Switch>
                </section>
                {/*  </CSSTransition>*/}
                {/*</TransitionGroup>*/}
              </Wrapper>
            </ThemeProvider>
          )
        }
      },
    ),
  ),
)
