import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import clsx from 'clsx'
import { t } from 'i18next'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import { DATABASE, PUBLIC_URL } from '../../../utils/config'
import IconButton from '@material-ui/core/IconButton'
import { canIModifyTheCalibration, handleValidation } from '../Utils'
import CheckIcon from '@material-ui/icons/Check'
import { Molecule, Nothing, Relance } from '../Style'
import ClearIcon from '@material-ui/icons/Clear'
import DashboardActivitiesComponent from '../DashboardActivitiesComponent'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ChromatoDetail from '../chromatoDetail/ChromatoDetail'
import FlagsDetail from '../FlagsDetail'
import Button from '@material-ui/core/Button'
import Loading from '../../Loading'
import { fetchChangeDetailActiveTab, fetchHideDetail } from '../../../redux/detail/actions'
import PropTypes from 'prop-types'
import { SyncSwitch } from './SyncSwitch'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
    textAlign: 'center',
  },
  title: {
    width: '100%',
    position: 'absolute',
    top: 22,
    left: 0,
    fontSize: 30,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 6px white',
  },
  validation: {
    zIndex: 1101,
    position: 'absolute',
    top: 35,
    right: 150,
  },
  titleISTD: {
    margin: 24,
    padding: '4px 8px',
    border: '2px solid ' + theme.palette.secondary.main,
    borderRadius: 10,
    boxShadow: '2px 1px 6px white',
  },
  cardImg: {
    opacity: 0.8,
  },
  bouton: {
    position: 'absolute',
    right: 10,
  },
  mainHeightExterne: {
    height: 'calc(100% - 120px)',
  },
  mainHeightInterne: {
    height: 'calc(100% - 152px)',
  },
  mainContainer: {
    overflow: 'hidden',
    margin: 5,
    '& > div > .MuiBox-root': {
      padding: 0,
      overflow: 'hidden',
    },
  },
  tabPanel: {
    height: '100%',
    '& > .MuiBox-root': {
      height: '100%',
    },
    '& > .MuiBox-root > .MuiTypography-root': {
      height: '100%',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
    top: 52,
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      height: 4,
    },
    '& button .MuiTab-wrapper': {
      textShadow: '1px 1px 6px #00000087',
    },
  },
  actionContainer: {
    backgroundColor: alpha('#ffffff', 0.4),
    borderRadius: 5,
    width: 'fit-content',
    padding: 10,
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  selected: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  syncSwitch: {
    zIndex: 1101,
    position: 'absolute',
    top: 10,
    left: 20,
    backgroundColor: alpha('#ffffff', 0.4),
    padding: '5px 6px 5px 5px',
    borderRadius: 5,
    '& .MuiTypography-root': {
      fontSize: 13,
      opacity: 0.5,
      height: 16,
    },
    '&.syncActive .MuiTypography-root': {
      opacity: 1,
      color: theme.palette.secondary.main,
    },
    '& svg': {
      height: 18,
    },
  },
}))

const DetailContentWithRouter = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)
  const { externalWindowMode, localItem, localActiveTab, localHideGeneral, localCollection } = props

  const featuresConfig = useSelector((state) => state.global.featuresConfig)
  const batch = useSelector((state) => state.batches.batch)

  const canEditCalib = useCallback(
    (item) => {
      const edit_calibration = featuresConfig?.content?.edit_calibration
      const param_exist = typeof edit_calibration === 'boolean'
      const getRights = canIModifyTheCalibration(batch)

      if (item) {
        return item.sampleType === 'Standard'
          ? param_exist
            ? edit_calibration && getRights
            : true
          : true
      }
      return param_exist ? edit_calibration && getRights : true
    },
    [featuresConfig, batch],
  )

  const localCanEdit = useMemo(() => canEditCalib(localItem), [localItem])
  const getSelectedChannel = useCallback(() => localItem.selectedChannel, [localItem])

  if (!localItem && externalWindowMode) {
    return <Loading mode={'miniCircle'} />
  }

  function handleChangeActiveTab(event, newValue) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(fetchChangeDetailActiveTab(newValue))
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  function openInExternalView() {
    window.open(
      `${window.location.origin}${window.location.pathname}?${DATABASE()}/detail/${localItem._id}`,
      `${localItem.name}${localCollection === 'compound' && localItem.analyse}`,
      `width=${window.innerWidth - 50},height=${window.innerHeight - 50},left=${35},top=${25}`,
    )
    dispatch(fetchHideDetail())
  }

  function handleCloseFlagsDetail(event) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(fetchHideDetail())
  }

  const handleChangeSync = () => {
    setAutoUpdate((prev) => !prev)
  }

  return (
    <Fragment>
      <Box className={classes.header}>
        <CardMedia
          className={classes.cardImg}
          component="img"
          alt="header image"
          height="100"
          image={PUBLIC_URL + '/images/detail_img_blur.jpg'}
          title="Header image"
        />
        <span className={classes.title}>
          {/*{item.name + (collection === 'compound' ? ' / ' + item.analyse : '')}*/}
          {localItem.name}
          {localCollection === 'compound' && (
            <span className={classes.titleISTD}>{localItem.analyse}</span>
          )}
        </span>
        <span className={classes.validation}>
          <div className={classes.actionContainer}>
            <IconButton
              disabled={!canEditCalib(localItem)}
              aria-label="detected"
              onClick={(event) => {
                handleValidation(
                  event,
                  localItem,
                  '1',
                  localCollection === 'compound' ? 'unitary_compound' : 'unitary_analysis',
                  dispatch,
                )
              }}
            >
              {localItem.validation === '1' ? (
                localItem.status === 'ok' || localItem.status === 'ko' ? (
                  <CheckIcon className={classes.selected} />
                ) : (
                  <Molecule className={classes.selected} />
                )
              ) : localItem.status === 'ok' || localItem.status === 'ko' ? (
                <CheckIcon className={classes.notSelected} />
              ) : (
                <Molecule className={classes.notSelected} />
              )}
            </IconButton>
            <IconButton
              disabled={!canEditCalib(localItem)}
              aria-label="notDetected"
              onClick={(event) =>
                handleValidation(
                  event,
                  localItem,
                  '2',
                  localCollection === 'compound' ? 'unitary_compound' : 'unitary_analysis',
                  dispatch,
                )
              }
            >
              {localItem.validation === '2' ? (
                localItem.status === 'ok' || localItem.status === 'ko' ? (
                  <ClearIcon className={classes.selected} />
                ) : (
                  <Nothing className={classes.selected} />
                )
              ) : localItem.status === 'ok' || localItem.status === 'ko' ? (
                <ClearIcon className={classes.notSelected} />
              ) : (
                <Nothing className={classes.notSelected} />
              )}
            </IconButton>
            <IconButton
              disabled={!canEditCalib(localItem)}
              aria-label="relaunch"
              onClick={(event) =>
                handleValidation(
                  event,
                  localItem,
                  '3',
                  localCollection === 'compound' ? 'unitary_compound' : 'unitary_analysis',
                  dispatch,
                )
              }
            >
              {localItem.validation === '3' ? (
                <Relance className={classes.selected} />
              ) : (
                <Relance className={classes.notSelected} />
              )}
            </IconButton>
          </div>
        </span>
        <div style={{ position: 'absolute', right: 54, top: 35, color: 'white' }}>
          <ErrorBoundaryGuard>
            <DashboardActivitiesComponent hideDefaultState />
          </ErrorBoundaryGuard>
        </div>
        {/*<span className={classes.validation}>*/}
        {/*  <div className={classes.actionContainer}>*/}
        {!externalWindowMode && (
          <IconButton
            style={{ position: 'absolute', top: 6, right: 10 }}
            disabled={!canEditCalib(localItem)}
            aria-label="detected"
            onClick={openInExternalView}
          >
            <OpenInNewIcon />
          </IconButton>
        )}
        {/*  </div>*/}
        {/*</span>*/}
      </Box>
      <AppBar className={classes.appBar} position="absolute">
        <Tabs
          value={localHideGeneral ? 0 : localActiveTab}
          onChange={handleChangeActiveTab}
          aria-label="simple tabs example"
        >
          {localHideGeneral ? (
            ''
          ) : (
            <Tab label={t('view.common.detail.general')} {...a11yProps(0)} />
          )}
          <Tab label={t('view.common.detail.flags')} {...a11yProps(localHideGeneral ? 0 : 1)} />
        </Tabs>
      </AppBar>
      <Box
        className={clsx(
          classes.mainContainer,
          externalWindowMode ? classes.mainHeightExterne : classes.mainHeightInterne,
        )}
      >
        {localHideGeneral ? (
          ''
        ) : (
          <TabPanel
            className={classes.tabPanel}
            value={localHideGeneral ? 0 : localActiveTab}
            index={0}
          >
            <ErrorBoundaryGuard>
              <ChromatoDetail
                externalWindowMode={externalWindowMode}
                canEdit={localCanEdit}
                item={localItem}
                collection={localCollection}
                getSelectedChannel={getSelectedChannel}
              />
            </ErrorBoundaryGuard>
          </TabPanel>
        )}
        <TabPanel
          className={classes.tabPanel}
          value={localHideGeneral ? 0 : localActiveTab}
          index={localHideGeneral ? 0 : 1}
        >
          <ErrorBoundaryGuard>
            <FlagsDetail item={localItem} />
          </ErrorBoundaryGuard>
        </TabPanel>
      </Box>
      {!externalWindowMode && (
        <Button
          color="primary"
          className={classes.bouton}
          onClick={(event) => handleCloseFlagsDetail(event)}
        >
          {t('view.common.detail.close')}
        </Button>
      )}
    </Fragment>
  )
})

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       console.log(`${prop} changed from`, prevProps[prop], 'to', nextProps[prop])
//     })
//   }
//
//   return propsChanged.length === 0
// }

const DetailContent = React.memo(
  React.forwardRef((props, ref) => {
    return <DetailContentWithRouter {...props} forwardedRef={ref} />
  }),
  // areEqual,
)

export default DetailContent
