import {
  DISPLAY_DETAIL,
  HIDE_DETAIL,
  REQUEST_DETAIL,
  REQUEST_CHANGE_DETAIL_ACTIVE_TAB,
  CHANGE_DETAIL_ACTIVE_TAB,
  CHANGE_DETAIL_CHANNEL,
  REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL,
  RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL,
  REQUEST_CHROMATO,
  RECEIVE_CHROMATO,
  RECEIVE_UPDATE_VALIDATION_DETAIL,
} from './actions'
import React from 'react'
import { REQUEST_UNITARY_ANALYSES_CHANGE_LOCK } from '../unitaryAnalyses/actions'
import { rebuildUnitaryAnalyses, rebuildUnitaryCompounds } from '../../view/common/Utils'

const initialState = {
  detail: {
    item: null,
    activeTab: 0,
    hideGeneral: false,
    anchorEl: null,
  },
  internalMode: false,
  isFetchingDetail: false,
  isFetchingUA: false,
  externalMode: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CHROMATO:
      return {
        ...state,
        isFetchingUA: true,
      }
    case RECEIVE_CHROMATO:
      return {
        ...state,
        isFetchingUA: false,
        internalMode: true,
        detail: {
          item: rebuildUnitaryAnalyses(action.unitaryAnalysis),
          activeTab: 0,
          hideGeneral: false,
          anchorEl: {},
        },
      }
    case REQUEST_DETAIL:
      return {
        ...state,
        isFetchingDetail: true,
      }

    case DISPLAY_DETAIL:
      const isExternalDemand = typeof action.externalMode === 'boolean'
      return {
        ...state,
        isFetchingDetail: false,
        internalMode: isExternalDemand ? state.internalMode : true,
        detail: action.detail,
        externalMode: isExternalDemand ? true : state.externalMode,
      }

    case 'DETAIL_WINDOW_OPENED':
      return {
        ...state,
        externalMode: true,
      }
    case 'DETAIL_WINDOW_CLOSED':
      return {
        ...state,
        externalMode: false,
      }
    case HIDE_DETAIL:
      const isExternalDemandToHide = typeof action.externalMode === 'boolean'
      return {
        ...state,
        isFetchingDetail: false,
        internalMode: isExternalDemandToHide ? state.internalMode : false,
        externalMode: isExternalDemandToHide ? false : state.externalMode,
      }
    case REQUEST_CHANGE_DETAIL_ACTIVE_TAB:
      return {
        ...state,
        isFetchingDetail: true,
      }
    case CHANGE_DETAIL_ACTIVE_TAB:
      return {
        ...state,
        isFetchingDetail: false,
        internalMode: state.internalMode,
        detail: { ...state.detail, activeTab: action.newActiveTab },
      }
    case CHANGE_DETAIL_CHANNEL:
      return {
        ...state,
        isFetchingDetail: false,
        internalMode: state.internalMode,
        detail: {
          ...state.detail,
          item: { ...state.detail.item, selectedChannel: action.channel },
        },
      }
    case REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          item: state.detail.item
            ? {
                ...state.detail.item,
                unlocked:
                  typeof state.detail.item.unlocked !== 'undefined'
                    ? !state.detail.item.unlocked
                    : true,
              }
            : state.detail.item,
        },
      }
    case RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL:
      if (state.detail.item) {
        let ua = action.uaUpdated.uas.filter((ua) => ua._id === state.detail.item._id)
        if (ua && ua.length > 0) {
          if (action.uaCollection === 'analyse') {
            ua = rebuildUnitaryAnalyses(ua[0])
          } else {
            ua = rebuildUnitaryCompounds(ua[0])
          }
          if (action.uaChannel !== undefined && action.uaChannel !== null) {
            ua.selectedChannel = action.uaChannel
          }
        }
        return {
          ...state,
          detail: {
            ...state.detail,
            item: ua ? ua : state.detail.item,
          },
        }
      }
      return state
    case RECEIVE_UPDATE_VALIDATION_DETAIL:
      if (state.detail.item) {
        let ua = action.uasUpdated.uas.filter((ua) => ua._id === state.detail.item._id)
        if (ua && ua.length > 0) {
          if (action.uaCollection === 'analyse') {
            ua = rebuildUnitaryAnalyses(ua[0])
          } else {
            ua = rebuildUnitaryCompounds(ua[0])
          }
          // if (action.uaChannel !== undefined && action.uaChannel !== null) {
          //   ua.selectedChannel = action.uaChannel
          // }
        }
        return {
          ...state,
          detail: {
            ...state.detail,
            item: ua ? ua : state.detail.item,
          },
        }
      }
      return state
    default:
      return state
  }
}
