import React, { Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { connect, useSelector } from 'react-redux'
import { getBatchById } from '../../redux/batches/selectors'
import { AUTHENTICATION_FAILURE, fetchBatch } from '../../redux/batches/actions'
import { fetchAnalyses, fetchAnalysesSentToLims } from '../../redux/analyses/actions'
import SamplesTable from './SamplesTable'
import QualityControlTable from './QualityControlTable'
import OthersTable from './OthersTable'
import CalibTable from './CalibTable'
import SamplesTable_old from './SamplesTable_old'
import QualityControlTable_old from './QualityControlTable_old'
import OthersTable_old from './OthersTable_old'
import CalibTable_old from './CalibTable_old'
import Navigation from '../Navigation'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import Information from './Information'
import Calibration from '../common/Calibration'
import TableMultiGrid from '../common/Table_MultiGrid'
import { AUTHENTICATION_ERROR } from '../../redux/tools'
import { displayPopupMessage } from '../common/Message'
import { testBatchLease } from '../common/Utils'
import Button from '@material-ui/core/Button'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RetiredCmp from '../common/RetiredCmp'
import { fetchChangeSettings } from '../../redux/settings/actions'
import {
  fetchQCUnitaryAnalyses,
  fetchQCUnitaryAnalysesCompound,
} from '../../redux/unitaryAnalyses/actions'
import { DATABASE, DRAWER_WIDTH, USE_OLD_TABLE } from '../../utils/config'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { fetchConfigurationFeatures } from '../../redux/configuration/actions'
import EmptyBatch from './EmptyBatch'
import { fetchDisplayChromatoDetail } from '../../redux/detail/actions'
import RenameDialog from './RenameDialog'

const styles = (theme) => ({
  backgroundImg: {
    height: '100%',
    // position: 'fixed',
    // marginLeft: 'calc(50% - 400px)',
  },
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    paddingTop: 8,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    // height: '500px',
    height: '100%',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
    position: 'relative',
    // overflowY: 'auto',
  },
  mainSplitter: {
    height: '100%',
    position: 'relative',
    paddingLeft: 60,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  mainSplitterFull: {
    height: 'calc(100% - 28px)',
    // paddingLeft: 241,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  centerSplitter: {
    padding: '0px 5px',
    '& > .layout-pane': {
      paddingTop: 0,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 12px 2px',
      height: 'auto !important',
    },
    '& > .layout-splitter': {
      height: '0 !important',
      opacity: 0,
    },
  },
  containerSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  deeperSplitter: {
    '& > .layout-pane': {
      padding: '1px 10px 12px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '1px 5px 12px 2px',
    },
  },
  deeperSplitterCalib: {
    '& > .layout-pane': {
      padding: '1px 22px 22px 15px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '1px 22px 22px 2px',
    },
  },
  firstSplitter: {
    height: 'calc(100% - 64px)',
    '& > .layout-pane': {
      padding: '1px 8px 14px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 0px 2px 2px',
      minWidth: DRAWER_WIDTH(),
    },
  },
})

const logout = (props) => {
  console.log("Please login, you're not logged.")
  localStorage.removeItem('SESSION_user_name')
  localStorage.removeItem('SESSION_user_json')
  props.history.push('/?login')
}

const mapStateToProps = (state, ownProps) => {
  const { batches, drawer, settings } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  return {
    batch,
    pinDrawer: drawer.pin,
    batchNotFound: batches.batchNotFound,
    authenticationError:
      batches.type === AUTHENTICATION_ERROR || batches.type === AUTHENTICATION_FAILURE,
    isFetchingAnalyses: false,
    isDatabaseOffline: settings.configurationDatabase.length > 0,
  }
}
const oldTable = USE_OLD_TABLE()

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Batch extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            isLoaded: false,
            items: [],
            batch: null,
            tableItemSelectedForColor: localStorage.getItem('BATCH_previous_selection'),
          }
          // this.navigationRef = React.createRef()
          this.contentRef = React.createRef()
          this.contentCalibrationRef = React.createRef()
          this.contentEchClientRef = React.createRef()
          this.contentOtherRef = React.createRef()
          this.contentFirstCntRef = React.createRef()
        }

        refCalibration = React.createRef()

        async componentDidMount() {
          const { dispatch, isDatabaseOffline, match, history } = this.props
          if (!isDatabaseOffline) {
            // Open the chromato detail if an ID is set in the URL
            const chromatoId = match.params.chromatoId
            if (chromatoId) {
              const regex = /ua=/i
              dispatch(fetchDisplayChromatoDetail(chromatoId.replace(regex, '')))
            }
            dispatch(fetchBatch(match.params.batchId)).then(function (resp) {
              if (resp && resp.type === 'ITEM_NOT_FOUND') {
                displayPopupMessage(
                  dispatch,
                  'warning',
                  t('view.batch.batch.not_found.title'),
                  <Trans i18nKey="view.batch.batch.not_found.msg">
                    The batch with id {{ val: match.params.batchId }} can not be found.
                  </Trans>,
                )
                history.push('/' + DATABASE())
              }
              // Load the configuration features from the batch
              if (resp && resp.batch && resp.batch.content && resp.batch.content.configuration) {
                dispatch(fetchConfigurationFeatures(resp.batch.content.configuration)).then(
                  function (resp) {
                    // Store the batch configuration id
                    resp.configFeatures?._id &&
                      localStorage.setItem(
                        'SETTINGS_configuration_profile',
                        resp?.configFeatures?._id,
                      )
                  },
                )
              }
            })
            dispatch(fetchAnalyses(match.params.batchId))

            // Calculate if analyses are already sent to LIMS (reported)
            dispatch(fetchAnalysesSentToLims(match.params.batchId))
          }
        }

        displayCalib(item) {
          // Load the QC for the calib point tooltip in the Calibration view
          const { dispatch } = this.props
          dispatch(fetchQCUnitaryAnalysesCompound(this.props.match.params.batchId, item.event))

          this.setState({ tableItemSelectedForColor: item._id })
          // In case of ISTD, do not display the calibration
          // EDIT: do not display calibration but we have to use it to get the wip parameters for the recompute button
          // if (item.type === 'ISTD') {
          //   this.refCalibration.current.displayISTDMessage()
          // } else {
          this.refCalibration.current.displayCalib(item.event, item.mainChannel, true)
          // }
        }

        render() {
          // const { error, isLoaded, operator, batch } = this.state
          const {
            dispatch,
            pinDrawer,
            classes,
            batch,
            isFetchingAnalyses,
            batchNotFound,
            authenticationError,
            isDatabaseOffline,
          } = this.props

          if (batch) {
            document.title =
              batch.name + ' - ' + localStorage.getItem('__config.json__APPLICATION_TITLE')
          } else {
            document.title = localStorage.getItem('__config.json__APPLICATION_TITLE')
          }

          if (authenticationError) {
            // logout(this.props)
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          // if (batchNotFound) {
          //   displayPopupMessage(
          //     dispatch,
          //     'warning',
          //     t('view.batch.batch.not_found.title'),
          //     <Trans i18nKey="view.batch.batch.not_found.msg">
          //       The batch with id {{ val: this.props.match.params.batchId }} can not be found.
          //     </Trans>,
          //   )
          //   this.props.history.push('/')
          // }
          // if (error) {
          //   return <div>Erreur : {error.message}</div>
          // }
          if (isDatabaseOffline) {
            return <EmptyBatch />
          } else if (!batch || isFetchingAnalyses) {
            return <Loading mode="alpha" size={10} />
          }

          const onContentSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_batch_secondaryPaneSize',
              this.contentRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          const onContentCalibrationSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_batch_calibration_secondaryPaneSize',
              this.contentCalibrationRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          const onContentFirstSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_first_secondaryPaneSize',
              this.contentFirstCntRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          const onContentEchClientSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_batch_client_secondaryPaneSize',
              this.contentEchClientRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          const onContentOtherSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_batch_other_secondaryPaneSize',
              this.contentOtherRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          const innerCmpNavigation = (
            <ErrorBoundaryGuard>
              <Navigation batch={batch} />
            </ErrorBoundaryGuard>
          )
          const innerCmp = (
            <ErrorBoundaryGuard>
              <SplitterLayout
                primaryIndex={1}
                secondaryInitialSize={
                  // localStorage.getItem('LAYOUT_navigation_secondaryPaneSize')
                  //   ? parseFloat(localStorage.getItem('LAYOUT_navigation_secondaryPaneSize'))
                  //   : 230
                  0
                }
                customClassName={pinDrawer ? classes.mainSplitterFull : classes.mainSplitter}
                // onDragEnd={onSplitterSizeSave}
                // ref={this.navigationRef}
              >
                <Fragment></Fragment>
                <SplitterLayout
                  vertical
                  primaryIndex={1}
                  secondaryInitialSize={120}
                  customClassName={classes.centerSplitter}
                >
                  <ErrorBoundaryGuard>
                    <Information batch={batch} />
                  </ErrorBoundaryGuard>
                  <SplitterLayout
                    vertical
                    percentage
                    customClassName={classes.containerSplitter}
                    ref={this.contentRef}
                    onDragEnd={onContentSplitterSizeSave}
                    secondaryInitialSize={
                      localStorage.getItem('LAYOUT_batch_secondaryPaneSize')
                        ? parseFloat(localStorage.getItem('LAYOUT_batch_secondaryPaneSize'))
                        : 50
                    }
                  >
                    <Paper elevation={0} className={classes.paper}>
                      <SplitterLayout
                        customClassName={classes.deeperSplitterCalib}
                        ref={this.contentCalibrationRef}
                        onDragEnd={onContentCalibrationSplitterSizeSave}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_batch_calibration_secondaryPaneSize')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_batch_calibration_secondaryPaneSize'),
                              )
                            : 450
                        }
                      >
                        <ErrorBoundaryGuard>
                          {oldTable ? (
                            <CalibTable_old
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                              onDisplayCalib={(item) => this.displayCalib(item)}
                            />
                          ) : (
                            <CalibTable
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                              onDisplayCalib={(item) => this.displayCalib(item)}
                            />
                          )}
                        </ErrorBoundaryGuard>
                        <ErrorBoundaryGuard>
                          <Calibration
                            batch={batch}
                            wrappedComponentRef={this.refCalibration}
                            collection={'batch'}
                            options={{ displayTitle: false, displayPaper: false }}
                          />
                        </ErrorBoundaryGuard>
                      </SplitterLayout>
                    </Paper>
                    <SplitterLayout
                      percentage
                      customClassName={classes.deeperSplitter}
                      ref={this.contentEchClientRef}
                      onDragEnd={onContentEchClientSplitterSizeSave}
                      secondaryInitialSize={
                        localStorage.getItem('LAYOUT_batch_client_secondaryPaneSize')
                          ? parseFloat(
                              localStorage.getItem('LAYOUT_batch_client_secondaryPaneSize'),
                            )
                          : 66
                      }
                    >
                      <ErrorBoundaryGuard>
                        {oldTable ? (
                          <QualityControlTable_old
                            batch={batch}
                            tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                          />
                        ) : (
                          <QualityControlTable
                            batch={batch}
                            tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                          />
                        )}
                      </ErrorBoundaryGuard>
                      <SplitterLayout
                        percentage
                        customClassName={classes.deeperSplitter}
                        ref={this.contentOtherRef}
                        onDragEnd={onContentOtherSplitterSizeSave}
                        secondaryInitialSize={
                          localStorage.getItem('LAYOUT_batch_other_secondaryPaneSize')
                            ? parseFloat(
                                localStorage.getItem('LAYOUT_batch_other_secondaryPaneSize'),
                              )
                            : 50
                        }
                      >
                        <ErrorBoundaryGuard>
                          {oldTable ? (
                            <SamplesTable_old
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                            />
                          ) : (
                            <SamplesTable
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                            />
                          )}
                        </ErrorBoundaryGuard>
                        <ErrorBoundaryGuard>
                          {oldTable ? (
                            <OthersTable_old
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                            />
                          ) : (
                            <OthersTable
                              batch={batch}
                              tableItemSelectedForColor={this.state.tableItemSelectedForColor}
                            />
                          )}
                        </ErrorBoundaryGuard>
                      </SplitterLayout>
                    </SplitterLayout>
                  </SplitterLayout>
                </SplitterLayout>
              </SplitterLayout>
            </ErrorBoundaryGuard>
          )

          return (
            <Fragment>
              <ErrorBoundaryGuard isDialog>
                <RenameDialog />
              </ErrorBoundaryGuard>
              <ErrorBoundaryGuard isDialog>
                <RetiredCmp batch={batch} />
              </ErrorBoundaryGuard>
              {pinDrawer ? (
                <SplitterLayout
                  customClassName={classes.firstSplitter}
                  ref={this.contentFirstCntRef}
                  onDragEnd={onContentFirstSplitterSizeSave}
                  secondaryMinSize={DRAWER_WIDTH()}
                  primaryIndex={1}
                  primaryMinSize={600}
                  secondaryInitialSize={
                    localStorage.getItem('LAYOUT_first_secondaryPaneSize')
                      ? parseFloat(localStorage.getItem('LAYOUT_first_secondaryPaneSize'))
                      : DRAWER_WIDTH()
                  }
                >
                  {innerCmpNavigation}
                  {innerCmp}
                </SplitterLayout>
              ) : (
                <Fragment>
                  {innerCmpNavigation} {innerCmp}
                </Fragment>
              )}
            </Fragment>
          )
        }
      },
    ),
  ),
)
