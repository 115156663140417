export const USE_CACHE_DEFAULT = false
export const DEFAULT_CACHE_MAX_SIZE = 1000
export const DEFAULT_CACHE_POLLING = 10
export const PREFETCH_LIMIT = () =>
  parseInt(localStorage.getItem('SETTINGS_cache_page_size')) || 500

// export const GET_LAST_CACHE_MODIFICATION_DATE = () =>
//   parseInt(localStorage.getItem('CACHE_last_modificationDate')) || 0
export const GET_LAST_CACHE_MODIFICATION = () =>
  JSON.parse(localStorage.getItem('CACHE_last_modificationDate')) || {
    modificationDate: 0,
    batchId: null,
  }
export const USE_OLD_TABLE_DEFAULT = false

export const PUBLIC_URL =
  process.env.NODE_ENV === 'development'
    ? window.location.origin
    : window.location.origin + '/' + window.location.pathname.split('/')[1]
export const RELEASE_VERSION_PATH = () => {
  return `${PUBLIC_URL}/${localStorage.getItem('__config.json__RELEASE_VERSION_PATH')}`
}
export const APPLICATION_CONFIG_JSON = `${PUBLIC_URL}/config.json`
export const DATABASE = () => {
  // return localStorage.getItem('SERVICE_dataBase_name')
  //   ? localStorage.getItem('SERVICE_dataBase_name')
  //   : localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME')
  return window.location.search.replace('?', '').split('/')[0]
}
export const SERVICE = () => {
  return localStorage.getItem('SERVICE_pythonService_name')
    ? localStorage.getItem('SERVICE_pythonService_name')
    : localStorage.getItem('__config.json__DEFAULT_PYTHON_SERVICE_NAME')
}
export const CONFIGURATION_PROFILE = () => {
  return localStorage.getItem('SETTINGS_configuration_profile')
}
const TORII_BASE_URL_KEY = () => {
  return process.env.NODE_ENV === 'development'
    ? localStorage.getItem('__config.json__TORII_BASE_URL_DEV')
    : localStorage.getItem('__config.json__TORII_BASE_URL')
}
export const GET_TORII_BASE_URL = () => {
  return TORII_BASE_URL_KEY()
    ? TORII_BASE_URL_KEY().replace('window.location.origin', window.location.origin)
    : null
}
export const FLASK_BASE_URL = () => {
  const url = localStorage.getItem('__config.json__FLASK_URL')
  return url
    ? url.replace('window.location.origin', window.location.origin)
    : `${GET_TORII_BASE_URL()}/ps`
}
export const FLASK_URL = () => {
  const url = localStorage.getItem('__config.json__FLASK_URL')
  return url
    ? url.replace('window.location.origin', window.location.origin)
    : `${FLASK_BASE_URL()}/${SERVICE()}`
}
export const CHAT_URL = () => {
  const url = localStorage.getItem('__config.json__CHAT_URL')
  return url ? url.replace('window.location.origin', window.location.origin) : null
}
export const API_URL = () => {
  return `${GET_TORII_BASE_URL()}/rest`
}
export const API_BO_URL = () => {
  const database = DATABASE()
  return `${API_URL()}/bo/${database}`
}

// Default factor of peak representation on chart
export const ZOOM_DEFAULT_FACTO = () => {
  return localStorage.getItem('CHROMATO_option_peakHeight')
    ? Number(localStorage.getItem('CHROMATO_option_peakHeight'))
    : 3
}

export const GET_CHROMATO_OPACITY = () => {
  return localStorage.getItem('CHROMATO_option_opacity')
    ? Number(localStorage.getItem('CHROMATO_option_opacity'))
    : 0.4
}

export const GET_CHROMATO_WEIGHT = () => {
  return localStorage.getItem('CHROMATO_option_weight')
    ? Number(localStorage.getItem('CHROMATO_option_weight'))
    : 0.5
}

export const GET_CHROMATO_COLORS = () => {
  return localStorage.getItem('CHROMATO_option_colors')
    ? localStorage.getItem('CHROMATO_option_colors').split(',')
    : [
        GET_PRIMARY_COLOR(),
        '#16b41d',
        '#ff5424',
        '#00BCD4',
        '#6a32ef',
        '#d947c9',
        '#0004ff',
        '#f1bd22',
      ]
}

export const GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK = () => {
  return localStorage.getItem('CHROMATO_option_isPeakAsDefaultWindow')
    ? localStorage.getItem('CHROMATO_option_isPeakAsDefaultWindow') === 'true'
    : false
}
// Default layout
export const LAYOUT_CONFIG_VALUE = (origin) => {
  return localStorage.getItem('LAYOUT_config_value_' + origin)
    ? localStorage.getItem('LAYOUT_config_value_' + origin)
    : 'default'
}

export const LANGUAGES = {
  en: { label: 'English', dir: 'ltr', active: true },
  fr: { label: 'Français', dir: 'ltr', active: false },
  // jp: { label: '日本', dir: 'ltr', active: false },
}
export const ZIP_LEVEL = 5

export const GET_PRIMARY_COLOR = () => {
  return localStorage.getItem('SETTINGS_primary_color')
    ? localStorage.getItem('SETTINGS_primary_color')
    : localStorage.getItem('SETTINGS_primary_color_from_db')
    ? localStorage.getItem('SETTINGS_primary_color_from_db')
    : localStorage.getItem('__config.json__PRIMARY_COLOR_DEFAULT')
}
export const GET_SECONDARY_COLOR = () => {
  return localStorage.getItem('SETTINGS_secondary_color')
    ? localStorage.getItem('SETTINGS_secondary_color')
    : localStorage.getItem('SETTINGS_secondary_color_from_db')
    ? localStorage.getItem('SETTINGS_secondary_color_from_db')
    : localStorage.getItem('__config.json__SECONDARY_COLOR_DEFAULT')
}

export const UPLOAD_CONFIGURATION_NAME = () => {
  return localStorage.getItem('__config.json__UPLOAD_CONFIGURATION_NAME')
}
export const UPLOAD_PYTHON_SERVICE_NAME = () => {
  return localStorage.getItem('__config.json__UPLOAD_PYTHON_SERVICE_NAME')
}

export const DRAWER_WIDTH = () => {
  return localStorage.getItem('__config.json__NAVIGATION_WIDTH_DEFAULT')
    ? parseFloat(localStorage.getItem('__config.json__NAVIGATION_WIDTH_DEFAULT'))
    : 240
}

export const AZURE_ENV = () => {
  return localStorage.getItem('__config.json__UPLOAD_CONFIGURATION_NAME') === 'true' ? true : false
}

export const CACHE_IS_USED = () => {
  return localStorage.getItem('SETTINGS_useCache')
    ? localStorage.getItem('SETTINGS_useCache') === 'true'
    : USE_CACHE_DEFAULT
}

export const USE_OLD_TABLE = () => {
  return localStorage.getItem('SETTINGS_table_old_version')
    ? localStorage.getItem('SETTINGS_table_old_version') === 'true'
    : USE_OLD_TABLE_DEFAULT
}
