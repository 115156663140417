import {
  DISPLAY_MESSAGE,
  HIDE_MESSAGE,
  REQUEST_MESSAGE,
  GET_MESSAGES,
  ADD_MESSAGE,
  CLEAN_NEW_MESSAGES,
} from './actions'
import React from 'react'

const initialState = {
  // message: {
  //   severity: 'info',
  //   text: <></>,
  //   title: '',
  // },
  // visibility: false,
  // isFetchingMessage: false,
  messages: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    // case REQUEST_MESSAGE:
    //   return {
    //     ...state,
    //     isFetchingMessage: true,
    //   }
    //
    // case DISPLAY_MESSAGE:
    //   return {
    //     ...state,
    //     isFetchingMessage: false,
    //     visibility: action.visibility,
    //     message: action.message,
    //   }
    //
    // case HIDE_MESSAGE:
    //   return {
    //     ...state,
    //     isFetchingMessage: false,
    //     visibility: action.visibility,
    //   }
    case GET_MESSAGES:
      return {
        ...state,
        messages: state.messages.sort(function (a, b) {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        }),
      }
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      }
    case CLEAN_NEW_MESSAGES:
      return {
        ...state,
        messages: state.messages.map((msg) => ({
          ...msg,
          isNew: false,
        })),
      }
    default:
      return state
  }
}
