import {
  REQUEST_BATCHES,
  RECEIVE_BATCHES,
  RECEIVE_BATCH,
  RECEIVE_UPDATE_BATCHES,
  RECEIVE_CREATE_SUB_BATCH,
  RECEIVE_MOVE_SUB_BATCH,
  AUTHENTICATION_FAILURE,
  RECEIVE_CHANGE_ANALYSES_TYPE,
  ITEM_NOT_FOUND,
  RECEIVE_CREATE_TRAINING_BATCH,
  REQUEST_SEARCH_BATCHES,
  RECEIVE_SEARCH_BATCHES,
  RECEIVE_DEVICES,
  RECEIVE_UPDATE_PRIORITY,
  RECEIVE_UPDATE_NOTE,
  POLLING_BATCHES,
  RECEIVE_SERVICES,
  RECEIVE_TYPES,
  CLEAN_CURRENT_BATCH_LEASE,
  RECEIVE_CURRENT_BATCH_LEASE,
  REQUEST_STAMP_BATCH,
  RECEIVE_STAMP_BATCH,
  REQUEST_RETIRE_BATCH,
  RECEIVE_RETIRE_BATCH,
  REQUEST_CALIBREF_BATCH,
  RECEIVE_CALIBREF_BATCH,
  REQUEST_RESTORE_BATCH,
  RECEIVE_RESTORE_BATCH,
} from './actions'
import { AUTHENTICATION_ERROR, DEFAULT_ERROR, NOT_FONDED, PERMISSION_ERROR } from '../tools'

const initialState = {
  items: [],
  isFetching: false,
  error: false,
  batchNotFound: false,
  keyword: null,
  isSearchingBatches: false,
  searchedBatches: [],
  devices: [],
  services: [],
  types: [],
  lastModificationDate: 0,
  updatedBatch: null,
  currentBatchLease: { date: 0, operator: null, reason: null },
  batchesLastDateRequest: null,
  batchesSearchedLastDateRequest: null,
  isFetchingRetireBatch: false,
  isFetchingCalibRefBatch: false,
}

// Function to update data in case of dico field is empty in database
// In this case, database don't return the field
const updateEmptyField = (items) => {
  const updateField = (item) => {
    if (item.content.molecules === undefined) {
      item.content['molecules'] = {}
    }
    return item
  }
  if (Array.isArray(items)) {
    return items.map((item) => {
      return updateField(item)
    })
  } else {
    return updateField(items)
  }
}

const batchesEqual = function (a, b) {
  if (a === [] && b === []) return true
  if (a.length !== b.length) return false

  const aIds = a.map((as) => as._id)
  const bIds = b.map((bs) => bs._id)

  for (let i = 0; i < aIds.length; ++i) {
    if (!bIds.includes(aIds[i])) {
      return false
    } else if (
      a[i].modificationDate !== b[b.map((b_id) => b_id._id).indexOf(a[i]._id)].modificationDate
    ) {
      return false
    }
  }
  for (let i = 0; i < bIds.length; ++i) {
    if (!aIds.includes(bIds[i])) {
      return false
    }
  }
  return true
}

const getLastModificationDate = function (batches) {
  const lastBatch = batches.sort(function (a, b) {
    if (a.modificationDate < b.modificationDate) return 1
    if (a.modificationDate > b.modificationDate) return -1
    return 0
  })[0]
  return lastBatch ? lastBatch.modificationDate : null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case POLLING_BATCHES:
      // Compare old and new batches to see if update is needed
      const noModification = batchesEqual(state.items, action.batches)
      if (noModification) {
        return {
          ...state,
        }
      } else {
        let lmd = getLastModificationDate(action.batches)
        return {
          ...state,
          items: updateEmptyField(action.batches),
          lastModificationDate: lmd ? lmd : state.lastModificationDate,
        }
      }
    case REQUEST_BATCHES:
      if (!action.from && !action.to) {
        return {
          ...state,
          batchesLastDateRequest: action.dateLock,
          isFetching: !action.polling,
        }
      } else {
        return {
          ...state,
          isSearchingBatches: false,
          isFetching: true,
          error: false,
          batchNotFound: false,
          from: action.from,
          to: action.to,
          batchesLastDateRequest: action.dateLock,
        }
      }

    case RECEIVE_BATCHES:
      if (action.errorReturn) {
        // Force to stop to display the loading case error happened
        return {
          ...state,
          isFetching: false,
        }
      } else if (action.dateLock !== state.batchesLastDateRequest) {
        // Avoid displaying an old request after a more recent one which was faster.
        return {
          ...state,
        }
      } else {
        let lmdrb = getLastModificationDate(action.batches)
        return {
          ...state,
          isSearchingBatches: false,
          isFetching: false,
          error: false,
          batchNotFound: false,
          items: updateEmptyField(action.batches),
          lastModificationDate: lmdrb ? lmdrb : state.lastModificationDate,
        }
      }

    case RECEIVE_BATCH:
      // Add the new batch in all case, but we have to remove the old one from the store
      const listItems = state.items.filter((item) => item._id !== action.batch._id)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: [...listItems, updateEmptyField(action.batch)],
      }

    case RECEIVE_UPDATE_BATCHES:
      // console.log('reducers batches')
      const batchIds = action.objectsUpdated.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      let lmdrub = getLastModificationDate(action.objectsUpdated)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (batchIds[batch._id]) {
            return updateEmptyField(batchIds[batch._id])
          }
          return batch
        }),
        lastModificationDate: lmdrub ? lmdrub : state.lastModificationDate,
      }

    case RECEIVE_CREATE_SUB_BATCH:
      // Add new sub-batch in the store
      state.items.push(updateEmptyField(action.objectsUpdated.batch))
      return {
        ...state,
        lastModificationDate: action.objectsUpdated.batch.modificationDate,
      }
    case RECEIVE_MOVE_SUB_BATCH:
    case RECEIVE_CHANGE_ANALYSES_TYPE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items,
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: true,
        batchNotFound: false,
      }
    case ITEM_NOT_FOUND:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        batchNotFound: true,
        error: false,
      }
    case RECEIVE_CREATE_TRAINING_BATCH:
      state.items.push(updateEmptyField(action.trainingBatch))
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items,
        lastModificationDate: action.trainingBatch.modificationDate,
      }
    case REQUEST_SEARCH_BATCHES:
      return {
        ...state,
        isSearchingBatches: true,
        keyword: action.keyword,
        error: false,
        batchesSearchedLastDateRequest: action.dateLock,
      }
    case RECEIVE_SEARCH_BATCHES:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.batchesSearchedLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          isSearchingBatches: false,
          error: false,
          searchedBatches: updateEmptyField(action.searchedBatches),
        }
      }
    case RECEIVE_DEVICES:
      return {
        ...state,
        error: false,
        devices: action.devices,
      }
    case RECEIVE_SERVICES:
      return {
        ...state,
        error: false,
        services: action.services,
      }
    case RECEIVE_TYPES:
      return {
        ...state,
        error: false,
        types: action.types,
      }
    case RECEIVE_UPDATE_PRIORITY:
      // let lmdrup = getLastModificationDate(action.updatedBatch.modificationDate)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (action.updatedBatch._id === batch._id) {
            return updateEmptyField(action.updatedBatch)
          }
          return batch
        }),
        lastModificationDate: state.lastModificationDate,
      }
    case RECEIVE_UPDATE_NOTE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (action.updatedBatch._id === batch._id) {
            return updateEmptyField(action.updatedBatch)
          }
          return batch
        }),
        lastModificationDate: action.updatedBatch.modificationDate,
      }
    case CLEAN_CURRENT_BATCH_LEASE:
      return {
        ...state,
        currentBatchLease: { date: 0, operator: null, reason: null },
      }
    case RECEIVE_CURRENT_BATCH_LEASE:
      return {
        ...state,
        currentBatchLease: action.currentBatchLease,
      }
    case REQUEST_STAMP_BATCH:
      return {
        ...state,
        isFetchingStampBatch: true,
      }
    case RECEIVE_STAMP_BATCH:
      return {
        ...state,
        isFetchingStampBatch: false,
        items: state.items.map(function (batch) {
          if (action.stampedBatch._id === batch._id) {
            return updateEmptyField(action.stampedBatch)
          }
          return batch
        }),
      }
    case REQUEST_RETIRE_BATCH:
      return {
        ...state,
        isFetchingRetireBatch: true,
      }
    case RECEIVE_RETIRE_BATCH:
      return {
        ...state,
        isFetchingRetireBatch: false,
        items: state.items.map(function (batch) {
          if (action.retiredBatch._id === batch._id) {
            return updateEmptyField(action.retiredBatch)
          }
          return batch
        }),
      }
    case REQUEST_CALIBREF_BATCH:
      return {
        ...state,
        isFetchingCalibRefBatch: true,
      }
    case RECEIVE_CALIBREF_BATCH:
      const returnedBatchIds = action.calibRefBatches.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingCalibRefBatch: false,
        items: state.items.map(function (batch) {
          if (returnedBatchIds[batch._id]) {
            return updateEmptyField(returnedBatchIds[batch._id])
          }
          return batch
        }),
      }
    case REQUEST_RESTORE_BATCH:
      return {
        ...state,
      }
    case RECEIVE_RESTORE_BATCH:
      const listItemsWithoutRestored = state.items.filter(
        (item) => item._id !== action.restoredBatch._id,
      )
      return {
        ...state,
        items: [...listItemsWithoutRestored, action.restoredBatch],
      }
    case PERMISSION_ERROR:
    case NOT_FONDED:
    case AUTHENTICATION_ERROR:
    case DEFAULT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
