import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
}))

const DatabaseSelectionWithRouter = withRouter((props) => {
  // const dispatch = useDispatch()
  const classes = useStyles()
  // const {
  //   internalMode: showDetail,
  //   detail: { item, activeTab, hideGeneral, anchorEl, collection },
  // } = useSelector((state) => state.detail)

  const [autoUpdate, setAutoUpdate] = useState(true)

  useEffect(() => {
    // TODO
  }, [])

  return <Fragment></Fragment>
})

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       console.log(`${prop} changed from`, prevProps[prop], 'to', nextProps[prop])
//     })
//   }
//
//   return propsChanged.length === 0
// }

const NoDatabase = React.memo(
  React.forwardRef((props, ref) => {
    return <DatabaseSelectionWithRouter {...props} forwardedRef={ref} />
  }),
  // areEqual,
)

export default NoDatabase
